import { ApiClient } from "../../api/ApiClient";
import { enqueueItem, SyncItem, SyncItemType } from "./SyncActions";
import { rehydrateRecapData, rehydratePortfoliosData } from "./PortfolioActions";
import { store } from "../store";
import {
  initialState,
  userSelector,
  userPreferencesSelector,
  siteConfigSelector,
  multiuserListSelector,
  userMaskAllValuesSelector,
  setPortfolioSessionUserId,
  getPortfolioSessionUserId,
  showBlackPaywalls
} from "../reducers/AuthReducer";
import {
  portfolioPersistTransform,
  portfolioReducer,
  portfoliosReducerName,
  initialState as portfoliosInitialState
} from "../reducers/PortfolioReducer";
import { getUuid } from "../../utilities/Number";
import {
  isAppInWhiteLabelMode,
  getSiteWhiteLabelConfigId,
  isAppInViewMode,
  getTokenForViewMode,
  setUserNameForViewMode,
  signOut,
  getRecaptchaToken
} from "../../utilities/Auth";
import { isMobile } from "../../utilities/Location";
import DeferredPromise from "../../utilities/DeferredPromise";

export const SET_STATUS_CONFIG = "SET_STATUS_CONFIG";

export const SET_SITE_CONFIG = "SET_SITE_CONFIG";
export const REMOVE_STRIPE_CONNECTED_ACCOUNT = "REMOVE_STRIPE_CONNECTED_ACCOUNT";

export const SET_APP_MAINTENANCE_STATUS = "SET_APP_MAINTENANCE_STATUS";

export const SIGNOUT_USER = "SIGNOUT_USER";
export const SET_USER = "SET_USER";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_USER_PREFERENCES = "SET_USER_PREFERENCES";
export const SET_SIGNIN_REDIRECT_PATH = "SET_SIGNIN_REDIRECT_PATH";
export const SET_SIGN_IN_WITH_GOOGLE_FLOW = "SET_SIGN_IN_WITH_GOOGLE_FLOW";
export const SET_SIGN_UP_WITH_GOOGLE_FLOW = "SET_SIGN_UP_WITH_GOOGLE_FLOW";

export const FETCH_CREDIT_BALANCE_PENDING = "FETCH_CREDIT_BALANCE_PENDING";
export const UPDATE_CREDIT_BALANCE = "UPDATE_CREDIT_BALANCE";

export const FETCH_MULTIUSER_LIST_PENDING = "FETCH_MULTIUSER_LIST_PENDING";
export const SET_MULTIUSER_LIST = "SET_MULTIUSER_LIST";
export const SET_MULTIUSER_INVITATION_DATA = "SET_MULTIUSER_INVITATION_DATA";
export const SET_SHARE_MASK = "SET_SHARE_MASK";

export const SET_RECEIPTS = "SET_RECEIPTS";

export const SHOW_BREAKING_CHANGES = "SHOW_BREAKING_CHANGES";

export const ADD_USER_ACTION = "ADD_USER_ACTION";
export const LINK_PORTFOLIO_ACTION = "LINK_PORTFOLIO_ACTION";

export const userTypes = {
  USER: "user",
  OWNER: "owner",
  ADMIN: "admin",
  MANAGER: "manager",
  SUB_USER: "subuser"
};

export const userTypeToString = userType => {
  switch (userType) {
    case userTypes.OWNER:
      return "Account Owner";
    case userTypes.ADMIN:
      return "Account Admin";
    case userTypes.MANAGER:
      return "Account User";
    default:
      return "User";
  }
};

export const userTypeToDescriptionString = userType => {
  switch (userType) {
    case userTypes.OWNER:
      return "Owner";
    case userTypes.ADMIN:
      return "Can add other users to this account.\nCan invite clients\nHave access to client dashboard.";
    case userTypes.MANAGER:
      return "Can invite clients.\nHave access to client dashboard.";
    default:
      return "User";
  }
};

export const OVERRIDE_INITIAL_CC_SETUP_LIST = [
  "kpnv8523@gmail.com",
  "kpnavaneetha@gmail.com",
  "sk11985235@gmail.com",
  "kpnva98@gmail.com",
  "testkubera1@gmail.com",
  "testkubera2@gmail.com",
  "testkubera12345@gmail.com",
  "unclescrooge31@gmail.com",
  "jonsnowappleseed@gmail.com",
  "cm.layouttest@gmail.com",
  "manojmarathayil@gmail.com"
];

export const SUBSCRIPTION_PLANS = Object.freeze({
  TRIAL: "trial",
  MONTHLY: "monthly",
  YEARLY: "yearly",
  YEARLY_FAMILY: "yearly_family",
  YEARLY_BLACK: "yearly_black"
});

export const SUBSCRIPTION_STATUS = Object.freeze({
  ACTIVE: "active",
  CANCELED: "canceled",
  PAST_DUE: "past_due",
  EXPIRED: "expired",
  TRIALING: "trialing",
  INCOMPLETE_EXPIRED: "incomplete_expired"
});

export const SUBSCRIPTION_ERROR = Object.freeze({
  CONFIRMPAYMENT_ERROR: "SUBSCRIPTION_CONFIRMPAYMENT_ERROR",
  CONFIRMPAYMENT_STATUSERROR: "SUBSCRIPTION_CONFIRMPAYMENT_STATUSERROR",
  CONFIRMSETUP_ERROR: "SUBSCRIPTION_CONFIRMSETUP_ERROR",
  CONFIRMSETUP_STATUSERROR: "SUBSCRIPTION_CONFIRMSETUP_STATUSERROR"
});

export const MFA_TYPES = Object.freeze({
  TOTP: "TOTP",
  SMS: "SMS",
  NONE: "NOMFA"
});

export const MFA_PREFERENCES = Object.freeze({
  TOTP: "SOFTWARE_TOKEN_MFA",
  SMS: "SMS_MFA",
  NONE: "NOMFA"
});

export const SUBSCRIPTION_ERROR_CODES = Object.freeze({
  WENT_WRONG: 1001,
  DECLINED: 1024,
  DUPLICATE: 1062
});

export const INITIAL_CARD_SETUP_TIMESTAMP = 1623821400;
export const SIDEBAR_CHANGES_TIMESTAMP = 1695410779;
export const API_ACCESS_TIMESTAMP = 1724220031;

export const BLACK_FEATURE_TRIGGERED = "BLACK_FEATURE_TRIGGERED";

export const setSiteConfigAction = config => ({
  type: SET_SITE_CONFIG,
  config
});

export const removeStripeConnectedAccountAction = config => ({
  type: REMOVE_STRIPE_CONNECTED_ACCOUNT,
  config
});

export const setStatusConfigAction = config => ({
  type: SET_STATUS_CONFIG,
  config
});

export const setUserAction = user => ({
  type: SET_USER,
  user
});

export const setUserErrorAction = userError => ({
  type: SET_USER_ERROR,
  userError
});

export const setUserPreferencesAction = userPreferences => ({
  type: SET_USER_PREFERENCES,
  userPreferences
});

export const setSignInRedirectPathAction = path => ({
  type: SET_SIGNIN_REDIRECT_PATH,
  path
});

export const setSignInWithGoogleFlowAction = isSignInWithGoogleFlow => ({
  type: SET_SIGN_IN_WITH_GOOGLE_FLOW,
  isSignInWithGoogleFlow
});

export const setSignUpWithGoogleFlowAction = isSignUpWithGoogleFlow => ({
  type: SET_SIGN_UP_WITH_GOOGLE_FLOW,
  isSignUpWithGoogleFlow
});

export const fetchCreditBalancePendingAction = isPending => ({
  type: FETCH_CREDIT_BALANCE_PENDING,
  isPending
});

export const updateCreditBalanceAction = balanceInCents => ({
  type: UPDATE_CREDIT_BALANCE,
  balanceInCents
});

export const setAppMaintenanceStatus = isAppInMaintenanceMode => ({
  type: SET_APP_MAINTENANCE_STATUS,
  isAppInMaintenanceMode
});

export const setShowBreakingChangesError = () => ({
  type: SHOW_BREAKING_CHANGES
});

export const fetchMultiuserListPending = isPending => ({
  type: FETCH_MULTIUSER_LIST_PENDING,
  isPending
});

export const setMultiUserList = list => ({
  type: SET_MULTIUSER_LIST,
  list
});

export const setMultiuserInvitationData = (email, invitationId, portfolioId) => ({
  type: SET_MULTIUSER_INVITATION_DATA,
  email,
  invitationId,
  portfolioId
});

export const setReceipts = receipts => ({
  type: SET_RECEIPTS,
  receipts
});

export const setShareMaskAction = shareMaskValue => ({
  type: SET_SHARE_MASK,
  shareMaskValue
});

const featureBlackTriggerAction = (promise, action) => ({
  type: BLACK_FEATURE_TRIGGERED,
  promise,
  action
});

export const showBlackPaywallIfQualifies = (action = "DEFAULT") => {
  return (dispatch, getState) => {
    if (showBlackPaywalls(getState())) {
      const promise = new DeferredPromise();
      dispatch(featureBlackTriggerAction(promise, action));
      return promise;
    }

    return true;
  };
};

export const fetchUser = (onSuccess, onError) => {
  return dispatch => {
    ApiClient.getUser(getUuid())
      .then(apiData => {
        const user = apiData.payload;

        const storedUser = userSelector(store.getState());
        if (!storedUser === false && storedUser.id !== user.id) {
          signOut();
          return;
        }

        if (!user.sharedWithMe === false) {
          injectReducersForSharedPortfolios(user.sharedWithMe);
        }

        dispatch(setUserAction(user));

        const portfolioSessionId = getPortfolioSessionUserId();
        if (
          !portfolioSessionId === false &&
          portfolioSessionId !== user.id &&
          (!user.sharedWithMe === true || !user.sharedWithMe.find(item => item.id === portfolioSessionId) === true)
        ) {
          switchToUsersDefaultAccount();
          return;
        }

        if (!portfolioSessionId === true) {
          setPortfolioSessionUserId(user.id);
        }

        if (!onSuccess === false) {
          onSuccess(user);
        }
      })
      .catch(apiError => {
        if (isAppInViewMode() === false) {
          dispatch(setUserErrorAction(apiError));
        }
        if (!onError === false) {
          onError(apiError);
        }
      });
  };
};

export const verifyEmail = (name, email) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.verifyEmail(getUuid(), name, email)
        .then(apiData => {
          resolve(apiData.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const validateEmail = (name, email, verificationCode) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.validateEmail(getUuid(), name, email, verificationCode)
        .then(apiData => {
          const user = apiData.payload;

          // For WL users set the subscription data
          // as if the subscription is active and will
          // never expire
          if (!user.wl === false) {
            user.subscription = {
              status: "active",
              tsStart: user.tsCreated,
              tsEnd: 1929610173,
              remainingDays: 1000000,
              graceDays: 0
            };
          }

          dispatch(setUserAction(user));
          resolve(user);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const isUserValid = (onSuccess, onError) => {
  return dispatch => {
    ApiClient.isUserValid(getUuid())
      .then(apiData => {
        onSuccess(apiData.payload.valid === 1);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const updateUser = user => {
  return dispatch => {
    dispatch(setUserAction(user));

    const request = idempotentId => ApiClient.setUser(idempotentId, user);
    const syncItem = new SyncItem(SyncItemType.UPDATE, "update_user", request, 0, true, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const updateUserAttributes = (propertiesToUpdate, onSuccess, onError) => {
  return dispatch => {
    const user = { ...userSelector(store.getState()), ...propertiesToUpdate };

    ApiClient.setUser(getUuid(), user)
      .then(apiData => {
        const user = apiData.payload;
        dispatch(setUserAction(user));
        onSuccess(user);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const userSetPasswordUpdated = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = idempotentId =>
        ApiClient.userSetPasswordUpdated(idempotentId)
          .then(apiData => {
            const user = apiData.payload;
            dispatch(setUserAction(user));
            resolve(user);
          })
          .catch(apiError => {
            reject(apiError);
          });

      const syncItem = new SyncItem(SyncItemType.UPDATE, "set_password", request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const getUserProfilePhotoUrl = (onSuccess, onError, pictureToken = undefined) => {
  return dispatch => {
    const user = userSelector(store.getState());

    if (!user === true) {
      return "";
    }
    const profilePicture = pictureToken === undefined ? user.picture : pictureToken;
    if (!profilePicture === true) {
      onSuccess("");
    } else if (profilePicture.startsWith("http") === true) {
      onSuccess(profilePicture);
    } else {
      ApiClient.getProfilePictureDownloadUrl(profilePicture)
        .then(url => {
          onSuccess(url);
        })
        .catch(onError);
    }
  };
};

export const uploadProfilePhoto = (file, onSuccess, onError) => {
  return dispatch => {
    ApiClient.uploadProfilePhoto(getUuid(), file)
      .then(apiData => {
        const user = apiData.payload;
        dispatch(setUserAction(user));
        onSuccess(user);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const requestAccountDeletion = (onSuccess, onError) => {
  return dispatch => {
    ApiClient.requestAccountDeletion(getUuid())
      .then(apiData => {
        onSuccess();
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const updateUserTimezoneOffset = () => {
  return dispatch => {
    if (isAppInViewMode()) {
      return;
    }

    const user = userSelector(store.getState());
    const currentTimezoneOffset = new Date().getTimezoneOffset();

    if (!user === true || user.utcOffset === currentTimezoneOffset) {
      return;
    }
    user.utcOffset = currentTimezoneOffset;
    dispatch(updateUser(user));
  };
};

export const updateUserPreferences = (preferencesToUpdate, syncItemId, delay, fetchLatestBeforeUpdate = false) => {
  return (dispatch, getState) => {
    const siteConfig = siteConfigSelector(getState());
    if (
      siteConfig.mask === 1 &&
      ("maskAllValues" in preferencesToUpdate || "maskAllValuesMobile" in preferencesToUpdate)
    ) {
      const maskAllValues = userMaskAllValuesSelector(getState());
      delete preferencesToUpdate.maskAllValues;
      delete preferencesToUpdate.maskAllValuesMobile;
      dispatch(setShareMaskAction(!maskAllValues));
    }

    const currentPreferences = userPreferencesSelector(getState());
    const updatedPreferences = { ...initialState.userPreferences, ...currentPreferences, ...preferencesToUpdate };

    dispatch(setUserPreferencesAction(updatedPreferences));
    if (isAppInViewMode()) {
      return;
    }

    const queueUpdate = preferences => {
      const request = idempotentId => ApiClient.setUserPreferences(idempotentId, preferences);
      const syncItem = new SyncItem(
        SyncItemType.UPDATE,
        syncItemId || "update_user_preferences",
        request,
        delay || 0,
        true,
        apiData => {}
      );
      dispatch(enqueueItem(syncItem));
    };

    if (fetchLatestBeforeUpdate === true) {
      ApiClient.getUserPreferences(getUuid())
        .then(apiData => {
          const preferences = { ...apiData.payload, ...preferencesToUpdate };
          dispatch(setUserPreferencesAction(preferences));
          queueUpdate(preferences);
        })
        .catch(apiError => {
          queueUpdate(updatedPreferences);
        });
    } else {
      queueUpdate(updatedPreferences);
    }
  };
};

export const deleteUserAccount = (token, onSuccess, onError) => {
  return dispatch => {
    ApiClient.deleteUserAccount(getUuid(), token)
      .then(apiData => {
        onSuccess();
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const markUserActive = (token, onSuccess, onError) => {
  return dispatch => {
    ApiClient.markUserActive(getUuid(), token)
      .then(apiData => {
        onSuccess();
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const requestUserPhoneUpdateOtp = (phone, onSuccess, onError) => {
  return dispatch => {
    ApiClient.requestUserPhoneUpdateOtp(getUuid(), phone)
      .then(apiData => {
        onSuccess();
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const submitUserPhoneUpdateOtp = (phone, otp, onSuccess, onError) => {
  return dispatch => {
    ApiClient.submitUserPhoneUpdateOtp(getUuid(), phone, otp)
      .then(apiData => {
        onSuccess();
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const removeUserPhoneNumber = () => {
  return dispatch => {
    const user = userSelector(store.getState());
    user.phone = null;

    dispatch(setUserAction(user));

    const request = idempotentId => ApiClient.removeUserPhoneNumber(idempotentId);
    const syncItem = new SyncItem(SyncItemType.UPDATE, "remove_user_phone", request, 0, true, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const subscribeUserEmail = (email, source, onSuccess, onError) => {
  return dispatch => {
    ApiClient.subscribeUserEmail(getUuid(), encodeURIComponent(email), source)
      .then(apiData => {
        onSuccess();
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getClientInfo = (onSuccess, onError) => {
  return dispatch => {
    ApiClient.getClientInfo(getUuid(), window.location.host === "beta.kubera.com" ? 1 : 0)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        if (onError) {
          onError(apiError);
        }
      });
  };
};

export const getTrialInfo = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = idempotentId =>
        ApiClient.getTrialInfo(idempotentId)
          .then(apiData => {
            dispatch(setUserAction(apiData.payload.user));

            resolve(apiData);
          })
          .catch(apiError => {
            reject(apiError);
          });

      const syncItem = new SyncItem(SyncItemType.UPDATE, "get_trial", request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const startTrial = paymentMethodId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = idempotentId =>
        ApiClient.startTrial(idempotentId, {
          paymentMethodId
        })
          .then(apiData => {
            dispatch(setUserAction(apiData.payload.user));

            resolve(apiData);
          })
          .catch(apiError => {
            reject(apiError);
          });

      const syncItem = new SyncItem(SyncItemType.CREATE, "start_trial", request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const createSubscription = (paymentMethodId, subscriptionFreaquency, discountCode) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const isPaywallShown = document.querySelector(".cancelled-with-paywall");
      const request = idempotentId =>
        ApiClient.createSubscription(idempotentId, {
          paymentMethodId,
          plan: subscriptionFreaquency,
          discountCode
        })
          .then(apiData => {
            dispatch(
              setUserAction({
                ...store.getState().auth.user,
                subscription: apiData.payload
              })
            );

            resolve(apiData);
          })
          .catch(apiError => {
            reject(apiError);
          });

      const syncItem = new SyncItem(SyncItemType.CREATE, "create_subscription", request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const upgradeSubscription = subscriptionFreaquency => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = idempotentId =>
        ApiClient.upgradeSubscription(idempotentId, {
          plan: subscriptionFreaquency
        })
          .then(apiData => {
            dispatch(
              setUserAction({
                ...store.getState().auth.user,
                subscription: apiData.payload
              })
            );

            resolve(apiData);
          })
          .catch(apiError => {
            reject(apiError);
          });

      const syncItem = new SyncItem(SyncItemType.CREATE, "upgrade_subscription", request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const cancelSubscription = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = idempotentId =>
        ApiClient.cancelSubscription(idempotentId)
          .then(apiData => {
            dispatch(
              setUserAction({
                ...store.getState().auth.user,
                subscription: apiData.payload
              })
            );

            resolve(apiData);
          })
          .catch(apiError => {
            reject(apiError);
          });

      const syncItem = new SyncItem(SyncItemType.CREATE, "cancel_subscription", request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const detachSubscriptionCard = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = idempotentId =>
        ApiClient.detachSubscriptionCard(idempotentId)
          .then(apiData => {
            if (isAppInWhiteLabelMode() === false) {
              dispatch(
                setUserAction({
                  ...store.getState().auth.user,
                  subscription: apiData.payload
                })
              );
            }

            resolve(apiData);
          })
          .catch(apiError => {
            reject(apiError);
          });

      const syncItem = new SyncItem(SyncItemType.CREATE, "detach_subscription_card", request, 0, true, apiData => {
        if (isAppInWhiteLabelMode() !== false) {
          setTimeout(() => {
            window.location.reload();
          }, 10);
        }
      });
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const updateSubscriptionCard = paymentMethodId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = idempotentId =>
        ApiClient.updateSubscriptionCard(idempotentId, {
          paymentMethodId
        })
          .then(apiData => {
            dispatch(
              setUserAction({
                ...store.getState().auth.user,
                subscription: apiData.payload
              })
            );

            resolve(apiData);
          })
          .catch(apiError => {
            reject(apiError);
          });

      const syncItem = new SyncItem(SyncItemType.CREATE, "update_subscription_card", request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const wlUpdateSubscriptionCard = paymentMethodId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = idempotentId =>
        ApiClient.updateSubscriptionCard(idempotentId, {
          paymentMethodId
        })
          .then(apiData => {
            resolve(apiData);
          })
          .catch(apiError => {
            reject(apiError);
          });

      const syncItem = new SyncItem(SyncItemType.CREATE, "update_subscription_card", request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const wlUpdateUnverifiedSubscriptionCard = (setupIntentId, paymentMethodId) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = idempotentId =>
        ApiClient.updateUnverifiedSubscriptionCard(idempotentId, {
          paymentMethodId,
          setupIntentId
        })
          .then(apiData => {
            resolve(apiData);
          })
          .catch(apiError => {
            reject(apiError);
          });

      const syncItem = new SyncItem(SyncItemType.CREATE, "update_subscription_card", request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const retrySubscription = paymentMethodId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = idempotentId =>
        ApiClient.retrySubscription(idempotentId, {
          paymentMethodId
        })
          .then(apiData => {
            dispatch(
              setUserAction({
                ...store.getState().auth.user,
                subscription: apiData.payload
              })
            );

            resolve(apiData);
          })
          .catch(apiError => {
            reject(apiError);
          });

      const syncItem = new SyncItem(SyncItemType.CREATE, "retry_subscription", request, 0, true, apiData => {});
      dispatch(enqueueItem(syncItem));
    });
  };
};

export const setupPaymentIntent = subscriptionFreaquency => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.setupPaymentIntent(getUuid(), {
        plan: subscriptionFreaquency
      })
        .then(apiData => {
          resolve(apiData);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const getSubscriptionState = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.getSubscriptionState(getUuid())
        .then(apiData => {
          dispatch(
            setUserAction({
              ...store.getState().auth.user,
              subscription: apiData.payload
            })
          );

          resolve(apiData);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const exportAndDelete = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.exportAndDelete(getUuid())
        .then(apiData => {
          resolve(apiData);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const generateBackupCodes = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.generateBackupCodes(getUuid())
        .then(apiData => {
          resolve(apiData);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const userPhoneSet = phone => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.userPhoneSet(getUuid(), {
        phone
      })
        .then(apiData => {
          resolve(apiData);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const verifyUserBackupCode = (username, password, backupCode) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const siteConfig = siteConfigSelector(store.getState());
      const clientId = siteConfig.clientId;
      const userPoolId = siteConfig.userPoolId;

      ApiClient.verifyUserBackupCode(getUuid(), {
        username,
        password,
        backupCode,
        clientId,
        userPoolId
      })
        .then(apiData => {
          resolve(apiData);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const userSetMFA = type => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.userSetMFA(getUuid(), type)
        .then(apiData => {
          resolve(apiData);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const setSiteConfig = (onSuccess, onError) => {
  return dispatch => {
    const saveConfig = config => {
      dispatch(setSiteConfigAction(config));
      // The timeout is to ensure that the site config is available to components
      // belonging to the onSuccess callback
      setTimeout(() => {
        onSuccess(config);
      }, 0);
    };

    if (isAppInWhiteLabelMode() === false) {
      const defaultConfig = {
        name: "Kubera",
        id: "kubera",
        website: process.env.REACT_APP_WEB_HOMEPAGE_URL + "/?r",
        userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
        clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        status: "active"
      };
      defaultConfig.logoUrl = process.env.REACT_APP_LOGO_URL.replace("{{id}}", defaultConfig.id);
      defaultConfig.bigLogoUrl = process.env.REACT_APP_BIG_LOGO_URL.replace("{{id}}", defaultConfig.id);
      defaultConfig.darkLogoUrl = process.env.REACT_APP_BIG_LOGO_DARKMODE_URL.replace("{{id}}", defaultConfig.id);

      if (isAppInViewMode()) {
        ApiClient.getViewOnlySiteConfig(getUuid(), getTokenForViewMode())
          .then(apiData => {
            const viewModeConfig = apiData.payload;
            delete viewModeConfig.id;
            saveConfig({ ...viewModeConfig, ...defaultConfig });

            if (!viewModeConfig.userName === false || !viewModeConfig.userEmail === false) {
              setUserNameForViewMode(viewModeConfig.userName || viewModeConfig.userEmail);
            }
          })
          .catch(apiError => {
            onError(apiError);
          });
      } else {
        saveConfig(defaultConfig);
      }
    } else {
      ApiClient.getWlSiteConfig(getUuid(), getSiteWhiteLabelConfigId())
        .then(apiData => {
          const config = apiData.payload;
          config.logoUrl = process.env.REACT_APP_LOGO_URL.replace("{{id}}", config.id);
          config.bigLogoUrl = process.env.REACT_APP_BIG_LOGO_URL.replace("{{id}}", config.id);
          config.darkLogoUrl = process.env.REACT_APP_BIG_LOGO_DARKMODE_URL.replace("{{id}}", config.id);

          if (isAppInViewMode()) {
            ApiClient.getViewOnlySiteConfig(getUuid(), getTokenForViewMode())
              .then(apiData => {
                const viewModeConfig = apiData.payload;
                delete viewModeConfig.id;
                saveConfig({ ...viewModeConfig, ...config });

                if (!viewModeConfig.userName === false || !viewModeConfig.userEmail === false) {
                  setUserNameForViewMode(viewModeConfig.userName || viewModeConfig.userEmail);
                }
              })
              .catch(apiError => {
                onError(apiError);
              });
          } else {
            saveConfig(config);
          }
        })
        .catch(apiError => {
          onError(apiError);
        });
    }
  };
};

export const setUserCollaborateDocumentStatus = status => {
  return dispatch => {
    const user = userSelector(store.getState());
    user.wl.collaborateDocument = status;
    dispatch(setUserAction(user));

    const request = idempotentId => ApiClient.setUserCollaborateDocumentStatus(idempotentId, status);
    const syncItem = new SyncItem(SyncItemType.UPDATE, "update_user", request, 0, true, apiData => {});
    dispatch(enqueueItem(syncItem));
  };
};

export const verifyPasscode = (passcode, onSuccess, onError) => {
  return dispatch => {
    ApiClient.verifyPasscode(getUuid(), passcode)
      .then(apiData => {
        onSuccess(apiData.payload.config);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const applyPromoCode = (promoCode, onSuccess, onError) => {
  return dispatch => {
    ApiClient.applyPromoCode(getUuid(), promoCode)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const updateUserSubscriptionAction = subscription => {
  return dispatch => {
    dispatch(
      setUserAction({
        ...store.getState().auth.user,
        subscription: subscription
      })
    );
  };
};

export const updateCreditBalance = () => {
  return dispatch => {
    dispatch(fetchCreditBalancePendingAction(true));

    ApiClient.getCreditBalance(getUuid())
      .then(apiData => {
        dispatch(fetchCreditBalancePendingAction(false));
        dispatch(updateCreditBalanceAction(apiData.payload.amount));
      })
      .catch(apiError => {
        dispatch(fetchCreditBalancePendingAction(false));
      });
  };
};

export const logOutFromAllDevices = (name, email, onSuccess, onError) => {
  return dispatch => {
    ApiClient.logOutFromAllDevices(getUuid(), name, email)
      .then(apiData => {
        onSuccess();
        signOut();
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getAppStatus = onSuccess => {
  return dispatch => {
    ApiClient.getAppStatus(getUuid())
      .then(apiData => {
        onSuccess(false);
      })
      .catch(apiError => {
        onSuccess(apiError.httpStatus === 503);
      });
  };
};

export const verifyUser = (code, onSuccess, onError) => {
  return dispatch => {
    ApiClient.verifyUser(getUuid(), code)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const getMultiuserList = () => {
  return dispatch => {
    dispatch(fetchMultiuserListPending(true));

    ApiClient.getMultiuserList(getUuid())
      .then(apiData => {
        dispatch(setMultiUserList(apiData.payload));
      })
      .catch(apiError => {
        dispatch(fetchMultiuserListPending(false));
      });
  };
};

export const sendMultiuserInvite = (name, email, access, onSuccess, onError) => {
  return dispatch => {
    ApiClient.sendMultiuserInvite(getUuid(), name, email, access)
      .then(apiData => {
        var userList = multiuserListSelector(store.getState()) || [];
        userList.push(apiData.payload);
        dispatch(setMultiUserList(userList));
        onSuccess();
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const updateMultiuserInvite = (user, propsToUpdate) => {
  return dispatch => {
    var userList = multiuserListSelector(store.getState()) || [];
    var userToUpdateIndex = userList.findIndex(item => item.id === user.id);
    if (userToUpdateIndex === -1) {
      return;
    }

    userList[userToUpdateIndex] = { ...user, ...propsToUpdate };
    dispatch(setMultiUserList([...userList]));

    ApiClient.updateMultiuserInvite(getUuid(), user.id, propsToUpdate)
      .then(() => {})
      .catch(() => {
        userList[userToUpdateIndex] = user;
        dispatch(setMultiUserList([...userList]));
      });
  };
};

export const accpetMultiuserInvite = (invitationId, onSuccess, onError) => {
  return dispatch => {
    ApiClient.acceptMultiuserInvite(getUuid(), invitationId)
      .then(apiData => {
        onSuccess(apiData.payload);
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const deleteSubAccountuser = (multiuserId, onSuccess, onError) => {
  return dispatch => {
    ApiClient.deleteSubAccountuser(getUuid(), multiuserId)
      .then(apiData => {
        var userList = multiuserListSelector(store.getState()) || [];
        userList = userList.filter(item => item.id !== multiuserId);
        dispatch(setMultiUserList(userList));
        onSuccess();
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const subAccountUserSelfLeave = (portfolioId, onSuccess, onError) => {
  return dispatch => {
    ApiClient.subAccountUserSelfLeave(getUuid(), portfolioId)
      .then(() => {
        const user = userSelector(store.getState());
        var userList = multiuserListSelector(store.getState()) || [];

        if (!portfolioId === false) {
          userList = userList.filter(item => item.userId !== user.id || item.portfolioId !== portfolioId);
        } else {
          userList = userList.filter(item => item.userId !== user.id);
        }

        dispatch(setMultiUserList(userList));
        onSuccess();
      })
      .catch(apiError => {
        onError(apiError);
      });
  };
};

export const verifyMultiuserInvitationId = (email, invitationId, onSuccess, onError) => {
  return dispatch => {
    getRecaptchaToken("verify_multiuser_invitation")
      .then(recaptchaToken => {
        return ApiClient.verifyMultiuserInvitationId(getUuid(), email, invitationId, recaptchaToken);
      })
      .then(apiData => {
        onSuccess(apiData.payload.valid === 1);
      })
      .catch(error => {
        onError(error);
      });
  };
};

export const sendMultiuserReInvite = (multiuserId, onSuccess = null, onError = null) => {
  return dispatch => {
    ApiClient.sendMultiuserReInvite(getUuid(), multiuserId)
      .then(apiData => {
        var userList = multiuserListSelector(store.getState()) || [];
        const userIndex = userList.findIndex(item => item.id === multiuserId);

        if (userIndex !== -1) {
          userList[userIndex] = apiData.payload;
          dispatch(setMultiUserList(userList));

          if (onSuccess) {
            onSuccess();
          }
        }
      })
      .catch(apiError => {
        if (onError) {
          onError(apiError);
        }
      });
  };
};

export const getReceipts = () => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.getReceipts(getUuid())
        .then(data => {
          dispatch(setReceipts(data.payload));
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const getReceiptUrl = invoiceId => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      ApiClient.getReceiptUrl(getUuid(), invoiceId)
        .then(data => {
          resolve(data.payload);
        })
        .catch(apiError => {
          reject(apiError);
        });
    });
  };
};

export const injectReducersForSharedPortfolios = sharedPortfolioUsers => {
  const reducers = sharedPortfolioUsers.map(user => {
    const reducerName = portfoliosReducerName(user.id);
    const reducer = (state, action) => {
      action.portfolioUserId = user.id;
      return portfolioReducer({ ...portfoliosInitialState, ...state }, action);
    };
    return { key: reducerName, reducer: reducer, transform: portfolioPersistTransform(user.id) };
  });

  store.injectReducers(reducers);

  sharedPortfolioUsers.forEach(user => {
    rehydrateRecapData(user.id);
    rehydratePortfoliosData(user.id);
  });
};

export const switchToUsersDefaultAccount = () => {
  const user = userSelector(store.getState());

  setPortfolioSessionUserId(user.id);
  const url = `${window.location.protocol}//${window.location.host}`;
  window.location = url;
  window.name = null;
};

export const switchToSharedPortfolioUserAccount = user => {
  if (isMobile()) {
    const url = `${window.location.protocol}//${window.location.host}`;
    window.location = url;

    setTimeout(() => {
      setPortfolioSessionUserId(user.id);
    }, 0);
    return;
  }

  const url = `${window.location.protocol}//${window.location.host}`;
  const sharedWindow = window.kuberaOpen(url, user.id + new Date().getTime());
  if (sharedWindow) {
    sharedWindow.portfolioUserId = user.id;
  }
};

export const optInForBlackTrial = (onSuccess = () => null) => {
  return dispatch => {
    const request = idempotentId => ApiClient.optInForBlackTrial(idempotentId);
    const syncItem = new SyncItem(SyncItemType.UPDATE, "update_user", request, 0, true, apiData => {
      onSuccess();
      setTimeout(() => {
        dispatch(setUserAction(apiData.payload));
      }, 600);
    });
    dispatch(enqueueItem(syncItem));
  };
};

export const getUniqueSessionId = () => {
  return new Promise((resolve, reject) => {
    ApiClient.getUniqueSessionId(getUuid())
      .then(data => {
        resolve(data.payload?.sessionId);
      })
      .catch(apiError => {
        reject(apiError);
      });
  });
};
