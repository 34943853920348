import React, { Suspense } from "react";
import styled from "styled-components";
import i18n from "i18next";
import {
  accountLinkingService,
  deleteProviderAccount,
  getJwtToken,
  store,
  reauthCustodian,
  getLinkedAccounts,
  apiErrorCodes,
  setPublicTokenForCustodian,
  getLinkConnectionUrl,
  getRefreshConnectionUrl,
  getReconnectConnectionUrl,
  linkAccountsWithCustodian,
  unmarkCustodianAsLinking,
  showToastTip,
  custodianLinkingAccountsDataFetched,
  getQueryParams,
  getLinkedConnectionsList,
  utilityStatus,
  custodianLinkingHintWaitTime,
  getAccountLinkingService,
  insertTickerCustodianInSection,
  expandHoldingsForCustodian,
  getLinkTokenForCustodian,
  isCustomWhiteLabelHost,
  custodianSelector,
  getProviderDetails,
  isHardwareWalletProvider,
  isZaboToInHouseApiCandidate,
  isZaboToInHouseOauthCandidate,
  zaboMigrateProviderId,
  isZaboMigrationCandidate,
  getCustodiansLinkedWithProviderAccountId,
  reconnectAccounts,
  currentPortfolioSelector,
  searchProviders,
  isCryptoLinkingService,
  userEmailSelector,
  getConnectionInfo,
  getReConnectionInfo,
  setLinkingProviderStatus,
  getAggregatorName,
  isMobile,
  custodianSheetSelector,
  userSelector,
  isAppInViewMode,
  getTokenForViewMode,
  getAppVersion,
  editAccountsInExistingConnection,
  getConnectivityCenterData,
  getBrowserNameInLowerCase
} from "@kubera/common";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import Loader from "components/loader/Loader";
import { routes, queryParams, hashParams } from "routes";
import { category } from "components/dashboard/DashboardComponent";
import { linkAccountMode } from "components/link_account/LinkAccountComponent";
import yodleeLogo from "assets/images/yodlee_logo.svg";
import saltEdgeLogo from "assets/images/saltedge_logo.png";
import snaptradeLogo from "assets/images/snaptrade_logo.png";
import flinksLogo from "assets/images/flinks_logo.png";
import LinkAccountFailureComponent from "components/link_account/LinkAccountFailureComponent";
import SelectLinkedAccountsComponent from "components/link_account/SelectLinkedAccountsComponent";
import LinkAccountProviderPageListener, {
  linkAccountMessageAction
} from "components/link_account/LinkAccountProviderPageListener";
import ProvidersComponent from "components/link_account/ProvidersComponent";
import ConfirmationDialog from "components/dialog/ConfirmationDialog";
import Cookies from "js-cookie";
import CryptoApiConnectionComponent from "components/link_account/CryptoApiConnectionComponent";
import MatchAccountsComponent from "components/link_account/MatchAccountsComponent";
import ZerionComponent from "./ZerionComponent";
import SelectLinkedConnectionsComponent from "./SelectLinkedConnectionsComponent";
import { configureLogger } from "logger/ConfigureLogger";
import lrConditionalCall from "utilities/lrConditionalCall";
import LogRocket from "logrocket";
import DashboardComponent from "components/dashboard/DashboardComponent";
import DeferredPromise from "utilities/DeferredPromise";

const HardwareWalletComponent = React.lazy(() => import("components/link_account/HardwareWalletComponent"));

export const LINK_CUSTODIAN_ID_KEY = "LINK_CUSTODIAN_ID_KEY";
export const LINK_DOMAIN_REDIRECT_KEY = "LINK_DOMAIN_REDIRECT_KEY";
const PLAID_LINK_TOKEN_SUFFIX = "PLAID_LINK_TOKEN_SUFFIX";

const terminalPlanType = "c";
const fastlinkIframeHeight = 540;
const providerPageContainerId = "container-provider-page";

const linkAccountScreen = {
  ACCOUNT_LIST: "account_list",
  PROVIDER_PAGE: "provider_page",
  LINK_FAILURE: "link_failure",
  PROVIDER_LIST: "provider_list",
  CONNECTION_LIST: "connection_list",
  HARDWARE_WALLET: "hardware_wallet",
  RECONNECT_ACCOUNT: "reconnect_account",
  RECONNECT_ACCOUNT_LOADER: "RECONNECT_ACCOUNT_LOADER",
  ZERION_PAGE: "zerion_page"
};

const LinkDialogOverlay = styled(DialogOverlay)`
  visibility: ${props => (props.visible === true ? "visible" : "hidden")};
`;

const LinkDialog = styled(Dialog)`
  position: relative;
  width: ${props => (props.isMobile === true ? "auto" : "612px")};
  min-height: 633px;
  display: flex;
  align-items: stretch;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  margin: 50px 60px 50px 60px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  flex: 1;
  position: relative;
`;

const PageLoader = styled(Loader)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
`;

const ReconnectLoaderTxt = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: inherit;
  transform: translateY(45px);
  transform-origin: 0 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "ss01" on;
  color: #000000;
`;

const ProviderPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${props => (props.hideMargin === true ? "-2px" : "-20px -30px -20px -30px")};
`;

const ProviderPage = styled.div`
  display: flex;
  min-height: ${props => (!props.minHeight === false ? `${props.minHeight}px` : "")};
  flex-direction: column;
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const ProviderPageIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  min-height: ${props => (!props.minHeight === false ? `${props.minHeight}px` : "")};
`;

const ProviderLogo = styled.div`
  width: 80px;
  height: 27px;
  margin-top: 5px;
  background-color: transparent;
  background-image: url(${props => props.logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
  z-index: 100;
`;

const ProviderScreenContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
`;

const isMetaMaskSupportedBrowser = (function() {
  const name = getBrowserNameInLowerCase();
  switch (name) {
    case "chrome":
    case "firefox":
    case "brave":
    case "edge":
      return true;
    default:
      return false;
  }
})();

class LinkAccountProviderPageComponent extends React.Component {
  static open = (
    regionCode,
    linkMode,
    category,
    portfolioId,
    custodian,
    linkingService,
    selectedProviderDetails,
    provider,
    providerAccountId,
    inline = false
  ) => {
    var url = `${window.location.protocol}//${window.location.host}${routes.LINK_PROVIDER_PAGE}?${queryParams.ID}=${custodian.id}`;
    if (isCustomWhiteLabelHost() === true) {
      url = `${process.env.REACT_APP_COGNITO_BASE_URL}${routes.REDIRECT_HOP}?${queryParams.REDIRECT_URL}=${encodeURI(
        url
      )}&${queryParams.CALLBACK_DOMAIN}=${encodeURI(window.location.host)}`;
    } else {
      LinkAccountProviderPageComponent.setRedirectDomain(window.location.host);
    }

    const linkingWindow = inline === true ? window : window.kuberaOpen(url, custodian.id);

    const details = {
      regionCode: regionCode,
      mode: linkMode,
      category: category,
      portfolioId: portfolioId,
      custodian: custodian,
      linkingService: linkingService,
      selectedProviderDetails: selectedProviderDetails,
      selectedProvider: provider,
      providerAccountId: providerAccountId,
      linkedAccounts: null,
      inline: inline,
      linkedConnections: null,
      currentProviderPlanType: selectedProviderDetails ? selectedProviderDetails.plan : undefined,
      linkingServiceHistory: linkMode === linkAccountMode.RECONNECT ? [] : [linkingService]
    };

    LinkAccountProviderPageComponent.setDetails(custodian.id, details);

    const windowTimer = setInterval(() => {
      if (linkingWindow && linkingWindow.closed === true) {
        store.dispatch(
          unmarkCustodianAsLinking(
            portfolioId,
            custodian,
            !provider === false ? provider.name : custodian.linkProviderName,
            category
          )
        );
        clearInterval(windowTimer);
      }
    }, 400);

    if (linkingWindow) {
      try {
        linkingWindow.onload = () => {
          clearInterval(windowTimer);
        };
      } catch (e) {
        console.log(e);
        clearInterval(windowTimer);
      }
    }

    if (inline === true) {
      setTimeout(() => {
        window.location.href = url;
      }, 500);
    }
    return linkingWindow;
  };

  static reopen = custodianId => {
    const tab = window.kuberaOpen("javascript:void(0)", custodianId); // eslint-disable-line
    tab.focus();
  };

  static close = custodianId => {
    const tab = window.kuberaOpen("javascript:void(0)", custodianId); // eslint-disable-line
    tab.close();
  };

  static setRedirectDomain = host => {
    Cookies.set(LINK_DOMAIN_REDIRECT_KEY, host, { path: "/", domain: ".kubera.com", expires: 1 });
  };

  static getRedirectDomain = () => {
    return Cookies.get(LINK_DOMAIN_REDIRECT_KEY) || window.location.host;
  };

  static setPlaidLinkToken = (custodianId, token) => {
    localStorage.setItem(custodianId + PLAID_LINK_TOKEN_SUFFIX, token);
  };

  static getPlaidLinkToken = custodianId => {
    return localStorage.getItem(custodianId + PLAID_LINK_TOKEN_SUFFIX);
  };

  static setDetails = (custodianId, details) => {
    localStorage.setItem(custodianId, JSON.stringify(details));
  };

  static loadLeanScript = () => {
    const isLoadedPromise = new DeferredPromise();
    const script = document.createElement("script");
    script.src = "https://cdn.leantech.me/link/loader/prod/ae/latest/lean-link-loader.min.js";
    script.async = true;
    script.onload = () => {
      isLoadedPromise.resolve(true);
    };
    script.onerror = () => {
      isLoadedPromise.reject("This provider is facing some issues please try connecting later");
    };
    document.body.appendChild(script);
    return isLoadedPromise;
  };

  constructor(props) {
    super(props);

    // The custodian ID is to be stored both in session and localstorage
    // as all details around the ongoing linking is stored against the
    // custodian ID. There are some cases like Chase UK where the provider
    // might redirect the user back to a different tab than the one which
    // was originally opened for linking. For cases like that localStorage
    // is the fallback. For all other cases sessionStorage works and has
    // hight precedence while reading the custodian ID post redirect in the
    // the RootComponent as we want to support correctly linking multiple
    // accounts in parallel.
    sessionStorage.setItem(LINK_CUSTODIAN_ID_KEY, this.props.custodianId);
    localStorage.setItem(LINK_CUSTODIAN_ID_KEY, this.props.custodianId);

    this.details = JSON.parse(localStorage.getItem(this.props.custodianId));

    // This delay is needed as without this in Safari sometimes the
    // window.postMessage call fails
    setTimeout(() => {
      this.sendMessage(linkAccountMessageAction.PAGE_RELOAD);
    }, 200);

    this.state = {
      isPending: false,
      currentScreen: null,
      error: false,
      linkedAccounts: null,
      linkErrorData: null,
      showAbortLinkingDialog: false,
      hideDialog: false,
      isMetamaskTryCatchError: false
    };

    this.forceReconnectWithSameProvider =
      this.details &&
      this.details.mode !== linkAccountMode.RECONNECT &&
      this.details.custodian.status === 1 &&
      this.details.custodian.statusInfo === "FORCE_RECONNECT_SAME";
    this.isCustodianAccountIdMismatch =
      this.details &&
      this.details.mode !== linkAccountMode.RECONNECT &&
      this.details.custodian.status === 1 &&
      this.details.custodian.statusInfo === "NO_MATCHING_ACCOUNT_ID";
    this.shouldWarnUserOnWindowClose = true;
    this.currentProviderPlanType = this.details ? this.details.currentProviderPlanType : "";
    this.linkingServiceHistory = this.details ? this.details.linkingServiceHistory : [];
    this.isLinkingCompleted = false;
    this.isRelinkFlow = false;
    this.iFrameEnabled = true;
    this.selectedAccounts = null;
    this.isProviderSelectedInReconnectFlow = false;
    this.aggregatorToBeSkippedOnProviderSearchScreen = null;
    this.linkProviderIdToBeSkippedOnProviderSearchScreen = null;
    this.blinkTab = this.blinkTab.bind(this);
    this.handleLinkFailureNegativeButtonClick = this.handleLinkFailureNegativeButtonClick.bind(this);
    this.handleLinkFailurePositiveButtonClick = this.handleLinkFailurePositiveButtonClick.bind(this);
    this.handleProviderSelection = this.handleProviderSelection.bind(this);
    this.handleProviderPageExit = this.handleProviderPageExit.bind(this);
    this.handleWindowUnload = this.handleWindowUnload.bind(this);
    this.handleLinkAccount = this.handleLinkAccount.bind(this);
    this.handleLoaderRefreshClick = this.handleLoaderRefreshClick.bind(this);
    this.handleOverlayDismiss = this.handleOverlayDismiss.bind(this);
    this.handleAbortDialogNegativeButtonClick = this.handleAbortDialogNegativeButtonClick.bind(this);
    this.handleAbortDialogPositiveButtonClick = this.handleAbortDialogPositiveButtonClick.bind(this);
    this.handleLinkedConnectionSelection = this.handleLinkedConnectionSelection.bind(this);
    this.handleLinkFailureManualEntry = this.handleLinkFailureManualEntry.bind(this);
    this.handleReconnectAccounts = this.handleReconnectAccounts.bind(this);
    this.closeWindow = this.closeWindow.bind(this);
  }

  blinkTab() {
    const oldTitle = this.details?.selectedProvider
      ? this.details.selectedProvider.name
      : this.getCurrentProviderName();
    const newTitle = "Please keep an eye here";
    const blink = function() {
      if (document.visibilityState === "visible") {
        clear();
      } else {
        document.title = document.title === oldTitle ? newTitle : oldTitle;
      }
    };
    var timeoutId = setInterval(blink, 1000);
    const clear = function() {
      clearInterval(timeoutId);
      document.title = oldTitle;
      timeoutId = null;
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
    window.addEventListener("beforeunload", this.handleWindowUnload);
    document.addEventListener("visibilitychange", event => {
      if (document.visibilityState !== "visible") {
        setTimeout(this.blinkTab, custodianLinkingHintWaitTime);
      }
    });

    this.setupReporting();
    this.setWindowTitle();
    this.fetchData();
  }

  setupReporting() {
    const user = this.props.user;
    // Enable LR sessions only for users who are not being shown the
    // subscription paywall
    if (isAppInViewMode() === false && !user === true) {
      return;
    }

    const userId = isAppInViewMode() ? getTokenForViewMode() : user.id;

    configureLogger();

    const isLrExecuted = lrConditionalCall(() => {
      LogRocket.identify(userId, {
        displayName: userId,
        appVersion: getAppVersion()
      });

      LogRocket.getSessionURL(function(sessionURL) {
        DashboardComponent.trackSession(userId, user, sessionURL);
      });
    }, false);

    if (!isLrExecuted) {
      DashboardComponent.trackSession(userId, user);
    }
  }

  componentDidUpdate(oldProps) {
    if (!oldProps.providerPageDetails === true && !this.props.providerPageDetails === false) {
      this.details = this.props.providerPageDetails;
      this.fetchData();
    }
    this.setWindowTitle();
  }

  setWindowTitle() {
    if (!this.props.inline === true && this.details?.selectedProvider) {
      document.title = this.details.selectedProvider.name;
    }
  }

  fetchData(isRetry = false) {
    if (!this.details === true) {
      return;
    }

    const params = getQueryParams(window.location);
    if (this.details.linkingService === accountLinkingService.AKAHU) {
      if (!params[queryParams.AKAHU_CODE] === false) {
        this.handleProviderPageSuccess({ code: params[queryParams.AKAHU_CODE] });
        return;
      } else if (!params[queryParams.AKAHU_ERROR] === false) {
        // This timeout is needed so that the page_reload event doesn't fire after this is fired
        setTimeout(() => {
          this.handleProviderPageExit();
        }, 250);
        return;
      }
    } else if (!params[queryParams.CONNECTION_ID] === false) {
      if (!params[queryParams.SE_ERROR_CLASS] === true) {
        this.handleProviderPageSuccess({ connectionId: params[queryParams.CONNECTION_ID] });
      } else {
        this.handleProviderPageFailure({
          errorCode: params[queryParams.SE_ERROR_CLASS],
          errorMessage: params[queryParams.SE_ERROR_CLASS]
        });
      }
      return;
    } else if (isRetry === false && !params[queryParams.PLAID_REDIRECT_ID] === false) {
      const linkToken = LinkAccountProviderPageComponent.getPlaidLinkToken(this.details.custodian.id);

      if (this.details.mode === linkAccountMode.RECONNECT) {
        this.fetchProviderDetails(this.details.linkingService, this.getCurrentProviderId(), providerDetails => {
          this.updateSelectedProviderDetails(providerDetails);

          const nextAvailableAggregator = this.getNextAvailableAggregator();
          if (!nextAvailableAggregator === true) {
            this.openPlaidProviderPage(linkToken);
          } else {
            this.details.linkingService = nextAvailableAggregator.linkType;
            this.currentProviderPlanType = nextAvailableAggregator.plan;
            this.openPlaidProviderPage(linkToken);
          }
        });
      } else {
        this.openPlaidProviderPage(linkToken);
      }
      return;
    } else if (
      this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_OAUTH &&
      !params[queryParams.CRYPTO_OAUTH_CODE] === false
    ) {
      this.handleProviderPageSuccess({ code: params[queryParams.CRYPTO_OAUTH_CODE] });
      return;
    }

    if (this.details.mode === linkAccountMode.LINK) {
      if (
        this.details.linkingService === accountLinkingService.SALTEDGE ||
        this.details.linkingService === accountLinkingService.SALTEDGE_EU
      ) {
        this.showLinkedConnectionsList(this.details.selectedProviderDetails.groupId);
        return;
      }
      this.linkAccount(this.details.selectedProvider);
    } else if (this.details.mode === linkAccountMode.REFRESH) {
      this.refreshAccount();
    } else if (this.details.mode === linkAccountMode.EDIT) {
      this.editAccount();
    } else if (this.details.mode === linkAccountMode.RECONNECT) {
      this.reconnectAccount();
    } else if (this.details.mode === linkAccountMode.MANAGE) {
      this.manageAccount();
    } else if (this.details.mode === linkAccountMode.SELECT_ACCOUNTS) {
      this.selectAccounts();
    }
  }

  goToDashboard() {
    window.name = null;
    window.details = null;
    document.title = i18n.t("appName");

    const pathname = this.details.category === category.ASSET ? routes.ASSETS : routes.DEBTS;
    const sheet = custodianSheetSelector(store.getState(), this.details.custodian.id);

    this.props.history.replace({
      ...this.props.location,
      pathname: pathname,
      search: `${queryParams.PORTFOLIO_ID}=${this.details.portfolioId}&${queryParams.NO_DATA_FETCH}=${1}`,
      hash: sheet ? `${hashParams.SHEET_ID}=${sheet.id}` : ""
    });
  }

  isMainWindowOpen() {
    return !window.opener === false && window.opener.closed === false && !this.props.inline === true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener("beforeunload", this.handleWindowUnload);
  }

  handleWindowUnload(event) {
    if (this.iFrameEnabled === true) {
      this.sendExitMessage();
    }

    if (this.shouldWarnUserOnWindowClose === true) {
      if (this.isMainWindowOpen() === false) {
        this.props.unmarkCustodianAsLinking(
          this.details.portfolioId,
          this.details.custodian,
          this.getCurrentProviderName(),
          this.details.category
        );
      }

      event.preventDefault();
      event.returnValue = "Don't leave";
    }
  }

  closeWindow() {
    this.shouldWarnUserOnWindowClose = false;
    window.close();
  }

  sendMessage(action, data = null) {
    const receiverWindow = this.props.inline === true ? window : window.opener;
    if (!receiverWindow === true) {
      return;
    }
    LinkAccountProviderPageListener.sendMessage(receiverWindow, this.details, action, data);
  }

  linkAccount(provider = null) {
    this.updateSelectedProviderDetails(this.details.selectedProviderDetails, provider);

    if (!provider === false && isHardwareWalletProvider(this.details.linkingService, provider.linkProviderId)) {
      this.openHardwareWalletProviderPage(provider.linkProviderId);
    } else if (this.details.linkingService === accountLinkingService.YODLEE) {
      const linkParams = this.getYodleeLinkAccountParams(provider.linkProviderId);
      this.openYodleeProviderPage(linkParams);
    } else if (this.details.linkingService === accountLinkingService.PLAID) {
      this.openPlaidProviderPage();
    } else if (this.details.linkingService === accountLinkingService.ZABO) {
      this.openZaboProviderPage(provider.linkProviderId);
    } else if (
      this.details.linkingService === accountLinkingService.SALTEDGE ||
      this.details.linkingService === accountLinkingService.SALTEDGE_EU
    ) {
      this.openSaltEdgeProviderPage(provider.linkProviderId);
    } else if (this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_OAUTH) {
      this.openInHouseCryptoOAuthProviderPage(provider.linkProviderId);
    } else if (this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_API) {
      this.openInHouseCryptoApiProviderPage(provider.linkProviderId);
    } else if (this.details.linkingService === accountLinkingService.ZERION) {
      this.openZerionProviderPage(provider.linkProviderId);
    } else if (
      this.details.linkingService === accountLinkingService.FLINKS_BANKING ||
      this.details.linkingService === accountLinkingService.FLINKS_INVESTMENT
    ) {
      this.openFlinksProviderPage(provider.linkProviderId);
    } else if (this.details.linkingService === accountLinkingService.AKAHU) {
      this.openAkahuProviderPage(provider.linkProviderId);
    } else if (this.details.linkingService === accountLinkingService.LEAN) {
      this.openLeanProviderPage(provider.linkProviderId);
    } else if (this.details.linkingService === accountLinkingService.FINICITY) {
      this.openFinicityProviderPage(provider.linkProviderId);
    } else if (this.details.linkingService === accountLinkingService.SNAPTRADE) {
      this.openSnaptradeProviderPage(provider.linkProviderId);
    } else if (this.details.linkingService === accountLinkingService.MX) {
      this.openMXProviderPage(provider.linkProviderId);
    }
  }

  refreshAccount() {
    if (isZaboMigrationCandidate(this.details.custodian)) {
      this.details.custodian.linkProviderId = zaboMigrateProviderId(this.details.custodian.linkProviderId);
      if (isZaboToInHouseApiCandidate(this.details.custodian.linkProviderId)) {
        this.details.linkingService = accountLinkingService.IN_HOUSE_CRYPTO_API;
      } else if (isZaboToInHouseOauthCandidate(this.details.custodian.linkProviderId)) {
        this.details.linkingService = accountLinkingService.IN_HOUSE_CRYPTO_OAUTH;
      }
    }

    if (this.isCustodianAccountIdMismatch === true || this.forceReconnectWithSameProvider === true) {
      this.details.mode = linkAccountMode.RECONNECT;
      this.fetchData();
      return;
    }

    this.fetchProviderDetails(this.details.linkingService, this.getCurrentProviderId(), providerDetails => {
      if (!providerDetails) {
        this.handleProviderPageFailure({
          errorCode: "NO_PROVIDER_DETAILS_FOUND"
        });
        return;
      }
      this.updateSelectedProviderDetails(providerDetails);
      LinkAccountProviderPageComponent.setDetails(this.details.custodian.id, this.details);

      if (isHardwareWalletProvider(this.details.linkingService, this.details.custodian.linkProviderId)) {
        this.openHardwareWalletProviderPage(this.details.custodian.linkProviderId);
      } else if (this.details.linkingService === accountLinkingService.YODLEE) {
        const providerAccountId = this.details.custodian.linkProviderAccountId;
        const linkParams = this.getYodleeRefreshAccountParams(providerAccountId);
        this.openYodleeProviderPage(linkParams);
      } else if (this.details.linkingService === accountLinkingService.PLAID) {
        this.openPlaidProviderPage();
      } else if (this.details.linkingService === accountLinkingService.ZABO) {
        this.openZaboProviderPage(this.details.custodian.linkProviderId);
      } else if (
        this.details.linkingService === accountLinkingService.SALTEDGE ||
        this.details.linkingService === accountLinkingService.SALTEDGE_EU
      ) {
        this.openSaltEdgeProviderPage(
          this.details.custodian.linkProviderId,
          this.details.custodian.linkProviderAccountId
        );
      } else if (this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_OAUTH) {
        this.openInHouseCryptoOAuthProviderPage(this.details.custodian.linkProviderId);
      } else if (this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_API) {
        this.openInHouseCryptoApiProviderPage(this.details.custodian.linkProviderId);
      } else if (this.details.linkingService === accountLinkingService.ZERION) {
        this.openZerionProviderPage(this.details.custodian.linkProviderId);
      } else if (
        this.details.linkingService === accountLinkingService.FLINKS_BANKING ||
        this.details.linkingService === accountLinkingService.FLINKS_INVESTMENT
      ) {
        this.details.mode = linkAccountMode.RECONNECT;
        LinkAccountProviderPageComponent.setDetails(this.details.custodian.id, this.details);
        this.openFlinksProviderPage(this.details.selectedProvider.linkProviderId);
      } else if (this.details.linkingService === accountLinkingService.AKAHU) {
        this.details.mode = linkAccountMode.RECONNECT;
        LinkAccountProviderPageComponent.setDetails(this.details.custodian.id, this.details);
        this.openAkahuProviderPage(this.details.selectedProvider.linkProviderId);
      } else if (this.details.linkingService === accountLinkingService.LEAN) {
        this.openLeanProviderPage(this.details.custodian.linkProviderId, this.details.custodian.linkProviderAccountId);
      } else if (this.details.linkingService === accountLinkingService.FINICITY) {
        this.openFinicityProviderPage(
          this.details.custodian.linkProviderId,
          this.details.custodian.linkProviderAccountId
        );
      } else if (this.details.linkingService === accountLinkingService.SNAPTRADE) {
        this.openSnaptradeProviderPage(
          this.details.custodian.linkProviderId,
          this.details.custodian.linkProviderAccountId
        );
      } else if (this.details.linkingService === accountLinkingService.MX) {
        this.openMXProviderPage(this.details.custodian.linkProviderId, this.details.custodian.linkProviderAccountId);
      }
    });
  }

  editAccount() {
    if (isZaboMigrationCandidate(this.details.custodian)) {
      this.details.custodian.linkProviderId = zaboMigrateProviderId(this.details.custodian.linkProviderId);
      if (isZaboToInHouseApiCandidate(this.details.custodian.linkProviderId)) {
        this.details.linkingService = accountLinkingService.IN_HOUSE_CRYPTO_API;
      } else if (isZaboToInHouseOauthCandidate(this.details.custodian.linkProviderId)) {
        this.details.linkingService = accountLinkingService.IN_HOUSE_CRYPTO_OAUTH;
      }
    }

    if (this.isCustodianAccountIdMismatch === true || this.forceReconnectWithSameProvider === true) {
      this.details.mode = linkAccountMode.RECONNECT;
      this.fetchData();
      return;
    }

    this.fetchProviderDetails(this.details.linkingService, this.getCurrentProviderId(), providerDetails => {
      if (!providerDetails) {
        this.handleProviderPageFailure({
          errorCode: "NO_PROVIDER_DETAILS_FOUND"
        });
        return;
      }
      this.updateSelectedProviderDetails(providerDetails);
      LinkAccountProviderPageComponent.setDetails(this.details.custodian.id, this.details);

      if (isHardwareWalletProvider(this.details.linkingService, this.details.custodian.linkProviderId)) {
        this.openHardwareWalletProviderPage(this.details.custodian.linkProviderId);
      } else if (this.details.linkingService === accountLinkingService.YODLEE) {
        const providerAccountId = this.details.custodian.linkProviderAccountId;
        const linkParams = this.getYodleeEditAccountParams(providerAccountId);
        this.openYodleeProviderPage(linkParams);
      } else if (this.details.linkingService === accountLinkingService.PLAID) {
        this.openPlaidProviderPage();
      } else if (this.details.linkingService === accountLinkingService.ZABO) {
        this.openZaboProviderPage(this.details.custodian.linkProviderId);
      } else if (
        this.details.linkingService === accountLinkingService.SALTEDGE ||
        this.details.linkingService === accountLinkingService.SALTEDGE_EU
      ) {
        this.openSaltEdgeProviderPage(
          this.details.custodian.linkProviderId,
          this.details.custodian.linkProviderAccountId
        );
      } else if (this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_OAUTH) {
        this.openInHouseCryptoOAuthProviderPage(this.details.custodian.linkProviderId);
      } else if (this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_API) {
        this.openInHouseCryptoApiProviderPage(this.details.custodian.linkProviderId);
      } else if (this.details.linkingService === accountLinkingService.ZERION) {
        this.openZerionProviderPage(this.details.custodian.linkProviderId);
      } else if (
        this.details.linkingService === accountLinkingService.FLINKS_BANKING ||
        this.details.linkingService === accountLinkingService.FLINKS_INVESTMENT
      ) {
        this.details.mode = linkAccountMode.RECONNECT;
        LinkAccountProviderPageComponent.setDetails(this.details.custodian.id, this.details);
        this.openFlinksProviderPage(this.details.selectedProvider.linkProviderId);
      } else if (this.details.linkingService === accountLinkingService.AKAHU) {
        this.details.mode = linkAccountMode.RECONNECT;
        LinkAccountProviderPageComponent.setDetails(this.details.custodian.id, this.details);
        this.openAkahuProviderPage(this.details.selectedProvider.linkProviderId);
      } else if (this.details.linkingService === accountLinkingService.LEAN) {
        this.openLeanProviderPage(this.details.custodian.linkProviderId, this.details.custodian.linkProviderAccountId);
      } else if (this.details.linkingService === accountLinkingService.FINICITY) {
        this.openFinicityProviderPage(
          this.details.custodian.linkProviderId,
          this.details.custodian.linkProviderAccountId
        );
      } else if (this.details.linkingService === accountLinkingService.SNAPTRADE) {
        this.openSnaptradeProviderPage(
          this.details.custodian.linkProviderId,
          this.details.custodian.linkProviderAccountId
        );
      } else if (this.details.linkingService === accountLinkingService.MX) {
        this.openMXProviderPage(this.details.custodian.linkProviderId, this.details.custodian.linkProviderAccountId);
      }
    });
  }

  manageAccount() {
    this.props.searchProviders(
      this.details.linkingService,
      this.details.category,
      this.details.custodian.linkProviderName,
      async (inputText, results = []) => {
        let selectedProvider = results.filter(
          result => result.linkProviderId === this.details.custodian.linkProviderId
        );

        if (selectedProvider.length === 0) {
          selectedProvider = [
            await this.fetchProviderDetails(this.details.linkingService, this.getCurrentProviderId(), () => null)
          ];
        }
        this.updateSelectedProviderDetails(selectedProvider[0]);
        if (
          [accountLinkingService.IN_HOUSE_CRYPTO_OAUTH, accountLinkingService.IN_HOUSE_CRYPTO_API].includes(
            this.details.linkingService
          )
        ) {
          this.setState({
            isPending: true,
            currentScreen: linkAccountScreen.ACCOUNT_LIST,
            error: null
          });
          this.handleProviderPageSuccessData({ accountId: this.details.custodian.linkProviderAccountId });
        } else if (this.details.linkingService === accountLinkingService.ZERION) {
          this.openZerionProviderPage(this.details.custodian.linkProviderId);
        }
      },
      apiError => {
        this.setState({ ...this.state, isSearchPending: false });
      }
    );
  }

  reconnectAccount(providerDetails = null) {
    if (providerDetails) {
      // Provider details will be available when selected via provider selection screen
      this.linkAccount(providerDetails);
    } else {
      // selected provider details will be available when edit flow has failed for some reason (provider Id marked for delete)
      // and then we reconnect the account. In this case we need to fetch latest link type. this.details.linkingService will have
      // old link type due to which providerDetails will be null
      const linkTypeForProviderDetails = this.details.selectedProviderDetails
        ? this.details.selectedProviderDetails.linkType
        : this.details.linkingService;
      const linkProviderIdForProviderDetails = this.details.selectedProviderDetails
        ? this.details.selectedProviderDetails.linkProviderId
        : this.details.custodian.linkProviderId;
      this.fetchProviderDetails(linkTypeForProviderDetails, linkProviderIdForProviderDetails, providerDetails => {
        if (!providerDetails) {
          this.handleProviderPageFailure({
            errorCode: "NO_PROVIDER_DETAILS_FOUND"
          });
          return;
        }
        const reauthSameProvider = () => {
          this.details.mode = linkAccountMode.EDIT;
          LinkAccountProviderPageComponent.setDetails(this.details.custodian.id, this.details);
          this.fetchData();
        };

        const selectedProvider = {
          linkProviderId: linkProviderIdForProviderDetails,
          name: this.getCurrentProviderName()
        };
        this.updateSelectedProviderDetails(providerDetails, selectedProvider);
        LinkAccountProviderPageComponent.setDetails(this.details.custodian.id, this.details);

        if (this.isCustodianAccountIdMismatch === true || this.forceReconnectWithSameProvider === true) {
          const currentAggregator = this.details.selectedProviderDetails.aggregatorOptions.find(
            aggregator => aggregator.linkProviderId === this.details.selectedProvider.linkProviderId
          );

          if (!currentAggregator === true) {
            reauthSameProvider();
          } else {
            this.showNextProvider(currentAggregator, true);
          }
        } else if (this.canReconnectAccounts(this.details.linkingService, providerDetails) === false) {
          reauthSameProvider();
        } else {
          const nextAvailableAggregator = this.getNextAvailableAggregator();
          if (!nextAvailableAggregator === true) {
            if (this.details.linkingService === accountLinkingService.PLAID) {
              this.showProviderList();
              this.handleProviderPageRetry();
              this.currentProviderPlanType = terminalPlanType;
            } else {
              this.showNextProvider(providerDetails.aggregatorOptions[0], true);
            }
          } else {
            this.showNextProvider(nextAvailableAggregator);
          }
        }
      });
    }
  }

  selectAccounts() {
    this.fetchProviderDetails(this.details.linkingService, this.getCurrentProviderId(), providerDetails => {
      this.updateSelectedProviderDetails(providerDetails);
      LinkAccountProviderPageComponent.setDetails(this.details.custodian.id, this.details);

      const needLinkingToSelectAccounts =
        this.details.linkingService === accountLinkingService.PLAID ||
        this.details.linkingService === accountLinkingService.AKAHU;

      this.currentProviderPlanType = terminalPlanType;

      if (needLinkingToSelectAccounts) {
        this.linkAccount(this.details.selectedProvider);
      } else {
        this.handleProviderPageSuccessData(null, this.details.custodian.linkProviderAccountId);
      }
    });
  }

  canReconnectAccounts(linkingService, providerDetails) {
    if (
      this.forceReconnectWithSameProvider === true ||
      this.isCustodianAccountIdMismatch === true ||
      this.currentProviderPlanType === terminalPlanType ||
      !providerDetails
    ) {
      return false;
    }
    return true;
  }

  fetchProviderDetails(linkingService, providerId, onSuccess) {
    return new Promise((resolve, reject) => {
      this.setState({ isPending: true, error: null });

      this.props.getProviderDetails(
        linkingService,
        providerId,
        providerDetails => {
          this.setState({ isPending: false });
          if (providerDetails.length === 0) {
            resolve(null);
            onSuccess(null);
          } else {
            if (!providerDetails[0].countryCode === true) {
              providerDetails[0].countryCode = "";
            }
            this.details.regionCode = providerDetails[0].countryCode.toUpperCase();
            resolve(providerDetails[0]);
            onSuccess(providerDetails[0]);
          }
        },
        apiError => {
          this.setState({ isPending: false, error: apiError, hideDialog: false });
        }
      );
    });
  }

  updateSelectedProviderDetails(providerDetails, selectedProvider = null) {
    if (!providerDetails === true) {
      return;
    }

    this.details.selectedProviderDetails = { ...providerDetails, ...providerDetails.aggregatorOptions[0] };
    if (selectedProvider) {
      this.details.selectedProvider = selectedProvider;
    } else {
      this.details.selectedProvider = {
        linkProviderId: this.details.selectedProviderDetails.linkProviderId,
        name: this.details.selectedProviderDetails.name
      };
    }
  }

  getCurrentProviderId() {
    if (this.details.mode === linkAccountMode.LINK || this.isProviderSelectedInReconnectFlow) {
      return this.details.selectedProvider ? this.details.selectedProvider.linkProviderId : "";
    }
    if (this.details.selectedProvider && this.details.selectedProvider.linkProviderId) {
      return this.details.selectedProvider.linkProviderId;
    }
    return this.details.custodian.linkProviderId;
  }

  getCurrentProviderName() {
    if (this.details.mode === linkAccountMode.LINK || this.isProviderSelectedInReconnectFlow) {
      return this.details.selectedProvider ? this.details.selectedProvider.name : "";
    }
    if (this.details.selectedProvider && this.details.selectedProvider.name) {
      return this.details.selectedProvider.name;
    }
    return this.details.custodian.linkProviderName;
  }

  getNextAvailableAggregator() {
    if (this.currentProviderPlanType === terminalPlanType) {
      return null;
    }

    const providerDetails = this.details.selectedProviderDetails;
    if (!providerDetails === true) {
      return null;
    }
    this.details.regionCode = providerDetails.countryCode ? providerDetails.countryCode.toUpperCase() : "";
    if (providerDetails.aggregatorOptions.length === 1) {
      return null;
    }
    // by default, aggregator option for any any provider id will have same providerid and aggregator as plan A,
    // but in a scenerio if the connected provider is deleted, It will have next aggreagator option as plan a.
    // In such a scenerio, we will be taking Plan a as next available aggregator for reconnection
    const planAOption = providerDetails.aggregatorOptions.find(aggregator => aggregator.plan === "a");
    if (!this.details.selectedProvider === true) {
      return planAOption;
    } else {
      const currentAggregator = providerDetails.aggregatorOptions.find(
        aggregator => aggregator.linkProviderId === this.details.selectedProvider.linkProviderId
      );

      if (!currentAggregator === true && this.getCurrentProviderId() !== (planAOption && planAOption.linkProviderId)) {
        return planAOption;
      }

      if (!currentAggregator === true) {
        return null;
      }

      if (currentAggregator.plan === "a") {
        return providerDetails.aggregatorOptions.find(aggregator => aggregator.plan === "b");
      } else if (currentAggregator.plan === "b") {
        return providerDetails.aggregatorOptions.find(aggregator => aggregator.plan === "c");
      }
    }
    return null;
  }

  openYodleeProviderPage(params) {
    var invalidCredentialsRetryCount = 0;

    this.getJwtToken(token => {
      var openParams = {
        fastLinkURL: process.env.REACT_APP_FASTLINK_URL,
        accessToken: token,
        params: params,
        onSuccess: data => {
          console.log("onSuccess");
          console.log(data);
          this.handleProviderPageSuccess(data);
        },
        onError: data => {
          console.log("onError");
          console.log(data);

          var statusCode = "Unknown";
          var reason = "Unknown";
          if (data.fnToCall === "accountStatus" && data.status === "FAILED") {
            statusCode = data.statusCode;
            reason = data.reason;
          } else {
            if (!data.code === false) {
              statusCode = data.code;
            }
            if (!data.message === false) {
              reason = data.message;
            }
          }

          var invalidCredentials = data.additionalStatus === "INCORRECT_CREDENTIALS";
          if (invalidCredentials === true) {
            invalidCredentialsRetryCount++;

            if (invalidCredentialsRetryCount >= 2) {
              this.handleProviderPageFailure({
                errorCode: statusCode,
                errorMessage: reason
              });
            }
            return;
          }

          if (invalidCredentials === false) {
            window.fastlink.close();
          }
          this.handleProviderPageFailure({
            errorCode: statusCode,
            errorMessage: reason,
            invalidCredentials: invalidCredentials,
            ...data
          });

          if (data.siteAccountId) {
            this.props.deleteProviderAccount(this.details.linkingService, data.siteAccountId);
          }
        },
        onExit: data => {
          console.log("onExit");
          console.log(data);
          this.handleProviderPageExit();
        },
        onEvent: data => {
          console.log("onEvent");
          console.log(data);

          if (data && data.status === "ACTION_ABANDONED") {
            this.handleProviderPageExit();
          }
        }
      };

      this.setState(
        {
          ...this.state,
          isPending: true,
          currentScreen: linkAccountScreen.PROVIDER_PAGE,
          error: null
        },
        async () => {
          const isLoaded = await window.yodleeLoadPromise.catch(err => {
            window.confirm(err);
            window.close();
          });
          if (!isLoaded) return;
          window.fastlink.open(openParams, providerPageContainerId);
          // Showing loading screen until the iframe loads
          const iFrames = document.getElementById(providerPageContainerId).getElementsByTagName("iframe");

          if (iFrames && iFrames.length > 0) {
            const fastLinkIframe = iFrames[0];
            fastLinkIframe.style["height"] = `${fastlinkIframeHeight}px`;
            fastLinkIframe.onload = () => {
              this.setState({ isPending: false });
            };
          } else {
            setTimeout(() => {
              this.setState({ isPending: false });
            }, 1000);
          }
        }
      );
    });
  }

  getJwtToken(onSuccess) {
    this.setState({ isPending: true, currentScreen: null, error: null });

    const category = this.details.category;
    // When Reauth via plaid fails, we will be reconnecting via yodlee / Flinks. action for reconnecting will be ADD
    const action =
      this.isCustodianAccountIdMismatch === true || this.details.mode === linkAccountMode.RECONNECT
        ? linkAccountMode.LINK
        : this.details.mode;

    const providerId = this.getCurrentProviderId();
    const providerName = this.getCurrentProviderName();
    this.props.getJwtToken(
      this.details.linkingService,
      category,
      action,
      providerId,
      providerName,
      token => {
        this.setState({
          isPending: false,
          error: null
        });

        onSuccess(token);
      },
      apiError => {
        this.setState({ isPending: false, error: apiError });
      }
    );
  }

  getYodleeLinkAccountParams(providerId) {
    return {
      flow: "add",
      providerId: providerId,
      configName: "Aggregation",
      userExperienceFlow: {
        Aggregation: {}
      },
      dataset: [
        {
          name: "BASIC_AGG_DATA",
          attribute: [
            {
              name: "BASIC_ACCOUNT_INFO"
            },
            {
              name: "HOLDINGS"
            }
          ]
        }
      ]
    };
  }

  getYodleeRefreshAccountParams(providerAccountId) {
    return {
      flow: "refresh",
      providerAccountId: providerAccountId,
      configName: "Aggregation",
      userExperienceFlow: {
        Aggregation: {}
      },
      dataset: [
        {
          name: "BASIC_AGG_DATA",
          attribute: [
            {
              name: "BASIC_ACCOUNT_INFO"
            },
            {
              name: "HOLDINGS"
            }
          ]
        }
      ]
    };
  }

  getYodleeEditAccountParams(providerAccountId) {
    return {
      flow: "edit",
      providerAccountId: providerAccountId,
      configName: "Aggregation",
      userExperienceFlow: {
        Aggregation: {}
      },
      dataset: [
        {
          name: "BASIC_AGG_DATA",
          attribute: [
            {
              name: "BASIC_ACCOUNT_INFO"
            },
            {
              name: "HOLDINGS"
            }
          ]
        }
      ]
    };
  }

  openPlaidProviderPage(persistedToken = null) {
    this.setState({
      currentScreen: linkAccountScreen.PROVIDER_PAGE,
      error: null
    });

    const isLinkingFlow =
      this.details.mode === linkAccountMode.LINK ||
      this.details.mode === linkAccountMode.RECONNECT ||
      this.details.mode === linkAccountMode.SELECT_ACCOUNTS;

    const countryCode = isLinkingFlow === true ? this.details.regionCode : null;

    const openPlaid = async linkToken => {
      const isLoaded = await window.plaidLoadPromise.catch(err => {
        window.confirm(err);
        window.close();
      });
      if (!isLoaded) return;
      this.iFrameEnabled = true;
      LinkAccountProviderPageComponent.setPlaidLinkToken(this.details.custodian.id, linkToken);

      let isPageExited = false;

      const handler = window.Plaid.create({
        key: process.env.REACT_APP_PLAID_PUBLIC_KEY,
        token: linkToken,
        receivedRedirectUri: !persistedToken === false ? window.location.href : null,
        onSuccess: (publicToken, metadata) => {
          console.log("Link - onSuccess");
          console.log(publicToken);
          console.log(metadata);

          const previousProviderName =
            !this.details.selectedProvider === true ? null : this.details.selectedProvider.name;
          const previousProviderId =
            !this.details.selectedProvider === true ? null : this.details.selectedProvider.linkProviderId;

          this.details.selectedProvider = {
            linkProviderId: metadata.institution.institution_id,
            name: metadata.institution.name,
            didUserChangeProvider: previousProviderId && previousProviderId !== metadata.institution.institution_id
          };
          this.sendMessage(linkAccountMessageAction.PROVIDER_CHANGE, { previousProviderName: previousProviderName });
          this.setWindowTitle();
          this.handleProviderPageSuccess({ publicToken: publicToken, metadata: metadata });
        },
        onEvent: (eventName, metadata) => {
          console.log("Link - onEvent");
          console.log(eventName);
          console.log(metadata);

          if (eventName === "OPEN_OAUTH") {
            this.iFrameEnabled = false;
            this.shouldWarnUserOnWindowClose = false;
          }
        },
        onExit: (error, metadata) => {
          // This is to avoid side effects of plaid page calling onExit multiple times
          if (isPageExited === true) {
            return;
          }
          isPageExited = true;

          console.log("Link - onExit");
          console.log(error);
          console.log(metadata);

          if (error && error.error_code === "INVALID_CREDENTIALS") {
            this.props.utilityStatus({
              errorCode: error.error_code,
              errorMessage: error.error_message,
              metaData: metadata,
              ...this.details,
              linkType: getAccountLinkingService(this.details.linkingService)
            });
          }

          if (error && error.error_code !== "INVALID_CREDENTIALS") {
            const previousProviderId =
              !this.details.selectedProvider === true ? null : this.details.selectedProvider.linkProviderId;

            this.details.selectedProvider = {
              linkProviderId: metadata.institution.institution_id,
              name: metadata.institution.name,
              didUserChangeProvider: previousProviderId && previousProviderId !== metadata.institution.institution_id
            };
            this.handleProviderPageFailure({
              errorCode: error.error_code,
              errorMessage: error.error_message,
              metaData: metadata,
              supressStatusReporting: error.error_code === "INSUFFICIENT_CREDENTIALS"
            });
          } else if (metadata && metadata.status === "institution_not_found") {
            // MM - commented this as this was causing issue with selecting plan as we were not able to get the provider id
            // if (this.details.selectedProvider) {
            //   this.details.selectedProvider.linkProviderId = null;
            // }

            this.handleProviderPageFailure({
              errorCode: metadata.status,
              errorMessage: metadata.status,
              metaData: metadata
            });
          } else {
            this.handleProviderPageExit();
          }
        }
      });

      handler.open();
    };

    if (!persistedToken === false) {
      openPlaid(persistedToken);
      return;
    }

    this.setState({ isPending: true, error: null });

    var linkDetails = null;
    if (isLinkingFlow) {
      linkDetails = {
        category: this.details.category,
        countryCode: countryCode,
        test: 0
      };

      if (!this.getCurrentProviderId() === false) {
        linkDetails.providerId = this.getCurrentProviderId();
      }
    } else {
      const linkedCustodianId = this.details.custodian.parentId || this.details.custodian.id;
      linkDetails = {
        custodianId: linkedCustodianId,
        test: 0
      };
    }

    this.props.getLinkTokenForCustodian(
      this.details.linkingService,
      linkDetails,
      linkToken => {
        this.setState({ ...this.state, isPending: false });
        openPlaid(linkToken);
      },
      apiError => {
        this.setState({ ...this.state, isPending: false, error: apiError });
      }
    );
  }

  openZaboProviderPage(providerId) {
    if (!window.kuberaZaboInstance === true) {
      this.setState({
        isPending: true,
        error: null,
        currentScreen: linkAccountScreen.PROVIDER_PAGE
      });
    } else {
      this.setState({
        currentScreen: linkAccountScreen.PROVIDER_PAGE
      });
    }

    const connectZabo = () => {
      this.isZaboCallbackReceived = false;
      zaboInstance
        .connect({ provider: providerId })
        .onConnection(account => {
          console.log("Link - onConnection");
          console.log(account);

          if (this.isZaboCallbackReceived) {
            return;
          }
          this.isZaboCallbackReceived = true;
          this.handleProviderPageSuccess(account);
        })
        .onError(error => {
          console.log("Link - onError");
          console.log(error);

          if (this.isZaboCallbackReceived) {
            return;
          }
          this.isZaboCallbackReceived = true;

          if (!error.error_type === true && !error.message === true) {
            this.isZaboCallbackReceived = false;
            return;
          } else if (error.error_type === 400) {
            this.handleProviderPageExit();
          } else {
            const data = {
              errorCode: error.error_type ? error.error_type : "unknown",
              errorMessage: error.message ? error.message : "unknown",
              requestId: error.request_id ? error.request_id : "unknown"
            };
            this.handleProviderPageFailure(data);
          }
        });
    };

    var zaboInstance = window.kuberaZaboInstance;
    if (!zaboInstance === true) {
      window.Zabo.init({
        clientId: process.env.REACT_APP_ZABO_CLIENT_ID,
        env: process.env.REACT_APP_ZABO_ENV
      })
        .then(zabo => {
          this.setState({ isPending: false, currentScreen: linkAccountScreen.PROVIDER_PAGE });

          zaboInstance = zabo;
          connectZabo();
        })
        .catch(error => {
          this.setState({ isPending: false, currentScreen: linkAccountScreen.PROVIDER_PAGE });

          const data = {
            errorCode: "INIT_FAILED",
            errorMessage: "Initializing failure"
          };
          this.handleProviderPageFailure(data);
        });

      console.log("Link - Initializing Zabo");
    } else {
      connectZabo();
    }
  }

  openSaltEdgeProviderPage(linkProviderId, linkProviderAccountId) {
    if (this.isMainWindowOpen() === false) {
      this.props.unmarkCustodianAsLinking(
        this.details.portfolioId,
        this.details.custodian,
        this.getCurrentProviderName(),
        this.details.category
      );
    }

    this.connectionId = null;
    this.setState({ ...this.state, isPending: true, currentScreen: null, error: null, hideDialog: true });

    var urlRequest = this.props.getLinkConnectionUrl;
    if (!linkProviderAccountId === false) {
      if (this.details.mode === linkAccountMode.EDIT) {
        urlRequest = this.props.getReconnectConnectionUrl;
      } else if (this.details.mode === linkAccountMode.REFRESH) {
        urlRequest = this.props.getRefreshConnectionUrl;
      }
    }
    const param = !linkProviderAccountId === true ? linkProviderId : linkProviderAccountId;

    urlRequest(
      this.details.linkingService,
      param,
      connectionData => {
        const connectionUrl = connectionData.connectUrl;
        this.iFrameEnabled = false;

        if (this.isMainWindowOpen() === false) {
          this.props.unmarkCustodianAsLinking(
            this.details.portfolioId,
            this.details.custodian,
            this.getCurrentProviderName(),
            this.details.category
          );
        }

        this.shouldWarnUserOnWindowClose = false;
        window.location = connectionUrl;
      },
      apiError => {
        if (apiError.errorCode === 1044) {
          // provider not supported error
          this.handleProviderPageFailure(apiError);
          return;
        } else if (apiError.errorCode === apiErrorCodes.REFRESH_NOT_AVAILABLE) {
          this.details.mode = linkAccountMode.EDIT;
          this.openSaltEdgeProviderPage(linkProviderId, linkProviderAccountId);
          return;
        } else if (apiError.errorCode === apiErrorCodes.CONNECTION_NOT_AVAILABLE) {
          this.isRelinkFlow = true;
          this.details.mode = linkAccountMode.LINK;
          this.details.selectedProvider = {
            linkProviderId: this.details.custodian.linkProviderId,
            name: this.details.custodian.linkProviderName
          };
          this.openSaltEdgeProviderPage(linkProviderId, null);
          return;
        }
        this.setState({ isPending: false, error: apiError, hideDialog: false });
      }
    );
  }

  openFinicityProviderPage(linkProviderId, linkProviderAccountId) {
    var invalidCredentialsRetryCount = 0;

    if (this.isMainWindowOpen() === false) {
      this.props.unmarkCustodianAsLinking(
        this.details.portfolioId,
        this.details.custodian,
        this.getCurrentProviderName(),
        this.details.category
      );
    }

    this.setState({ ...this.state, isPending: true, currentScreen: null, error: null, hideDialog: true });

    var urlRequest = this.props.getLinkConnectionUrl;
    if (!linkProviderAccountId === false) {
      if (this.details.mode === linkAccountMode.EDIT) {
        urlRequest = this.props.getReconnectConnectionUrl;
      } else if (this.details.mode === linkAccountMode.REFRESH) {
        urlRequest = this.props.getRefreshConnectionUrl;
      }
    }
    const param = !linkProviderAccountId === true ? linkProviderId : linkProviderAccountId;

    urlRequest(
      this.details.linkingService,
      param,
      connectionData => {
        this.iFrameEnabled = true;
        const connectionUrl = connectionData.connectUrl;

        if (this.isMainWindowOpen() === false) {
          this.props.unmarkCustodianAsLinking(
            this.details.portfolioId,
            this.details.custodian,
            this.getCurrentProviderName(),
            this.details.category
          );
        }

        this.setState(
          {
            ...this.state,
            isPending: false,
            currentScreen: linkAccountScreen.PROVIDER_PAGE,
            error: null,
            hideDialog: false
          },
          async () => {
            const isLoaded = await window.finicityLoadPromise.catch(err => {
              window.confirm(err);
              window.close();
            });
            if (!isLoaded) return;
            window.finicityConnect.launch(connectionUrl, {
              selector: `#${providerPageContainerId}`,
              overlay: "rgba(255, 255, 255, 0)",
              success: event => {
                console.log("Link - success", event);
                this.handleProviderPageSuccess({});
              },
              cancel: event => {
                console.log("Link - cancel", event);
                this.handleProviderPageExit();
              },
              error: error => {
                console.log("Link - error", error);
                this.handleProviderPageFailure(error);
              },
              loaded: () => {
                console.log("Link - loaded");
              },
              route: event => {
                console.log("Link - route", event);
              },
              user: event => {
                console.log("Link - user", event);

                if (event.data && event.data.action === "DisplayAlert") {
                  const errorCode = event.data.errorCode;

                  // 103 is invalid credentials
                  // Supress status reporting for errors codes where user has
                  // not provided consent or cancelled a request on provider's page
                  if (errorCode === 103 && invalidCredentialsRetryCount < 1) {
                    invalidCredentialsRetryCount++;
                  } else {
                    this.handleProviderPageFailure({
                      errorCode: errorCode,
                      errorMessage: event.data.message,
                      supressStatusReporting: !errorCode === false && [2017, 2003].includes(errorCode) === true
                    });
                  }
                } else if (event.data && event.data.action === "End") {
                  if (this.details.mode !== linkAccountMode.LINK && event.data.type && event.data.type === "fix") {
                    this.handleProviderPageSuccess({});
                  }
                }
              }
            });
          }
        );
      },
      apiError => {
        if (apiError.errorCode === apiErrorCodes.REFRESH_NOT_AVAILABLE) {
          this.details.mode = linkAccountMode.EDIT;
          this.openFinicityProviderPage(linkProviderId, linkProviderAccountId);
          return;
        } else if (apiError.errorCode === apiErrorCodes.CONNECTION_NOT_AVAILABLE) {
          this.isRelinkFlow = true;
          this.details.mode = linkAccountMode.LINK;
          this.details.selectedProvider = {
            linkProviderId: this.details.custodian.linkProviderId,
            name: this.details.custodian.linkProviderName
          };
          this.openFinicityProviderPage(linkProviderId, null);
          return;
        }

        this.setState({ isPending: false, error: apiError, hideDialog: false });
      }
    );
  }

  openSnaptradeProviderPage(linkProviderId, linkProviderAccountId) {
    if (this.isMainWindowOpen() === false) {
      this.props.unmarkCustodianAsLinking(
        this.details.portfolioId,
        this.details.custodian,
        this.getCurrentProviderName(),
        this.details.category
      );
    }

    this.setState({ ...this.state, isPending: true, currentScreen: null, error: null, hideDialog: true });

    var urlRequest = this.props.getLinkConnectionUrl;
    if (!linkProviderAccountId === false) {
      if (this.details.mode === linkAccountMode.EDIT) {
        urlRequest = this.props.getReconnectConnectionUrl;
      } else if (this.details.mode === linkAccountMode.REFRESH) {
        urlRequest = this.props.getRefreshConnectionUrl;
      }
    }
    const param = !linkProviderAccountId === true ? linkProviderId : linkProviderAccountId;

    urlRequest(
      this.details.linkingService,
      param,
      connectionData => {
        const connectionUrl = connectionData.connectUrl;

        if (this.isMainWindowOpen() === false) {
          this.props.unmarkCustodianAsLinking(
            this.details.portfolioId,
            this.details.custodian,
            this.getCurrentProviderName(),
            this.details.category
          );
        }

        this.setState(
          {
            ...this.state,
            isPending: true,
            currentScreen: linkAccountScreen.PROVIDER_PAGE,
            error: null,
            hideDialog: false
          },
          () => {
            if (!this.windowMessageListener === false) {
              window.removeEventListener("message", this.windowMessageListener);
            }

            const iFrame = document.getElementById(providerPageContainerId);
            if (!iFrame === false) {
              iFrame.src = connectionUrl;
              iFrame.onload = () => {
                this.setState({ ...this.state, isPending: false });
              };

              this.windowMessageListener = event => {
                if (!event.data === true || !event.origin === true || event.origin !== "https://app.snaptrade.com") {
                  return;
                }

                console.log("Link - message");
                console.log(event);

                if (event.data === "CLOSE_MODAL" || event.data === "CLOSED" || event.data === "ERROR:400") {
                  console.log("Link - CLOSED");

                  window.removeEventListener("message", this.windowMessageListener);
                  this.handleProviderPageExit();
                  return;
                }

                if (!event.data === false && typeof event.data === "string" && event.data.startsWith("ERROR")) {
                  console.log("Link - FAILURE");

                  window.removeEventListener("message", this.windowMessageListener);
                  this.handleProviderPageFailure({
                    errorCode: event.data,
                    errorMessage: event.data
                  });
                }

                if (!event.data === false && typeof event.data === "string" && event.data.startsWith("SUCCESS:")) {
                  console.log("Link - SUCCESS");

                  window.removeEventListener("message", this.windowMessageListener);
                  this.handleProviderPageSuccess({ providerAccountId: event.data.replace("SUCCESS:", "") });
                }
              };
              window.addEventListener("message", this.windowMessageListener);
            }
          }
        );
      },
      apiError => {
        if (apiError.errorCode === apiErrorCodes.REFRESH_NOT_AVAILABLE) {
          this.details.mode = linkAccountMode.EDIT;
          this.openSnaptradeProviderPage(linkProviderId, linkProviderAccountId);
          return;
        } else if (apiError.errorCode === apiErrorCodes.CONNECTION_NOT_AVAILABLE) {
          this.isRelinkFlow = true;
          this.details.mode = linkAccountMode.LINK;
          this.details.selectedProvider = {
            linkProviderId: this.details.custodian.linkProviderId,
            name: this.details.custodian.linkProviderName
          };
          this.openSnaptradeProviderPage(linkProviderId, null);
          return;
        }

        this.setState({ isPending: false, error: apiError, hideDialog: false });
      }
    );
  }

  getMXConnectionStatusErrorMessage(errorCode) {
    switch (errorCode) {
      case 2:
        return "The credentials provided for the member were invalid.";
      case 4:
        return "An MFA challenge was answered incorrectly.";
      case 5:
        return "The financial institution is preventing authentication. The end user must contact the financial institution.";
      case 11:
        return "The connection to this financial institution is no longer available.";
      case 12:
        return "The end user, MX, the client, or a partner has marked the member as closed.";
      case 14:
        return "Aggregation failed without being connected.";
      case 19:
        return "The MFA answer was not provided within the time allotted by the financial institution.";
      default:
        return "Unknown error";
    }
  }

  openMXProviderPage(linkProviderId, linkProviderAccountId) {
    var invalidCredentialsRetryCount = 0;
    if (this.isMainWindowOpen() === false) {
      this.props.unmarkCustodianAsLinking(
        this.details.portfolioId,
        this.details.custodian,
        this.getCurrentProviderName(),
        this.details.category
      );
    }

    this.setState({ ...this.state, isPending: true, currentScreen: null, error: null, hideDialog: true });

    var urlRequest = this.props.getLinkConnectionUrl;
    if (!linkProviderAccountId === false) {
      if (this.details.mode === linkAccountMode.EDIT) {
        urlRequest = this.props.getReconnectConnectionUrl;
      } else if (this.details.mode === linkAccountMode.REFRESH) {
        urlRequest = this.props.getRefreshConnectionUrl;
      }
    }
    const param = !linkProviderAccountId === true ? linkProviderId : linkProviderAccountId;

    urlRequest(
      this.details.linkingService,
      param,
      connectionData => {
        const connectionUrl = connectionData.connectUrl;

        if (this.isMainWindowOpen() === false) {
          this.props.unmarkCustodianAsLinking(
            this.details.portfolioId,
            this.details.custodian,
            this.getCurrentProviderName(),
            this.details.category
          );
        }

        this.setState(
          {
            ...this.state,
            isPending: true,
            currentScreen: linkAccountScreen.PROVIDER_PAGE,
            error: null,
            hideDialog: false
          },
          () => {
            if (!this.windowMessageListener === false) {
              window.removeEventListener("message", this.windowMessageListener);
            }

            const iFrame = document.getElementById(providerPageContainerId);
            if (!iFrame === false) {
              iFrame.src = connectionUrl;
              iFrame.onload = () => {
                this.setState({ ...this.state, isPending: false });
              };

              this.windowMessageListener = event => {
                if (
                  !event.data === true ||
                  !event.origin === true ||
                  (event.origin !== "https://int-widgets.moneydesktop.com" &&
                    event.origin !== "https://widgets.moneydesktop.com")
                ) {
                  return;
                }

                console.log("Link - message");
                console.log(JSON.stringify(event.data));

                if (event.data === "CLOSED" || event.data === "ERROR:400") {
                  console.log("Link - CLOSED");

                  window.removeEventListener("message", this.windowMessageListener);
                  this.handleProviderPageExit();
                  return;
                }

                if (
                  !event.data === false &&
                  typeof event.data === "object" &&
                  event.data.hasOwnProperty("type") &&
                  event.data.type === "mx/connect/memberConnected"
                ) {
                  console.log("Link - SUCCESS");
                  const memberId = event.data.metadata.member_guid;

                  window.removeEventListener("message", this.windowMessageListener);
                  this.handleProviderPageSuccess({ providerAccountId: memberId });
                } else if (
                  !event.data === false &&
                  typeof event.data === "object" &&
                  event.data.hasOwnProperty("type") &&
                  event.data.type === "mx/connect/backToSearch"
                ) {
                  console.log("Link - backToSearch");

                  window.removeEventListener("message", this.windowMessageListener);
                  this.sendExitMessage(true);
                  this.closeWindow();
                } else if (
                  !event.data === false &&
                  typeof event.data === "object" &&
                  event.data.hasOwnProperty("type") &&
                  event.data.type === "mx/connect/oauthError"
                ) {
                  console.log("Link - FAILURE - Oauth error" + event.data.metadata.error_reason);

                  window.removeEventListener("message", this.windowMessageListener);
                  this.handleProviderPageFailure({
                    errorMessage: "Oauth error : " + event.data.metadata.error_reason
                  });
                } else if (
                  !event.data === false &&
                  typeof event.data === "object" &&
                  event.data.hasOwnProperty("type") &&
                  event.data.type === "mx/connect/memberError"
                ) {
                  const errorCode = event.data.metadata.member?.connection_status;
                  const retriable_error_codes = [2, 4];

                  // for retriable errors, failure after 2 invalid credentials
                  if (retriable_error_codes.includes(errorCode) === true) {
                    if (invalidCredentialsRetryCount < 2) {
                      invalidCredentialsRetryCount++;
                    } else {
                      console.log(
                        `Link - FAILURE - Breached max invalid credentials retry count - ${invalidCredentialsRetryCount}, error code - ${errorCode}`
                      );
                      const errorMessage = this.getMXConnectionStatusErrorMessage(errorCode);

                      window.removeEventListener("message", this.windowMessageListener);
                      this.handleProviderPageFailure({
                        errorCode,
                        errorMessage
                      });
                    }
                  } else {
                    console.log("Link - FAILURE - Connection status error - " + errorCode);
                    const errorMessage = this.getMXConnectionStatusErrorMessage(errorCode);

                    window.removeEventListener("message", this.windowMessageListener);
                    this.handleProviderPageFailure({
                      errorCode,
                      errorMessage
                    });
                  }
                } else if (
                  !event.data === false &&
                  typeof event.data === "object" &&
                  event.data.hasOwnProperty("type") &&
                  event.data.type === "mx/connect/createMemberError"
                ) {
                  console.log("Link - FAILURE - createMemberError");

                  window.removeEventListener("message", this.windowMessageListener);
                  this.handleProviderPageFailure({
                    errorMessage: "Member failed to get created when credentials were entered."
                  });
                }
                // the below part will be used when we have to handle the case when user clicks on "Revive" button and actually connection has no issues
                // example to test revive
                // we should test actual revive
                else if (
                  linkProviderAccountId &&
                  !event.data === false &&
                  typeof event.data === "object" &&
                  event.data.hasOwnProperty("type") &&
                  event.data.type === "mx/connect/loaded" &&
                  event.data.hasOwnProperty("metadata") &&
                  typeof event.data.metadata === "object" &&
                  event.data.metadata.hasOwnProperty("initial_step") &&
                  event.data.metadata.initial_step === "connected"
                ) {
                  console.log("Link - SUCCESS(Revive - Already Connected)");

                  window.removeEventListener("message", this.windowMessageListener);
                  this.handleProviderPageSuccess({ providerAccountId: linkProviderAccountId });
                }
              };
              window.addEventListener("message", this.windowMessageListener);
            }
          }
        );
      },
      apiError => {
        if (apiError.errorCode === apiErrorCodes.REFRESH_NOT_AVAILABLE) {
          this.details.mode = linkAccountMode.EDIT;
          this.openMXProviderPage(linkProviderId, linkProviderAccountId);
          return;
        } else if (apiError.errorCode === apiErrorCodes.CONNECTION_NOT_AVAILABLE) {
          this.isRelinkFlow = true;
          this.details.mode = linkAccountMode.LINK;
          this.details.selectedProvider = {
            linkProviderId: this.details.custodian.linkProviderId,
            name: this.details.custodian.linkProviderName
          };
          this.openMXProviderPage(linkProviderId, null);
          return;
        }

        this.setState({ isPending: false, error: apiError, hideDialog: false });
      }
    );
  }

  openFlinksProviderPage(linkProviderId) {
    var invalidCredentialsRetryCount = 0;

    if (this.isMainWindowOpen() === false) {
      this.props.unmarkCustodianAsLinking(
        this.details.portfolioId,
        this.details.custodian,
        this.getCurrentProviderName(),
        this.details.category
      );
    }
    this.setState(
      { ...this.state, isPending: true, currentScreen: linkAccountScreen.PROVIDER_PAGE, error: null },
      () => {
        this.iFrameEnabled = true;
        this.shouldWarnUserOnWindowClose = false;
        const connectionUrl = this.getFlinksConnectionUrl(linkProviderId);
        const iFrame = document.getElementById(providerPageContainerId);
        if (!iFrame === false) {
          iFrame.src = connectionUrl;
          const flinksEventListener = event => {
            console.log(event);

            if (
              !event.data === true ||
              !event.origin === true ||
              (event.origin !== process.env.REACT_APP_FLINKS_BANKING_URL &&
                event.origin !== process.env.REACT_APP_FLINKS_INVESTMENT_URL)
            ) {
              return;
            }

            const data = event.data;
            var invalidCredentials = data.flinksCode === "INVALID_LOGIN";

            if (invalidCredentials === true) {
              invalidCredentialsRetryCount++;

              if (invalidCredentialsRetryCount >= 2) {
                this.handleProviderPageFailure({
                  errorCode: data.flinksCode,
                  errorMessage: data.step
                });
              }
              return;
            }

            if (data.step === "REDIRECT") {
              this.handleProviderPageSuccess({ accountId: data.loginId });
            }
            //show loader untill flinks connect app is loaded
            else if (data.step === "APP_MOUNTED") {
              this.setState({ ...this.state, isPending: false });
            } else if (
              data.step === "DISABLED_INSTITUTION" ||
              data.step === "INVALID_INSTITUTION" ||
              data.step === "INSTITUTION_NOT_AVAILABLE" ||
              (data.step === "RETRY_COUNT" && data.count >= 3)
            ) {
              this.handleProviderPageFailure({
                errorCode: data.step,
                errorMessage: data.step,
                invalidCredentials: invalidCredentials
              });
            }
          };
          window.addEventListener("message", flinksEventListener);
        }
      }
    );
  }

  openAkahuProviderPage(linkProviderId) {
    if (this.isMainWindowOpen() === false) {
      this.props.unmarkCustodianAsLinking(
        this.details.portfolioId,
        this.details.custodian,
        this.getCurrentProviderName(),
        this.details.category
      );
    }

    this.setState({
      currentScreen: linkAccountScreen.PROVIDER_PAGE,
      error: null
    });

    this.iFrameEnabled = false;
    this.shouldWarnUserOnWindowClose = false;

    var url = "https://oauth.akahu.io";
    const redirectUrl = `${process.env.REACT_APP_COGNITO_BASE_URL}/redirect_callback`;
    url += "?response_type=code&";
    url += `client_id=${encodeURIComponent(process.env.REACT_APP_AKAHU_CLIENT_ID)}&`;
    url += `email=${encodeURIComponent(this.props.userEmail)}&`;
    url += `connection=${encodeURIComponent(linkProviderId)}&`;
    url += `redirect_uri=${encodeURIComponent(redirectUrl)}&`;
    url += "scope=ENDURING_CONSENT&";

    window.location = url;
  }

  async openLeanProviderPage(linkProviderId, linkProviderAccountId) {
    const isLoaded = await LinkAccountProviderPageComponent.loadLeanScript().catch(err => {
      window.confirm(err);
      window.close();
    });
    if (!isLoaded) return;

    this.setState({ isPending: true, error: null });

    const callbackHandler = response => {
      console.log(response);

      setTimeout(() => {
        if (response.status === "CANCELLED") {
          this.handleProviderPageExit();
        } else if (response.status === "SUCCESS") {
          this.handleProviderPageSuccess({});
        } else if (response.status === "ERROR") {
          this.handleProviderPageFailure(response);
        }
      }, 200);
    };

    if (this.details.mode === linkAccountMode.LINK) {
      this.props.getConnectionInfo(
        this.details.linkingService,
        linkProviderId,
        connectionInfo => {
          this.setState({ isPending: false, currentScreen: linkAccountScreen.PROVIDER_PAGE, error: null });

          const linkParams = {
            access_token: connectionInfo.accessToken,
            app_token: process.env.REACT_APP_LEAN_CLIENT_ID,
            permissions: ["accounts", "balance"],
            customer_id: connectionInfo.customerId,
            bank_identifier: linkProviderId,
            callback: callbackHandler
          };

          if (process.env.REACT_APP_ENV !== "prod") {
            linkParams.sandbox = "true";
          }
          window.Lean.connect(linkParams);
        },
        apiError => {
          this.setState({ isPending: false, error: apiError });
        }
      );
    } else {
      this.props.getReConnectionInfo(
        this.details.linkingService,
        linkProviderAccountId,
        connectionInfo => {
          if (!connectionInfo.reconnectId === true) {
            this.handleProviderPageFailure({});
            return;
          }

          this.setState({ isPending: false, currentScreen: linkAccountScreen.PROVIDER_PAGE, error: null });

          const linkParams = {
            app_token: process.env.REACT_APP_LEAN_CLIENT_ID,
            reconnect_id: connectionInfo.reconnectId,
            callback: callbackHandler
          };

          if (process.env.REACT_APP_ENV !== "prod") {
            linkParams.sandbox = "true";
          }

          console.log("window.Lean.reconnect", linkParams);

          window.Lean.reconnect(linkParams);
        },
        apiError => {
          this.setState({ isPending: false, error: apiError });
        }
      );
    }
  }

  getFlinksConnectionUrl(linkProviderId) {
    var url = process.env.REACT_APP_FLINKS_BANKING_URL;
    var productType = "banking";
    if (this.details.linkingService === accountLinkingService.FLINKS_INVESTMENT) {
      url = process.env.REACT_APP_FLINKS_INVESTMENT_URL;
      productType = "investment";
    }
    url += "/v2";
    url += `/Credential/${linkProviderId}?`;
    url += `productType=${productType}&`;
    url += `consentEnable=true&`;
    url += `features=TransactionHistory,AccountNumber&`;
    url += `headerEnable=false&`;
    url += `accountSelectorEnable=false&`;
    url += `accountSelectorMultiple=true&`;
    url += `showAllOperationsAccounts=true&`;
    url += `fetchAllAccounts=true&`;
    url += `institutionFilterEnable=false&`;
    url += `closeEnable=false&`;
    url += `scheduleRefresh=true&`;
    url += `institutionsLocation=CA&`;
    url += `enhancedMFA=true&`;

    if (process.env.REACT_APP_ENV !== "prod") {
      url += "demo=true&";
      url += `customerName=Kubera-Staging&`;
    } else {
      url += `customerName=Kubera&`;
    }

    return url;
  }

  openZerionProviderPage(linkProviderId) {
    const showZerionScreen = () => {
      this.setState({
        ...this.state,
        isPending: false,
        currentScreen: linkAccountScreen.ZERION_PAGE,
        error: null,
        hideDialog: false
      });
    };
    showZerionScreen();
  }

  openInHouseCryptoOAuthProviderPage(linkProviderId) {
    if (this.isMainWindowOpen() === false) {
      this.props.unmarkCustodianAsLinking(
        this.details.portfolioId,
        this.details.custodian,
        this.getCurrentProviderName(),
        this.details.category
      );
    }

    this.setState({ ...this.state, isPending: true, currentScreen: null, error: null, hideDialog: true });

    const urlRequest = this.props.getLinkConnectionUrl;
    const param = linkProviderId;

    urlRequest(
      this.details.linkingService,
      param,
      connectionData => {
        const connectionUrl = connectionData.connectUrl;

        if (this.isMainWindowOpen() === false) {
          this.props.unmarkCustodianAsLinking(
            this.details.portfolioId,
            this.details.custodian,
            this.getCurrentProviderName(),
            this.details.category
          );
        }

        const redirectToProviderPage = () => {
          this.shouldWarnUserOnWindowClose = false;
          window.location = connectionUrl;
        };

        if (isZaboMigrationCandidate(this.details.custodian)) {
          this.fetchProviderDetails(accountLinkingService.IN_HOUSE_CRYPTO_OAUTH, linkProviderId, providerDetails => {
            this.updateSelectedProviderDetails(providerDetails);
            LinkAccountProviderPageComponent.setDetails(this.details.custodian.id, this.details);
            redirectToProviderPage();
          });
        } else {
          redirectToProviderPage();
        }
      },
      apiError => {
        this.setState({ isPending: false, error: apiError, hideDialog: false });
      }
    );
  }

  openInHouseCryptoApiProviderPage(linkProviderId) {
    const showCryptoApiScreen = () => {
      this.setState({
        ...this.state,
        isPending: false,
        currentScreen: linkAccountScreen.CRYPTO_API_PAGE,
        error: null,
        hideDialog: false
      });
    };

    showCryptoApiScreen();
  }

  openHardwareWalletProviderPage(linkProviderId) {
    const showWalletScreen = () => {
      this.setState({
        isPending: false,
        currentScreen: linkAccountScreen.HARDWARE_WALLET,
        error: null
      });
    };

    showWalletScreen();
  }

  handleProviderPageExit() {
    if (this.isMainWindowOpen() === true) {
      this.sendExitMessage();
      this.closeWindow();
    } else {
      this.props.unmarkCustodianAsLinking(
        this.details.portfolioId,
        this.details.custodian,
        this.getCurrentProviderName(),
        this.details.category
      );
      this.goToDashboard();
    }
  }

  sendExitMessage(openSearch = false) {
    this.sendMessage(linkAccountMessageAction.EXIT, {
      linkingCompleted: this.isLinkingCompleted,
      linkingFailed: this.state.currentScreen === linkAccountScreen.LINK_FAILURE,
      accountsFetched: this.state.linkedAccounts !== null,
      connectionId: this.connectionId,
      noAccountsAvailableToLink:
        this.state.noAccountsAvailableToLink ||
        (this.isLinkingCompleted && !this.selectedAccounts === false && this.selectedAccounts.length === 0),
      openSearch
    });
  }

  handleProviderPageFailure(linkErrorData) {
    if (this.details.mode === linkAccountMode.LINK) {
      this.sendMessage(linkAccountMessageAction.PROVIDER_PAGE_FAILURE, linkErrorData);
    }

    if (!linkErrorData.invalidCredentials === true) {
      this.setState({
        ...this.state,
        isPending: false,
        currentScreen: linkAccountScreen.LINK_FAILURE,
        linkErrorData: linkErrorData,
        hideDialog: false
      });

      if (!linkErrorData.supressStatusReporting === true) {
        this.handleLinkingProviderStatus(1);
      }
    }

    this.props.utilityStatus({
      ...linkErrorData,
      ...this.details,
      linkType: getAccountLinkingService(this.details.linkingService)
    });
  }

  handleProviderPageSuccess(data) {
    if (
      this.details.mode === linkAccountMode.LINK ||
      this.details.mode === linkAccountMode.RECONNECT ||
      this.details.mode === linkAccountMode.SELECT_ACCOUNTS
    ) {
      this.handleProviderPageSuccessData(data);
    } else if (this.details.mode === linkAccountMode.EDIT || this.details.mode === linkAccountMode.REFRESH) {
      // For Zabo refresh, edit and link are same
      if (this.details.linkingService === accountLinkingService.ZABO) {
        this.details.selectedProvider = {
          linkProviderId: this.details.custodian.linkProviderId,
          name: this.details.custodian.linkProviderName
        };
        this.handleProviderPageSuccessData(data);
        return;
      } else if (isZaboMigrationCandidate(this.details.custodian)) {
        this.handleProviderPageSuccessData(data);
        return;
      }

      const refreshCustodian = () => {
        if (this.isMainWindowOpen() === true) {
          this.sendMessage(linkAccountMessageAction.REFRESH_CUSTODIAN);
          this.closeWindow();
        } else {
          this.setState({ ...this.state, isPending: true, error: null, currentScreen: null });

          const onCompletion = () => {
            this.setState({ ...this.state, isPending: false, error: null, currentScreen: null });
            this.goToDashboard();
          };

          this.props.reauthCustodian(
            this.details.custodian.id,
            () => {
              onCompletion();
            },
            () => {
              onCompletion();
            }
          );
        }
      };

      var updatePublicTokenParams = null;

      if (this.details.linkingService === accountLinkingService.PLAID) {
        updatePublicTokenParams = {
          publicToken: data.publicToken,
          linkSessionId: data.metadata.link_session_id
        };
      } else if (this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_OAUTH) {
        updatePublicTokenParams = {
          publicToken: data.code
        };
      } else if (this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_API) {
        updatePublicTokenParams = {
          metaData: JSON.stringify(data.metaData)
        };
      }

      const currentScreen = this.state.currentScreen;
      if (!updatePublicTokenParams === false) {
        this.setState({ ...this.state, isPending: true, error: null, currentScreen: null });

        this.props.setPublicTokenForCustodian(
          this.details.linkingService,
          this.details.custodian.parentId || this.details.custodian.id,
          updatePublicTokenParams,
          () => {
            refreshCustodian();
          },
          apiError => {
            var nextScreen =
              this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_API ? currentScreen : null;
            this.setState({ ...this.state, isPending: false, error: apiError, currentScreen: nextScreen });
          }
        );
      } else {
        refreshCustodian();
      }
    }
  }

  handleProviderPageSuccessData(data, existingProviderAccountId = null) {
    var params = null;
    var hasSubAccounts = true;

    if (this.details.linkingService === accountLinkingService.YODLEE) {
      params = {
        portfolioId: this.details.portfolioId,
        providerAccountId: existingProviderAccountId ? `${existingProviderAccountId}` : `${data.providerAccountId}`,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name
      };
    } else if (this.details.linkingService === accountLinkingService.PLAID) {
      params = {
        portfolioId: this.details.portfolioId,
        providerId: data.metadata.institution.institution_id,
        providerName: data.metadata.institution.name,
        publicToken: data.publicToken,
        linkSessionId: data.metadata.link_session_id,
        metaData: JSON.stringify(data.metadata)
      };

      hasSubAccounts = data.metadata.accounts.length > 1;
    } else if (this.details.linkingService === accountLinkingService.ZABO) {
      params = {
        portfolioId: this.details.portfolioId,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name,
        metaData: JSON.stringify(data)
      };
    } else if (
      this.details.linkingService === accountLinkingService.SALTEDGE ||
      this.details.linkingService === accountLinkingService.SALTEDGE_EU
    ) {
      params = {
        portfolioId: this.details.portfolioId,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name,
        providerAccountId: existingProviderAccountId || data.connectionId
      };
    } else if (this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_OAUTH) {
      params = {
        publicToken: data.code,
        portfolioId: this.details.portfolioId,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name,
        ...(data.accountId ? { providerAccountId: `${data.accountId}` } : null)
      };
    } else if (this.details.linkingService === accountLinkingService.IN_HOUSE_CRYPTO_API) {
      params = {
        metaData: JSON.stringify(data.metaData),
        portfolioId: this.details.portfolioId,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name,
        ...(data.accountId ? { providerAccountId: `${data.accountId}` } : null)
      };
    } else if (this.details.linkingService === accountLinkingService.ZERION) {
      params = {
        portfolioId: this.details.portfolioId,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name,
        metaData: JSON.stringify(data.metaData)
      };
    } else if (
      this.details.linkingService === accountLinkingService.FLINKS_BANKING ||
      this.details.linkingService === accountLinkingService.FLINKS_INVESTMENT
    ) {
      params = {
        portfolioId: this.details.portfolioId,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name,
        providerAccountId: existingProviderAccountId || data.accountId
      };
    } else if (this.details.linkingService === accountLinkingService.AKAHU) {
      params = {
        portfolioId: this.details.portfolioId,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name,
        publicToken: data.code
      };
    } else if (this.details.linkingService === accountLinkingService.LEAN) {
      params = {
        portfolioId: this.details.portfolioId,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name
      };

      if (!existingProviderAccountId === false) {
        params.providerAccountId = `${existingProviderAccountId}`;
      }
    } else if (this.details.linkingService === accountLinkingService.FINICITY) {
      params = {
        portfolioId: this.details.portfolioId,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name
      };

      if (!existingProviderAccountId === false) {
        params.providerAccountId = `${existingProviderAccountId}`;
      }
    } else if (this.details.linkingService === accountLinkingService.SNAPTRADE) {
      params = {
        portfolioId: this.details.portfolioId,
        providerAccountId: existingProviderAccountId ? `${existingProviderAccountId}` : `${data.providerAccountId}`,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name
      };
    } else if (this.details.linkingService === accountLinkingService.MX) {
      params = {
        portfolioId: this.details.portfolioId,
        providerAccountId: existingProviderAccountId ? `${existingProviderAccountId}` : `${data.providerAccountId}`,
        providerId: `${this.details.selectedProvider.linkProviderId}`,
        providerName: this.details.selectedProvider.name
      };
    }

    this.handleLinkingProviderStatus(0);

    const { metaData, ...utilityParams } = params;
    this.props.utilityStatus({
      ...utilityParams,
      requestId: data ? data.request_id : "",
      connectionId: data ? data.connectionId : "",
      linkType: getAccountLinkingService(this.details.linkingService)
    });
    this.getLinkedAccountsList(params, hasSubAccounts);
  }

  handleLinkingProviderStatus(status) {
    if (this.details.mode !== linkAccountMode.LINK) {
      return;
    }

    if (this.details.selectedProvider && this.details.selectedProvider.linkProviderId) {
      const groupId =
        !this.details.selectedProvider.didUserChangeProvider === true &&
        this.details.selectedProviderDetails &&
        this.details.selectedProviderDetails.groupId
          ? this.details.selectedProviderDetails.groupId
          : "-1";

      if (this.isMainWindowOpen() === true) {
        this.sendMessage(linkAccountMessageAction.SET_PROVIDER_STATUS, {
          linkType: this.details.linkingService,
          groupId: groupId,
          providerId: this.details.selectedProvider.linkProviderId,
          status: status
        });
      } else {
        this.props.setLinkingProviderStatus(
          this.details.linkingService,
          groupId,
          this.details.selectedProvider.linkProviderId,
          status
        );
      }
    }
  }

  getLinkedAccountsList(params, hasSubAccounts = true, isExistingConnection = false) {
    const hideCurrentScreen = this.details.linkingService !== accountLinkingService.IN_HOUSE_CRYPTO_API;
    var showAccountList =
      this.details.mode === linkAccountMode.RECONNECT ||
      (this.details.mode === linkAccountMode.SELECT_ACCOUNTS &&
        this.details.linkingService !== accountLinkingService.PLAID) ||
      (this.details.linkingService === accountLinkingService.PLAID
        ? false
        : this.details.selectedProviderDetails && this.details.selectedProviderDetails.showAccountList);
    var showFancyAccountLoader = showAccountList;

    if (isCryptoLinkingService(this.details.linkingService)) {
      showAccountList = false;
    }

    if (this.details.linkingService === accountLinkingService.AKAHU) {
      showFancyAccountLoader = true;
    }

    this.setState({
      isPending: true,
      currentScreen: hideCurrentScreen === true ? null : this.state.currentScreen,
      error: null,
      hideDialog: hasSubAccounts === false
    });

    if (this.details.mode === linkAccountMode.RECONNECT) {
      this.setState({ currentScreen: linkAccountScreen.RECONNECT_ACCOUNT_LOADER });
    }
    if ((hasSubAccounts === true && hideCurrentScreen === true && showAccountList) || showFancyAccountLoader === true) {
      setTimeout(() => {
        if (this.state.isPending) {
          this.setState({
            isPending: true,
            currentScreen: linkAccountScreen.ACCOUNT_LIST,
            error: null
          });
        }
      }, 2000);
    }
    this.props.getLinkedAccounts(
      this.details.linkingService,
      category.ALL,
      params,
      (accounts, newTickers) => {
        if (this.isComponentMounted === false) {
          return;
        }

        const handleAccountListFromProvider = () => {
          if (accounts.length === 0) {
            this.handleProviderPageFailure({
              errorCode: "no_accounts_found",
              errorMessage: "No accounts found"
            });
            return;
          }

          //filter only the accounts which was not already connected. Connected accounts will have a linkedCustodian id
          accounts = !showAccountList ? accounts.filter(account => !account.linkedCustodianId === true) : accounts;

          this.setState({
            isPending: false,
            linkedAccounts: accounts,
            hideDialog: false
          });
          const linkingAccountsData = {
            accounts: accounts,
            providerId: this.getCurrentProviderId(),
            providerName: this.getCurrentProviderName(),
            linkingService: this.details.linkingService,
            category: this.details.category,
            regionCode: this.details.regionCode,
            portfolioId: this.details.portfolioId
          };
          if (this.isMainWindowOpen() === true) {
            if (!newTickers === false) {
              this.sendMessage(linkAccountMessageAction.ADD_TICKERS, newTickers);
            }
            this.sendMessage(linkAccountMessageAction.PROVIDER_PAGE_SUCCESS, linkingAccountsData);
          } else {
            this.props.custodianLinkingAccountsDataFetched(
              this.details.portfolioId,
              this.details.custodian,
              linkingAccountsData,
              this.getCurrentProviderName(),
              this.details.category
            );
          }

          if (
            this.details.mode === linkAccountMode.RECONNECT ||
            (this.details.mode === linkAccountMode.SELECT_ACCOUNTS &&
              this.details.linkingService === accountLinkingService.PLAID)
          ) {
            this.setState({ currentScreen: linkAccountScreen.RECONNECT_ACCOUNT });
          } else if (accounts.length === 0) {
            this.setState({ noAccountsAvailableToLink: true });
          } else if (accounts.length === 1) {
            if (!accounts[0].linkedCustodianId === false && this.details.mode === linkAccountMode.LINK) {
              this.setState({ noAccountsAvailableToLink: true });
            } else if (
              this.details.mode === linkAccountMode.MANAGE ||
              this.details.mode === linkAccountMode.SELECT_ACCOUNTS
            ) {
              this.setState({ currentScreen: linkAccountScreen.ACCOUNT_LIST });
            } else {
              this.setState({ currentScreen: null });
              this.handleLinkAccount(accounts);
            }
          } else if (showAccountList) {
            this.setState({ currentScreen: linkAccountScreen.ACCOUNT_LIST });
          } else {
            this.handleLinkAccount(accounts);
          }
        };
        // If window opener is null delay by 2 seconds before showing
        // account list as without that on Safari the window opener
        // being set in LinkAccountComponent doesn't work after
        // the user is redirected to kuberas page
        if (!window.opener === true) {
          setTimeout(() => {
            handleAccountListFromProvider();
          }, 5000);
        } else {
          handleAccountListFromProvider();
        }
      },
      apiError => {
        if (apiError.errorCode === apiErrorCodes.UNRECOVERABLE_ERROR) {
          if (isExistingConnection === true) {
            this.linkAccount(this.details.selectedProvider);
          } else {
            const linkErrorData = { errorCode: apiError.errorCode, errorMessage: apiError.errorMessage };
            this.handleProviderPageFailure(linkErrorData);
          }
        } else if (apiError.errorCode === apiErrorCodes.CONNECTION_NOT_AVAILABLE) {
          this.linkAccount(this.details.selectedProvider);
        } else if (apiError.errorCode === apiErrorCodes.INVALID_INPUT && hideCurrentScreen === false) {
          this.setState({
            isPending: false,
            error: apiError
          });
        } else if (apiError.errorCode === apiErrorCodes.CRYPTO_PROVIDER_NOT_SUPPORTED_WITH_AGGREGATOR) {
          const linkErrorData = { errorCode: apiError.errorCode, errorMessage: apiError.errorMessage };
          this.setState({
            isPending: false,
            currentScreen: linkAccountScreen.LINK_FAILURE,
            linkErrorData: linkErrorData
          });
        } else {
          const linkErrorData = { errorCode: apiError.errorCode, errorMessage: apiError.errorMessage };
          this.handleProviderPageFailure(linkErrorData);
        }
      }
    );
  }

  handleProviderPageRetry() {
    this.sendMessage(linkAccountMessageAction.RETRY);
  }

  handleLinkFailureNegativeButtonClick() {
    if (this.details.mode !== linkAccountMode.LINK) {
      //skip the failed provider in provider search screen
      this.aggregatorToBeSkippedOnProviderSearchScreen = getAggregatorName(this.details.linkingService);
      this.linkProviderIdToBeSkippedOnProviderSearchScreen = this.getCurrentProviderId();
      this.details.mode = linkAccountMode.RECONNECT;
      // when reconnect via plaid fails, show provider list
      if (this.details.linkingService === accountLinkingService.PLAID) {
        const nextAvailableAggregator = this.getNextAvailableAggregator();
        if (!nextAvailableAggregator) {
          this.showProviderList();
          this.handleProviderPageRetry();
          this.currentProviderPlanType = terminalPlanType;
          return;
        }
      }
      this.fetchData();
    }
  }

  handleLinkFailurePositiveButtonClick(e) {
    if (this.details.mode !== linkAccountMode.LINK) {
      if (this.details.mode === linkAccountMode.RECONNECT && !this.getNextAvailableAggregator() === false) {
        this.showNextProvider(this.getNextAvailableAggregator());
      } else {
        this.handleProviderPageExit();
      }
      return;
    }

    if (
      this.state.linkErrorData &&
      this.state.linkErrorData.errorCode === apiErrorCodes.CRYPTO_PROVIDER_NOT_SUPPORTED_WITH_AGGREGATOR
    ) {
      // Setting Zabo as linking service so that providers list opens with crypto options
      this.details.linkingService = accountLinkingService.ZABO;
      this.showProviderList();
      return;
    }

    const nextAvailableAggregator = this.getNextAvailableAggregator();
    if (this.currentProviderPlanType !== terminalPlanType && !nextAvailableAggregator === false) {
      this.showNextProvider(nextAvailableAggregator);
      this.handleProviderPageRetry();
    } else {
      this.handleProviderPageExit();
    }
  }

  showNextProvider(nextAvailableAggregator, isFinalTry = false) {
    if (!nextAvailableAggregator === true) {
      return;
    }

    this.details.linkingService = nextAvailableAggregator.linkType;
    this.details.selectedProvider.linkProviderId = nextAvailableAggregator.linkProviderId;
    this.currentProviderPlanType = isFinalTry ? terminalPlanType : nextAvailableAggregator.plan;
    this.linkingServiceHistory.push(this.details.linkingService);
    this.linkAccount(this.details.selectedProvider);
    LinkAccountProviderPageComponent.setDetails(this.details.custodian.id, this.details);
  }

  handleProviderSelection(providerDetails) {
    const aggregatorOptions = providerDetails.aggregatorOptions;
    const aggregator =
      aggregatorOptions.length === 1
        ? aggregatorOptions[0]
        : aggregatorOptions.find(aggregator => aggregator.plan === "a");
    if (!aggregator === true) {
      return;
    }

    const previousProviderName = !this.details.selectedProvider === true ? null : this.details.selectedProvider.name;

    this.details.selectedProviderDetails = providerDetails;
    this.details.selectedProvider = {
      linkProviderId: aggregator.linkProviderId,
      name: providerDetails.name
    };
    this.setWindowTitle();
    this.details.linkingService = aggregator.linkType;
    this.sendMessage(linkAccountMessageAction.PROVIDER_CHANGE, { previousProviderName: previousProviderName });
    if (this.details.mode === linkAccountMode.RECONNECT) {
      this.isProviderSelectedInReconnectFlow = true;
      this.reconnectAccount(providerDetails);
    } else {
      this.linkAccount(this.details.selectedProvider);
    }
  }

  showProviderList() {
    this.setState({
      currentScreen: linkAccountScreen.PROVIDER_LIST,
      isPending: false,
      error: null,
      linkErrorData: null
    });
  }

  isDialogVisible() {
    if (
      this.state.currentScreen === linkAccountScreen.PROVIDER_PAGE &&
      (this.details.linkingService === accountLinkingService.ZABO ||
        this.details.linkingService === accountLinkingService.LEAN ||
        (this.details.linkingService === accountLinkingService.PLAID &&
          this.state.isPending === false &&
          !this.state.error === true))
    ) {
      return false;
    }

    if (
      this.details.linkingService === accountLinkingService.AKAHU &&
      this.state.isPending === false &&
      !this.state.error === true &&
      this.details.linkAccountMode === linkAccountMode.SELECT_ACCOUNTS
    ) {
      return false;
    }

    if (this.state.currentScreen === linkAccountScreen.RECONNECT_ACCOUNT) {
      return false;
    }

    return true;
  }

  shouldShowLoader() {
    if (this.state.currentScreen === linkAccountScreen.ACCOUNT_LIST && this.state.isPending === true) {
      return false;
    }
    if (this.state.currentScreen === linkAccountScreen.ACCOUNT_LIST && !this.state.error === false) {
      return false;
    }
    if (this.state.currentScreen === linkAccountScreen.CRYPTO_API_PAGE) {
      return false;
    }
    return this.state.isPending === true || !this.state.error === false;
  }

  handleLinkAccount(accounts) {
    this.isLinkingCompleted = true;
    this.selectedAccounts = accounts;

    if (
      (accounts || []).length === 0 &&
      this.details.mode !== linkAccountMode.MANAGE &&
      this.details.mode !== linkAccountMode.SELECT_ACCOUNTS
    ) {
      if (this.isMainWindowOpen() === true) {
        this.closeWindow();
      } else {
        this.props.unmarkCustodianAsLinking(
          this.details.portfolioId,
          this.details.custodian,
          this.getCurrentProviderName(),
          this.details.category
        );
        this.goToDashboard();
      }
      return;
    }

    const shouldAutoExpand = false;
    if (this.isMainWindowOpen() === true) {
      window.linkingCompleted = true;
      this.sendMessage(linkAccountMessageAction.ACCOUNTS_SELECTED, {
        accounts: this.state.linkedAccounts,
        selectedAccounts: accounts,
        providerId: this.getCurrentProviderId(),
        providerName: this.getCurrentProviderName(),
        linkingService: this.details.linkingService,
        category: this.details.category,
        regionCode: this.details.regionCode,
        portfolioId: this.details.portfolioId,
        isRelinkFlow: this.isRelinkFlow,
        shouldAutoExpand: shouldAutoExpand
      });

      if (shouldAutoExpand === true) {
        this.shouldWarnUserOnWindowClose = false;
        this.setState({ isPending: true, currentScreen: null });
      } else {
        this.closeWindow();
      }
    } else {
      var linkCompletionCallback = null;

      if (this.details.mode === linkAccountMode.LINK) {
        if (shouldAutoExpand === true) {
          linkCompletionCallback = linkedCustodian => {
            if (linkedCustodian.holdingsCount === 0) {
              this.goToDashboard();
              return;
            }

            this.props.expandHoldingsForCustodian(
              linkedCustodian.id,
              holdingsSectionId => {
                document.scrollToElement(holdingsSectionId);
                this.goToDashboard();
              },
              apiError => {
                this.goToDashboard();
              },
              true,
              this.getCurrentProviderName()
            );
          };
        }
      } else if (!this.details.custodian.parentId === false) {
        this.details.custodian = custodianSelector(store.getState(), this.details.custodian.parentId);

        linkCompletionCallback = linkedCustodian => {
          this.props.reauthCustodian(
            this.details.custodian.id,
            () => {
              this.goToDashboard();
            },
            () => {
              this.goToDashboard();
            }
          );
        };
      }

      if (this.details.mode === linkAccountMode.SELECT_ACCOUNTS) {
        this.props.editAccountsInExistingConnection(
          this.details.portfolioId,
          this.details.category,
          this.details.custodian,
          this.details.linkingService,
          this.details.selectedProvider.linkProviderId,
          this.details.selectedProvider.name,
          accounts
        );
      } else {
        this.props.linkAccountsWithCustodian(
          this.details.portfolioId,
          this.details.category,
          accounts,
          this.state.linkedAccounts,
          this.details.custodian,
          this.details.linkingService,
          this.details.selectedProvider.linkProviderId,
          this.details.selectedProvider.name,
          this.isRelinkFlow,
          linkedCustodian => {
            if (linkCompletionCallback) {
              linkCompletionCallback(linkedCustodian);
            }
            this.props.getConnectivityCenterData();
          },
          this.details.mode !== linkAccountMode.MANAGE,
          true,
          true
        );
      }
      if (!linkCompletionCallback === true) {
        this.goToDashboard();
      } else {
        this.setState({ isPending: true });
      }
    }
  }

  handleReconnectAccounts(existingCustodians, incomingAccounts) {
    this.isLinkingCompleted = true;

    if (this.isMainWindowOpen() === true) {
      window.linkingCompleted = true;
      this.sendMessage(linkAccountMessageAction.RECONNECT_ACCOUNTS, {
        existingCustodians: existingCustodians,
        incomingAccounts: incomingAccounts,
        linkingAccountsData: {
          providerId: this.getCurrentProviderId(),
          providerName: this.getCurrentProviderName(),
          linkingService: this.details.linkingService,
          category: this.details.category
        }
      });
      this.closeWindow();
    } else {
      this.props.unmarkCustodianAsLinking(this.details.portfolioId, this.details.custodian, null);

      if (!this.details.custodian.parentId === false) {
        this.details.custodian = custodianSelector(store.getState(), this.details.custodian.parentId);
      }
      this.props.reconnectAccounts(
        this.details.portfolioId,
        this.details.category,
        this.details.custodian,
        this.details.linkingService,
        this.details.selectedProvider.linkProviderId,
        this.details.selectedProvider.name,
        existingCustodians,
        incomingAccounts
      );
      this.goToDashboard();
    }
  }

  handleLoaderRefreshClick() {
    this.fetchData(true);
  }

  handleOverlayDismiss() {
    if (!this.props.inline === true) {
      return;
    }

    if (this.state.currentScreen === linkAccountScreen.ACCOUNT_LIST || this.state.isPending === true) {
      this.setState({ ...this.state, showAbortLinkingDialog: true });
      return;
    }

    this.dismissDialog();
  }

  handleAbortDialogNegativeButtonClick() {
    this.dismissDialog();
  }

  handleAbortDialogPositiveButtonClick() {
    this.setState({ ...this.state, showAbortLinkingDialog: false });
  }

  dismissDialog() {
    this.props.unmarkCustodianAsLinking(
      this.details.portfolioId,
      this.details.custodian,
      this.getCurrentProviderName(),
      this.details.category
    );
    DialogOverlay.dismiss(this.props.history, this.props.location);
  }

  showLinkedConnectionsList(groupId) {
    this.setState({
      isPending: true,
      linkedConnections: null,
      currentScreen: linkAccountScreen.CONNECTION_LIST,
      error: null,
      hideDialog: true
    });

    this.props.getLinkedConnectionsList(
      groupId,
      connections => {
        if (connections.length === 0) {
          this.linkAccount(this.details.selectedProvider);
        } else {
          this.setState({
            isPending: false,
            linkedConnections: connections,
            hideDialog: false
          });
        }
      },
      apiError => {
        this.setState({
          isPending: false,
          error: apiError,
          hideDialog: false
        });
      }
    );
  }

  handleLinkedConnectionSelection(connection) {
    if (!connection === true || connection.status === 1) {
      this.linkAccount(this.details.selectedProvider);
      return;
    }

    const params = {
      portfolioId: this.details.portfolioId,
      providerName: connection.linkProviderName,
      providerId: connection.linkProviderId,
      providerAccountId: connection.linkProviderAccountId
    };
    this.getLinkedAccountsList(params, undefined, true);
  }

  handleLinkFailureManualEntry(name, value, ticker, tickerInput) {
    if (this.isMainWindowOpen() === true) {
      this.sendMessage(linkAccountMessageAction.MANUAL_ENTRY, {
        name: name,
        value: value,
        ticker: ticker,
        tickerInput: tickerInput
      });
    } else {
      this.props.unmarkCustodianAsLinking(
        this.details.portfolioId,
        this.details.custodian,
        this.getCurrentProviderName(),
        this.details.category
      );
      this.props.insertTickerCustodianInSection(
        this.details.portfolioId,
        this.details.custodian.sectionId,
        this.details.custodian.id,
        { name: name, value: value, valueTickerId: !ticker === true ? null : ticker.id },
        null,
        null,
        tickerInput
      );
    }
  }

  handleMetamaskTryCatchError = () => {
    this.setState({
      isMetamaskTryCatchError: true
    });

    this.handleOverlayDismiss();
  };

  render() {
    if (!this.details === true) {
      return null;
    }

    const error = this.state.error;
    const isPending = this.state.isPending;
    const showLoader = this.shouldShowLoader();
    const currentScreen = this.state.currentScreen;
    const linkingService = this.details.linkingService;
    const selectedProvider = this.details.selectedProvider;
    const isDialogVisible = this.isDialogVisible();
    const showAbortLinkingDialog = this.state.showAbortLinkingDialog;
    if (
      isPending === true &&
      linkingService === accountLinkingService.ZABO &&
      currentScreen === linkAccountScreen.PROVIDER_PAGE
    ) {
      return <PageLoader />;
    }

    if (isPending === true && this.state.hideDialog === true) {
      return (
        <DialogOverlay onDismiss={this.handleOverlayDismiss}>
          <PageLoader />
        </DialogOverlay>
      );
    }

    return (
      <LinkDialogOverlay onDismiss={this.handleOverlayDismiss} visible={isDialogVisible}>
        <LinkDialog isMobile={isMobile()}>
          <Container>
            {currentScreen !== linkAccountScreen.HARDWARE_WALLET &&
            currentScreen !== linkAccountScreen.RECONNECT_ACCOUNT_LOADER &&
            showLoader === true ? (
              <PageLoader
                errorMessage={!error === false ? error.errorMessage : null}
                onRefresh={this.handleLoaderRefreshClick}
              />
            ) : (
              currentScreen === linkAccountScreen.RECONNECT_ACCOUNT_LOADER &&
              showLoader === true && (
                <>
                  <PageLoader />
                  <ReconnectLoaderTxt>{i18n.t("reconnectAccount.loaderTxt")}</ReconnectLoaderTxt>
                </>
              )
            )}
            {isPending === false && !error === true && currentScreen === linkAccountScreen.PROVIDER_LIST && (
              <ProvidersComponent
                custodianId={this.details.custodian.id}
                mode={this.details.mode}
                category={this.details.category}
                linkingService={linkingService}
                onProviderSelection={this.handleProviderSelection}
                aggregatorToBeSkippedOnProviderSearchScreen={this.aggregatorToBeSkippedOnProviderSearchScreen}
                linkProviderIdToBeSkippedOnProviderSearchScreen={this.linkProviderIdToBeSkippedOnProviderSearchScreen}
              />
            )}
            {!error === true &&
              currentScreen === linkAccountScreen.PROVIDER_PAGE &&
              linkingService === accountLinkingService.YODLEE && (
                <ProviderPageContainer>
                  <ProviderPage id={providerPageContainerId} />
                  <ProviderLogo logo={yodleeLogo} onClick={() => window.kuberaOpen("https://www.yodlee.com/")} />
                </ProviderPageContainer>
              )}
            {!error === true &&
              currentScreen === linkAccountScreen.PROVIDER_PAGE &&
              (linkingService === accountLinkingService.SALTEDGE ||
                linkingService === accountLinkingService.SALTEDGE_EU) && (
                <ProviderPageContainer>
                  <ProviderPageIframe id={providerPageContainerId} />
                  <ProviderLogo logo={saltEdgeLogo} onClick={() => window.kuberaOpen("https://www.saltedge.com/")} />
                </ProviderPageContainer>
              )}
            {!error === true &&
              currentScreen === linkAccountScreen.PROVIDER_PAGE &&
              linkingService === accountLinkingService.SNAPTRADE && (
                <ProviderPageContainer>
                  <ProviderPageIframe id={providerPageContainerId} minHeight={600} />
                  <ProviderLogo logo={snaptradeLogo} onClick={() => window.kuberaOpen("https://www.snaptrade.com/")} />
                </ProviderPageContainer>
              )}
            {!error === true &&
              currentScreen === linkAccountScreen.PROVIDER_PAGE &&
              linkingService === accountLinkingService.MX && (
                <ProviderPageContainer>
                  <ProviderPageIframe id={providerPageContainerId} minHeight={600} />
                </ProviderPageContainer>
              )}
            {!error === true &&
              currentScreen === linkAccountScreen.PROVIDER_PAGE &&
              linkingService === accountLinkingService.FINICITY && (
                <ProviderPageContainer hideMargin={true}>
                  <ProviderPage id={providerPageContainerId} minHeight={800} />
                </ProviderPageContainer>
              )}
            {!error === true &&
              currentScreen === linkAccountScreen.PROVIDER_PAGE &&
              (linkingService === accountLinkingService.FLINKS_BANKING ||
                linkingService === accountLinkingService.FLINKS_INVESTMENT) && (
                <ProviderPageContainer>
                  <ProviderPageIframe id={providerPageContainerId} />
                  <ProviderLogo logo={flinksLogo} onClick={() => window.kuberaOpen("https://www.flinks.com/")} />
                </ProviderPageContainer>
              )}
            {(!this.state.linkedAccounts === false || isPending === true || !error === false) &&
              currentScreen === linkAccountScreen.ACCOUNT_LIST && (
                <ProviderScreenContainer>
                  <SelectLinkedAccountsComponent
                    title={selectedProvider.name}
                    error={error}
                    onRefresh={this.handleLoaderRefreshClick}
                    isPending={isPending}
                    accounts={this.state.linkedAccounts}
                    onAddAccount={this.handleLinkAccount}
                    linkType={this.details.selectedProviderDetails.linkType}
                    successMessage={this.details.selectedProviderDetails.message}
                    portfolio={this.props.currentPortfolio}
                    mode={this.details.mode}
                    details={this.details.selectedProviderDetails}
                    enableLinkedAccounts={this.details.mode === linkAccountMode.SELECT_ACCOUNTS}
                  />
                </ProviderScreenContainer>
              )}
            {isPending === false && currentScreen === linkAccountScreen.LINK_FAILURE && (
              <LinkAccountFailureComponent
                data={this.state.linkErrorData}
                linkingService={linkingService}
                mode={this.details.mode}
                nextAvailableAggregator={this.getNextAvailableAggregator()}
                providerId={this.getCurrentProviderId()}
                providerName={this.getCurrentProviderName()}
                canReconnectAccounts={this.canReconnectAccounts(linkingService, this.details.selectedProviderDetails)}
                linkingServiceHistory={this.linkingServiceHistory}
                onManualEntry={this.handleLinkFailureManualEntry}
                onPositiveButtonClick={this.handleLinkFailurePositiveButtonClick}
                onNegativeButtonClick={this.handleLinkFailureNegativeButtonClick}
                regionCode={this.details.regionCode}
              />
            )}
            {isPending === false && !error === true && currentScreen === linkAccountScreen.CONNECTION_LIST && (
              <SelectLinkedConnectionsComponent
                connections={this.state.linkedConnections}
                onConnectionSelection={this.handleLinkedConnectionSelection}
              />
            )}
            {currentScreen === linkAccountScreen.CRYPTO_API_PAGE && (
              <CryptoApiConnectionComponent
                providerDetails={this.details.selectedProviderDetails}
                isPending={isPending}
                error={error}
                onSubmit={data => this.handleProviderPageSuccess(data)}
              />
            )}
            {currentScreen === linkAccountScreen.HARDWARE_WALLET && (
              <Suspense>
                <HardwareWalletComponent
                  providerDetails={this.details.selectedProviderDetails}
                  isPending={isPending}
                  error={error}
                  onSubmit={data => this.handleProviderPageSuccess(data)}
                />
              </Suspense>
            )}
            {currentScreen === linkAccountScreen.RECONNECT_ACCOUNT && (
              <MatchAccountsComponent
                providerName={this.getCurrentProviderName()}
                existingLinkingService={this.details.custodian.linkType}
                existingCustodians={getCustodiansLinkedWithProviderAccountId(
                  store.getState(),
                  this.details.custodian.linkProviderAccountId,
                  false
                )}
                incomingLinkingService={this.details.linkingService}
                incomingAccounts={this.state.linkedAccounts}
                onSubmit={this.handleReconnectAccounts}
                closeWindow={this.closeWindow}
              />
            )}
            {currentScreen === linkAccountScreen.ZERION_PAGE && linkingService === accountLinkingService.ZERION && (
              <ZerionComponent
                details={this.details.selectedProviderDetails}
                onMetamaskError={this.handleMetamaskTryCatchError}
                custodian={this.details.custodian}
                isMetamaskNotSupported={!isMetaMaskSupportedBrowser}
                submit={data => this.handleProviderPageSuccess(data)}
                isLoading={this.state.isPending}
                id={this.props.custodianId}
                closeWindow={this.closeWindow}
                sendMessage={this.sendMessage}
                handleLinkAccount={this.handleLinkAccount}
                mode={this.details.mode}
              />
            )}
          </Container>
          {showAbortLinkingDialog === true && (
            <ConfirmationDialog
              title={i18n.t("linkAccount.abortLinkingDialogTitle")}
              description={i18n.t("linkAccount.abortLinkingDialogDescription")}
              positiveButtonTitle={i18n.t("stay")}
              negativeButtonTitle={i18n.t("abort")}
              handleNegativeButtonClick={this.handleAbortDialogNegativeButtonClick}
              handlePositiveButtonClick={this.handleAbortDialogPositiveButtonClick}
            />
          )}
          {this.state.noAccountsAvailableToLink === true && (
            <ConfirmationDialog
              title={i18n.t("linkAccount.alreadyAddedDialogTitle")}
              description={
                isCryptoLinkingService(this.details.linkingService)
                  ? i18n.t("zerionmodal.allAccountsConnectedDescription")
                  : i18n.t("linkAccount.alreadyAddedDialogDescription")
              }
              positiveButtonTitle={i18n.t("ok")}
              handlePositiveButtonClick={this.handleProviderPageExit}
            />
          )}
          {this.state.isMetamaskTryCatchError && (
            <ConfirmationDialog
              title={i18n.t("linkAccount.metamaskTryCatchErrorTitle")}
              description={i18n.t("linkAccount.metamaskTryCatchErrorDesc")}
              positiveButtonTitle={i18n.t("ok")}
              handlePositiveButtonClick={this.dismissMetamaskTryCatchError}
            />
          )}
        </LinkDialog>
      </LinkDialogOverlay>
    );
  }
}

const mapStateToProps = state => ({
  currentPortfolio: currentPortfolioSelector(state),
  userEmail: userEmailSelector(state),
  user: userSelector(state)
});

const mapDispatchToProps = {
  getJwtToken: getJwtToken,
  deleteProviderAccount: deleteProviderAccount,
  getLinkTokenForCustodian: getLinkTokenForCustodian,
  reauthCustodian: reauthCustodian,
  getLinkedAccounts: getLinkedAccounts,
  setPublicTokenForCustodian: setPublicTokenForCustodian,
  getLinkConnectionUrl: getLinkConnectionUrl,
  getRefreshConnectionUrl: getRefreshConnectionUrl,
  getReconnectConnectionUrl: getReconnectConnectionUrl,
  linkAccountsWithCustodian: linkAccountsWithCustodian,
  showToastTip: showToastTip,
  unmarkCustodianAsLinking: unmarkCustodianAsLinking,
  custodianLinkingAccountsDataFetched: custodianLinkingAccountsDataFetched,
  getLinkedConnectionsList: getLinkedConnectionsList,
  utilityStatus,
  insertTickerCustodianInSection: insertTickerCustodianInSection,
  expandHoldingsForCustodian: expandHoldingsForCustodian,
  getProviderDetails: getProviderDetails,
  reconnectAccounts: reconnectAccounts,
  searchProviders: searchProviders,
  getConnectionInfo: getConnectionInfo,
  getReConnectionInfo: getReConnectionInfo,
  setLinkingProviderStatus: setLinkingProviderStatus,
  editAccountsInExistingConnection: editAccountsInExistingConnection,
  getConnectivityCenterData: getConnectivityCenterData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LinkAccountProviderPageComponent));
