import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import GridComponentWrapper from "components/grid/GridComponentWrapper";
import {
  GridData,
  GridSheetData,
  GridSectionData,
  GridRowData,
  GridColumnData,
  GridCellData,
  cellType
} from "components/grid/GridDataModel";
import {
  getUuid,
  convertCustodianHistoryApiDateFormatToUIFormat,
  converUIDateFormatToCustodianHistoryApiDateFormat,
  getSortKeyBetween,
  showToastTip,
  fetchCustodianDetailsSuccessAction,
  deleteOwnershipHistoryForCustodian,
  updateOwnershipHistoryForCustodian,
  guessDateInCustodianHistoryUIFormat,
  updateCustodianOwnership,
  custodianDetailsIsPendingSelector,
  currentPortfolioCurrencySelector
} from "@kubera/common";
import { connect } from "react-redux";
import { contextMenuItemType } from "components/contextmenu/ContextMenu";
import DeferredPromise from "utilities/DeferredPromise";

const OwneshipDetailsDialog = styled(Dialog)`
  width: 620px;
  min-height: 600px;
  display: flex;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 60px;
`;

const GridTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;
`;

const GridContainer = styled.div`
  margin-top: 10px;
  border: ${props => (props.theme.mode === "default" ? "1px solid rgba(0, 0, 0, 0.1)" : null)};
  border-top: 0;
`;

const Grid = styled(GridComponentWrapper)`
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
`;

class CustodianOwnershipDetailsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.getEmptyRow = this.getEmptyRow.bind(this);
    this.handleRowUpdate = this.handleRowUpdate.bind(this);
    this.handleCellBlur = this.handleCellBlur.bind(this);
    this.handleAddNewRow = this.handleAddNewRow.bind(this);

    props.details.ownership.sort((a, b) => b.date.localeCompare(a.date));
    this.state = {
      gridData: this.getGridData(props.portfolioCurrency, props.details.ownership)
    };
    this.handleRowContextMenuSelection = this.handleRowContextMenuSelection.bind(this);
  }

  getGridData(currency, ownership) {
    var rows = [];
    let nextSortKey = null;
    for (const [index, ownershipEntry] of ownership.entries()) {
      nextSortKey = getSortKeyBetween(nextSortKey, null);
      const row = this.getEmptyRow(nextSortKey);
      row.id = ownershipEntry.id;
      row.cells[0].value = convertCustodianHistoryApiDateFormatToUIFormat(ownershipEntry.date);
      row.cells[1].value = `${ownershipEntry.percentage}%`;
      row.tsModified = ownershipEntry.tsModified;
      rows.push(row);
      const shouldAddDummyRowForStartDate =
        index === ownership.length - 1 && this.props.chartStartDate < ownershipEntry.date;
      if (shouldAddDummyRowForStartDate) {
        nextSortKey = getSortKeyBetween(nextSortKey, null);
        const row = this.getEmptyRow(nextSortKey);
        row.cells[0].value = convertCustodianHistoryApiDateFormatToUIFormat(this.props.chartStartDate);
        row.cells[1].value = `100%`;
        row.tsModified = 0;
        rows.push(row);
      }
    }

    const section = this.getEmptySection(0, "1");
    section.rows = rows;

    const sheet = this.getEmptySheet("1");
    sheet.sections = [section];

    const gridData = new GridData(currency, [sheet]);
    gridData.currentSheetIndex = 0;
    gridData.forceShowSheetsTitles = false;
    return gridData;
  }

  handleAddNewRow(sheetIndex, sectionIndex, rowIndex, row) {}

  getEmptySheet(sortKey) {
    return new GridSheetData(getUuid(), sortKey, null, []);
  }

  getEmptySection(forIndex, sortKey) {
    const dateColumn = new GridColumnData("Date", true, this.props.isReadOnly === false, false);
    const ownershipColumn = new GridColumnData("Ownership", true, this.props.isReadOnly === false, false);
    const optionsColumn = new GridColumnData(null, true, false, true);
    const columns = [dateColumn, ownershipColumn, optionsColumn];

    if (this.props.isReadOnly === true) {
      columns.pop();
    }

    const sectionData = new GridSectionData(
      getUuid(),
      sortKey,
      "Section " + (forIndex + 1),
      [],
      columns,
      undefined,
      undefined,
      false
    );
    sectionData.showFooter = false;
    sectionData.showHeader = false;
    return sectionData;
  }

  getEmptyRow(sortKey) {
    const dateCell = new GridCellData(cellType.TEXT, "Date", null);
    const ownershipCell = new GridCellData(cellType.TEXT, "Percentage", null);
    const optionsCell = new GridCellData(cellType.OPTIONS, "", null);
    const cells = [dateCell, ownershipCell, optionsCell];
    const rowData = new GridRowData(getUuid(), sortKey, "entry-id-" + Math.random(), cells, 0, false, () => {
      const date = cells[0].value;
      const ownership = !cells[1].value === true ? undefined : cells[1].value.replace(/%/g, "").replace(/ /g, "");
      const guessedDate = guessDateInCustodianHistoryUIFormat(date);

      if (
        guessedDate.isInvalid === false &&
        ownership !== null &&
        ownership !== undefined &&
        isNaN(ownership) === false
      ) {
        return true;
      }
      return false;
    });
    rowData.showHint = false;

    rowData.getContextMenuItems = (row, rowIndex) => {
      if (this.props.isReadOnly === true) {
        return null;
      }
      return [[contextMenuItemType.INSERT_ABOVE], [contextMenuItemType.DELETE]];
    };
    rowData.onUpdateDelay = 3000;
    return rowData;
  }

  isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, dateString) {
    if (!dateString === true) {
      return false;
    }
    const existingEntryIndex = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows.findIndex(
      (row, index) => rowIndex !== index && row.cells[0].value === dateString
    );
    return existingEntryIndex !== -1 && existingEntryIndex !== rowIndex;
  }

  handleChange(newGridData) {
    this.setState({ ...this.state, gridData: newGridData });
  }

  handleRowContextMenuSelection(sheetIndex, sectionIndex, rowIndex, row, menuItem) {
    if (!row.isComplete()) {
      return;
    }

    if (menuItem.id === contextMenuItemType.DELETE.id) {
      const rowsCountBeforeDelete = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows.length;
      if (rowsCountBeforeDelete <= 1) {
        this.props.showToastTip("TIP", i18n.t("lastOwnershipEntryDelete"), null, 3000);
        const promise = new DeferredPromise();
        setTimeout(() => {
          promise.resolve(false);
        }, 0);
        return promise;
      }
      this.props.deleteOwnershipHistoryForCustodian(this.props.details.info.sectionId, this.props.custodianId, row.id);

      const newDetailsInfo = this.props.details;
      let foundAt = -1;
      for (const [index, entry] of this.props.details.ownership.entries()) {
        if (entry.id === row.id) {
          foundAt = index;
          break;
        }
      }
      if (foundAt >= 0) {
        newDetailsInfo.ownership.splice(foundAt, 1);
      }
      this.props.fetchCustodianDetailsAction(newDetailsInfo);

      if (this.shouldUpdateCustodian(row.cells[0].value)) {
        let latestAtIndex = -1;
        let latestTime = -1;
        for (const [index, entry] of newDetailsInfo.ownership.entries()) {
          if (!entry.date === false) {
            const rowDate = new Date(entry.date);
            if (rowDate.getTime() > latestTime) {
              latestTime = rowDate.getTime();
              latestAtIndex = index;
            }
          }
        }
        const newLatestOwnership = newDetailsInfo.ownership[latestAtIndex].percentage;
        this.props.updateCustodianOwnership(this.props.custodianId, newLatestOwnership);
        this.props.onCustodianOwnershipChange(newLatestOwnership);
      }
    }
  }

  handleRowUpdate(sheetIndex, sectionIndex, rowIndex, updatedRow, isFirstEdit) {
    if (updatedRow.isComplete() === false) {
      return;
    }

    const guessedDate = guessDateInCustodianHistoryUIFormat(updatedRow.cells[0].value);
    if (
      guessedDate.isInvalid === true ||
      this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)
    ) {
      return;
    }
    const guessedDateInCustodianHistoryFormat = converUIDateFormatToCustodianHistoryApiDateFormat(
      guessedDate.dateString
    );

    let ownership = updatedRow.cells[1].value.replace(/%/g, "").replace(/ /g, "");
    if (isNaN(ownership)) {
      return;
    }
    ownership = Number(ownership);
    if (ownership > 100) {
      return;
    }

    const updatedRowEntry = {
      id: updatedRow.id,
      percentage: ownership,
      date: guessedDateInCustodianHistoryFormat
    };

    const isNewEntry = updatedRow.tsModified === 0;
    if (isNewEntry === true) {
      updatedRow.tsModified = new Date().getTime() / 1000;
    }

    const newDetailsInfo = this.props.details;
    let found = false;
    for (const [index, entry] of this.props.details.ownership.entries()) {
      if (entry.id === updatedRow.id) {
        newDetailsInfo.ownership[index].percentage = ownership;
        newDetailsInfo.ownership[index].date = guessedDateInCustodianHistoryFormat;
        found = true;
        break;
      }
    }
    if (!found) {
      newDetailsInfo.ownership.push(updatedRowEntry);
      newDetailsInfo.ownership.sort((a, b) => b.date.localeCompare(a.date));
    }

    const updateOwnershipHistory = () => {
      this.props.updateOwnershipHistoryForCustodian(
        isNewEntry,
        this.props.details.info.sectionId,
        this.props.custodianId,
        updatedRowEntry
      );
      this.props.fetchCustodianDetailsAction(newDetailsInfo);
    };
    const shouldUpdateCustodian = this.shouldUpdateCustodian(updatedRow.cells[0].value);
    if (shouldUpdateCustodian) {
      updateOwnershipHistory();
      this.props.updateCustodianOwnership(this.props.custodianId, ownership);
      this.props.onCustodianOwnershipChange(ownership);
    } else {
      updateOwnershipHistory();
    }
  }

  shouldUpdateCustodian(updatedRowDateString) {
    if (!updatedRowDateString === true) {
      return false;
    }

    const updatedRowDate = guessDateInCustodianHistoryUIFormat(updatedRowDateString);
    if (updatedRowDate.isInvalid === true) {
      return false;
    }

    for (const row of this.state.gridData.sheets[0].sections[0].rows) {
      if (!row.cells[0].value === false) {
        const rowDate = new Date(row.cells[0].value);

        if (rowDate.getTime() > updatedRowDate.date.getTime()) {
          return false;
        }
      }
    }
    return true;
  }

  handleCellBlur(sheetIndex, sectionIndex, rowIndex, cellIndex) {
    if (cellIndex === 1) {
      const newGridData = this.state.gridData;
      let cellValue = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex]
        .value;
      if (!cellValue) {
        return;
      }
      cellValue = cellValue.replace(/%/g, "").replace(/ /g, "");
      if (isNaN(cellValue) || Number(cellValue) > 100) {
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[1].invalidInputText = cellValue;
        this.props.showToastTip("TIP", i18n.t("ownershipfieldErrorMessage"), null, 6000);
      } else {
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[1].invalidInputText = null;
      }
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[1].value = `${cellValue}%`;
      this.setState({ ...this.state, gridData: newGridData });
    }

    if (cellIndex === 0) {
      const cellValue = this.state.gridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[cellIndex]
        .value;
      if (!cellValue === true) {
        return;
      }

      const guessedDate = guessDateInCustodianHistoryUIFormat(cellValue);
      var dateString = guessedDate.dateString;
      const newGridData = this.state.gridData;

      if (guessedDate.isInvalid === true) {
        this.props.showToastTip("TIP", i18n.t("invalidDateEntry"), null, 3000);
        if (!dateString === true) {
          dateString = cellValue;
        }
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].invalidInputText = cellValue;
      } else if (this.isDateEntryAlreadyPresent(sheetIndex, sectionIndex, rowIndex, guessedDate.dateString)) {
        this.props.showToastTip("TIP", i18n.t("duplicateDateEntry"), null, 3000);
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].invalidInputText = cellValue;
      } else {
        newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].invalidInputText = null;
      }
      newGridData.sheets[sheetIndex].sections[sectionIndex].rows[rowIndex].cells[0].value = dateString;
      this.setState({ ...this.state, gridData: newGridData });
    }
  }

  render() {
    const gridData = this.state.gridData;
    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <OwneshipDetailsDialog>
          <Container>
            <GridTitle>{i18n.t("ownershipPercentage")}</GridTitle>
            <GridContainer>
              <Grid
                title={i18n.t("ownershipPercentage")}
                gridData={gridData}
                getEmptyRow={this.getEmptyRow}
                onRowContextMenuSelection={this.handleRowContextMenuSelection}
                onChange={this.handleChange}
                onRowUpdate={this.handleRowUpdate}
                onCellBlur={this.handleCellBlur}
                showGridTitle={false}
                onAddNewRow={this.handleAddNewRow}
              />
            </GridContainer>
          </Container>
        </OwneshipDetailsDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isFetchPending: custodianDetailsIsPendingSelector(state),
  portfolioCurrency: currentPortfolioCurrencySelector(state)
});

const mapDispatchToProps = {
  //   fetchDetailsHistory: fetchCustodianDetailsHistory,
  fetchCustodianDetailsAction: fetchCustodianDetailsSuccessAction,
  showToastTip: showToastTip,
  updateOwnershipHistoryForCustodian: updateOwnershipHistoryForCustodian,
  deleteOwnershipHistoryForCustodian: deleteOwnershipHistoryForCustodian,
  updateCustodianOwnership: updateCustodianOwnership
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustodianOwnershipDetailsComponent);
