import React from "react";
import styled from "styled-components";
import { withRouter, portfoliosSelector, accountLinkingService } from "@kubera/common";
import { connect } from "react-redux";
import i18n from "i18next";
import { DialogOverlay, Dialog } from "components/dialog/DialogOverlay";
import TreeChartComponent from "components/charts/TreeChartComponent";

const TreeChartFontSize = 14;

const TreeDialog = styled(Dialog)`
  width: ${props => props.width + 50}px;
  min-width: 600px;
  min-height: 600px;
  display: flex;
  margin-top: 80px;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TreeChart = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const Description = styled.div`
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  background: #0000001a;

  a {
    color: rgba(0, 0, 0, 0.7);
  }
`;

const DescriptionText = styled.div`
  padding: 30px;
  max-width: 600px;
`;

class PortfolioTreeDialog extends React.Component {
  constructTree(tree, portfolioIdMap, links) {
    if (tree.children.length === 0) {
      return tree;
    }

    tree.children.forEach(child => {
      child.children = links[child.id]
        .filter(id => !portfolioIdMap[id] === false)
        .map(id => {
          return { name: portfolioIdMap[id].name, id: id };
        });
      this.constructTree(child, portfolioIdMap, links);
    });
    return tree;
  }

  getTreeHeight(tree) {
    if (tree.children.length === 0) {
      return 1;
    }

    let height = 0;
    tree.children.forEach(child => {
      height = Math.max(height, this.getTreeHeight(child));
    });
    return height + 1;
  }

  getPortfoioTreeData() {
    const portfolioIdMap = {};
    this.props.portfolios.forEach(portfolio => {
      portfolioIdMap[portfolio.id] = portfolio;
    });

    const links = {};
    for (const portfolio of this.props.portfolios) {
      const connectedPortfolios = portfolio.details.custodian
        .filter(item => !item.parentId === true && item.linkType === accountLinkingService.KUBERA_PORTFOLIO)
        .map(item => item.linkProviderAccountId);
      links[portfolio.id] = [...new Set([...connectedPortfolios])];
    }

    const portfoliosWithIncomingLinks = [...new Set([...Object.values(links).flat()])];
    const portfoliosWithoutIncomingLinks = this.props.portfolios
      .map(item => item.id)
      .filter(id => portfoliosWithIncomingLinks.includes(id) === false);

    const tree = this.constructTree(
      {
        name: "",
        children: portfoliosWithoutIncomingLinks.map(id => {
          return { name: portfolioIdMap[id].name, id: id };
        })
      },
      portfolioIdMap,
      links
    );
    return tree;
  }

  getTreeChartWidth(tree) {
    // Try to show at least 15 characters of text per node
    const treeHeight = this.getTreeHeight(tree);
    return Math.min(Math.max(900, TreeChartFontSize * 15 * treeHeight), window.innerWidth - 200);
  }

  render() {
    const data = this.getPortfoioTreeData();
    const treeChartWidth = this.getTreeChartWidth(data);

    return (
      <DialogOverlay onDismiss={this.props.onDismiss}>
        <TreeDialog className={this.props.className} width={treeChartWidth}>
          <Container>
            <TreeChart>
              <TreeChartComponent data={data} width={treeChartWidth} fontSize={TreeChartFontSize} />
            </TreeChart>
            <Description>
              <DescriptionText
                dangerouslySetInnerHTML={{
                  __html: i18n.t("portfolioTreeExplanation")
                }}
              />
            </Description>
          </Container>
        </TreeDialog>
      </DialogOverlay>
    );
  }
}

const mapStateToProps = (state, props) => ({
  portfolios: portfoliosSelector(state)
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PortfolioTreeDialog));
