import React from "react";
import { useRef, useEffect } from "react";
import * as d3 from "d3";
import { capitalizeStringWithSpaces } from "@kubera/common";
import { useTheme, dialogOverlayTheme } from "theme";

function TreeChart(data, width, fontSize, svgRef, theme) {
  const labelXPadding = 8;
  const labelYPadding = 8;

  const svg = d3.select(svgRef.current);
  svg.selectAll("*").remove();

  // Compute the tree height; this approach will allow the height of the
  // SVG to scale according to the breadth (width) of the tree layout.
  const root = d3.hierarchy(data);
  const dx = 45;
  const dy = width / (root.height + 1);

  // Create a tree layout.
  const tree = d3.tree().nodeSize([dx, dy]);

  function formatName(d) {
    // Maximum characters that can be displayed is a factor of the node size dy
    // and the font size. The 1.5 factor is to take into account the space
    // left between the nodes at subsequent levels of the chart.
    const maxChars = (dy * 1.5) / fontSize;
    const name = capitalizeStringWithSpaces(d.data.name);
    if (name.length > maxChars) {
      return name.substring(0, maxChars - 3) + "...";
    }
    return name;
  }

  // Sort the tree and apply the layout.
  root.sort((a, b) => d3.ascending(a.data.name, b.data.name));
  tree(root);

  // Compute the extent of the tree. Note that x and y are swapped here
  // because in the tree layout, x is the breadth, but when displayed, the
  // tree extends right rather than down.
  let x0 = Infinity;
  let x1 = -x0;
  root.each(d => {
    if (d.x > x1) x1 = d.x;
    if (d.x < x0) x0 = d.x;
  });

  // Compute the adjusted height of the tree.
  const height = x1 - x0 + dx * 2;

  svg
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [-dy / 4, x0 - dx, width, height])
    .attr("style", `max-width: 100%; height: auto; font-size: ${fontSize}px; font-family: Inter; font-weight: 400;`);

  const node = svg
    .append("g")
    .attr("stroke-linejoin", "round")
    .attr("stroke-width", 3)
    .selectAll()
    .data(root.descendants())
    .join("g")
    .attr("transform", d => `translate(${d.y - 2},${d.x})`);

  node
    .append("circle")
    .attr("stroke", "#000")
    .attr("stroke-width", 2)
    .attr("stroke-opacity", d => (d.depth === 0 ? 1 : 0))
    .attr("fill", "none")
    .attr("r", 2.5);

  node
    .append("text")
    .attr("dy", "0.31em")
    .attr("text-anchor", "start")
    .text(d => formatName(d))
    .attr("paint-order", "stroke")
    .attr("fill", "rgba(0,0,0)");

  node
    .append("rect")
    .attr("fill", theme.treeChartLabelBG)
    .attr("visibility", d => (!d.data.name === true ? "hidden" : "visible"));

  node.selectAll("text").each(function(d) {
    d.bbox = this.getBBox();
  });

  node
    .selectAll("rect")
    .attr("stroke", "#000")
    .attr("stroke-width", 2)
    .attr("x", d => {
      return d.bbox.x - labelXPadding;
    })
    .attr("y", d => d.bbox.y - labelYPadding)
    .attr("height", d => d.bbox.height + 2 * labelYPadding)
    .attr("width", d => d.bbox.width + 2 * labelXPadding);

  node.selectAll("text").remove();

  node
    .append("text")
    .attr("dy", "0.31em")
    .attr("text-anchor", "start")
    .text(d => formatName(d))
    .attr("paint-order", "stroke")
    .attr("fill", "rgba(0,0,0)");

  function diagonal(s, d) {
    const path = `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`;

    return path;
  }

  svg
    .append("g")
    .attr("fill", "none")
    .attr("stroke", "#000")
    .attr("stroke-opacity", 1)
    .attr("stroke-width", 2)
    .selectAll()
    .data(root.links())
    .join("path")
    .attr("d", d => {
      const source = { ...d.source };
      const target = { ...d.target };

      if (d.source.depth === 0) {
        source.y += source.bbox.width;
      } else {
        source.y += source.bbox.width + labelXPadding - 2;
      }
      target.y -= labelXPadding + 2;
      return diagonal(source, target);
    });

  // Bring text boxes to front so that in case a line runs via a text box
  // due to space contraints, the text box is still visible.
  svg.select("g").raise();
}

function TreeChartComponent({ className, width, fontSize, data }) {
  var svgRef = useRef(null);
  const theme = useTheme();
  const modifiedTheme = { ...theme, ...dialogOverlayTheme(theme.mode) };

  useEffect(() => {
    TreeChart(data, width, fontSize, svgRef, modifiedTheme);
  }, [data, modifiedTheme, fontSize, width]);

  return (
    <div id="tree-chart" className={className}>
      <svg ref={svgRef} />
    </div>
  );
}

export default TreeChartComponent;
