import React from "react";
import i18n from "i18next";
import styled from "styled-components";
import { withRouter } from "@kubera/common";
import { connect } from "react-redux";
import {
  currentPortfolioSelector,
  portfolioNetWorth,
  currentPortfolioNetWorthDataSelector,
  getTickerUsingId,
  convertCurrency,
  chartTimeRange,
  getNetWorthChartStartDateForPortfolio,
  supportedIndicesSelector,
  supportedIndicesIrrSelector,
  irrComparisonIndexesSelector,
  updateUserPreferences,
  netWorthIrrComparisonTimePeriodSelector,
  sanitizeIrr,
  isAppInWhiteLabelMode,
  userPreferencesSelector,
  getCagr,
  siteConfigSelector,
  recapDataSelector,
  getHashParams,
  recapDataTotalInvestableAssetsValueSelector,
  showCopiedToast
} from "@kubera/common";
import CurrencyHeaderLabel from "components/labels/CurrencyHeaderLabel";
import PortfolioIrrDialog, { timePeriods } from "./PortfolioIrrDialog";
import PercentageLabel from "components/labels/PercentageLabel";
import { ReactComponent as QuestionIcon } from "assets/images/question_mark.svg";
import CustodianListDialog from "./CustodianListDialog";
import { ReactComponent as DownArrow } from "assets/images/menu_downarrow.svg";
import { hashParams } from "routes";

export const cardComponents = {
  INVESTABLE_ASSETS: "investable_assets",
  NONE: "none"
};

const Container = styled.div`
  min-height: 404px;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.netWorthContainerBG};
  border: ${props => props.theme.netWorthContainerBR};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const NetWorthTitle = styled.div`
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-feature-settings: "ss01" on, "calt" off;
  white-space: pre-line;
`;

const InvestableAssetsTitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 44px;
`;

const InvestableAssetsTitle = styled(NetWorthTitle)`
  display: flex;
  margin-bottom: 6px;
  cursor: pointer;
`;

const QuestionMarkIcon = styled(QuestionIcon)`
  width: 14px;
  height: 14px;
  margin-left: 3px;
  margin-bottom: 7px;
  cursor: pointer;

  path {
    fill: ${props => props.theme.dashboardComponentContainerCLR};
  }
`;

const FieldValue = styled(CurrencyHeaderLabel)`
  width: fit-content;
  cursor: ${props => (!props.onClick === false ? "pointer" : "auto")};
`;

const CagrContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 44px;
`;

const ChangeContainer = styled.div`
  display: flex;
`;

const CagrTitle = styled.div`
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-transform: ${props => (props.isPlaceholder === true ? "" : "uppercase")};
  filter: ${props => (props.isPlaceholder === true ? "opacity(0.3)" : "opacity(0.7)")};
  cursor: ${props => (props.isPlaceholder === true ? "auto" : "pointer")};
  font-feature-settings: "ss01" on;
`;

const ChangeDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  cursor: ${props => (props.isPlaceholder === true ? "auto" : "pointer")};
  filter: ${props => (props.isPlaceholder === true ? "opacity(0.3)" : null)};
`;

const ChangeTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  font-feature-settings: "ss01" on;
`;

const DropDownArrow = styled(DownArrow)`
  margin-left: 2px;

  path {
    fill: ${props => props.theme.contextMenuButtonColor};
  }
`;

const NetWorthCagr = styled(PercentageLabel)`
  width: fit-content;
  margin-top: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on, "calt" off;
  filter: ${props => (props.isUpdating === true ? "opacity(0.3)" : null)};

  ${ChangeDetails}:hover & {
    text-decoration: underline;
  }
`;

const PlaceholderPercentage = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on;
`;

const PlaceHolderValue = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 19px;
  font-feature-settings: "ss01" on;
  margin-top: 4px;
  filter: opacity(0.3);
`;

const IndexesIrrContainer = styled.div`
  width: fit-content;
  display: flex;
  margin-top: 10px;
`;

const IndexesIrrDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${props => (props.isFirst === true ? "0px" : "24px")};
  cursor: ${props => (props.isPlaceholder === true ? "auto" : "pointer")};
  filter: ${props => (props.isPlaceholder === true ? "opacity(0.3)" : null)};
`;

const IndexIrrValue = styled(PercentageLabel)`
  width: fit-content;
  margin-top: 1px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-feature-settings: "ss01" on;

  ${IndexesIrrContainer}:hover & {
    text-decoration: underline;
  }
`;

class NetWorthScorecard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSettingsDialog: false,
      selectedTimePeriod: props.networthIrrComparisonTimePeriod || timePeriods.START_DATE,
      selectedIndexes: props.irrComparisonIndexes,
      showInvestableAssetsDialog: false
    };

    this.handleTotalChangeClick = this.handleTotalChangeClick.bind(this);
    this.handlePortfolioIrrDialogOnDismiss = this.handlePortfolioIrrDialogOnDismiss.bind(this);
    this.handleTimePeriodSelection = this.handleTimePeriodSelection.bind(this);
    this.handleInvestableAssetsClick = this.handleInvestableAssetsClick.bind(this);
    this.handleInvestableAssetDialogTipClick = this.handleInvestableAssetDialogTipClick.bind(this);
    this.handleInvestableAssetDialogDismiss = this.handleInvestableAssetDialogDismiss.bind(this);
    this.handleNWClick = this.handleNWClick.bind(this);

    this.openCard = this.openCard.bind(this);
    this.modifyCardHashParam = this.modifyCardHashParam.bind(this);
  }

  handleNWClick() {
    navigator.clipboard.writeText(this.props.netWorth).then(() => {
      this.props.showCopiedToast();
    });
  }

  handleInvestableAssetDialogTipClick(e) {
    this.props.updateUserPreferences({
      isInvestableAssetsTipShown: true
    });
  }

  handleInvestableAssetDialogDismiss() {
    this.modifyCardHashParam(cardComponents.NONE);
    this.setState({ showInvestableAssetsDialog: false });
  }

  handleInvestableAssetsClick(e) {
    this.modifyCardHashParam(cardComponents.INVESTABLE_ASSETS);
    this.setState({ showInvestableAssetsDialog: true });
  }

  handleTotalChangeClick(e) {
    this.setState({ showSettingsDialog: true });
  }

  handleTimePeriodSelection(selectedTimePeriod) {
    this.setState({ selectedTimePeriod: selectedTimePeriod });
  }

  handlePortfolioIrrDialogOnDismiss(selectedIndexes, selectedTimePeriod) {
    this.setState({
      showSettingsDialog: false,
      selectedTimePeriod: selectedTimePeriod,
      selectedIndexes: selectedIndexes
    });

    this.props.updateUserPreferences({
      irrComparisonIndexes: selectedIndexes,
      networthIrrComparisonTimePeriod: selectedTimePeriod
    });
  }

  componentDidMount() {
    this.openCard();
  }

  openCard() {
    if (getHashParams(window.location)[hashParams.CARD] === cardComponents.INVESTABLE_ASSETS) {
      this.setState({ showInvestableAssetsDialog: true });
    }
  }

  modifyCardHashParam(card) {
    const detailsHash = card === cardComponents.NONE ? "" : `${hashParams.CARD}=${card}`;
    this.props.history.push({ ...this.props.location, hash: detailsHash });
  }

  getChartRangeFromTimePeriod(timePeriod) {
    if (this.state.selectedTimePeriod === timePeriods.START_DATE) {
      return chartTimeRange.ALL;
    } else if (this.state.selectedTimePeriod === timePeriods.YEAR) {
      return chartTimeRange.YEARLY;
    } else if (this.state.selectedTimePeriod === timePeriods.YTD) {
      return chartTimeRange.YTD;
    }
    return null;
  }

  getNetworthChange(forInvestable) {
    if (!this.props.netWorthData === true || !this.props.netWorthData.data === true) {
      return null;
    }

    const netWorthData = this.props.netWorthData.data[this.getChartRangeFromTimePeriod(this.state.selectedTimePeriod)];
    const netWorthDataPoints = netWorthData.netWorth;
    const netWorthTicker = getTickerUsingId(netWorthData.tickerId).shortName;
    const portfolioTicker = this.props.portfolio.currency;

    if (netWorthDataPoints.length < 2) {
      return null;
    }
    const startPoint = (() => {
      let tempStartPoint;
      if (forInvestable) {
        tempStartPoint = netWorthDataPoints.find(point => point.investibleTotal > 0);
      } else {
        tempStartPoint = netWorthDataPoints.find(point => point.value > 0);
      }

      if (tempStartPoint) {
        return tempStartPoint;
      }

      return netWorthDataPoints[0];
    })();

    const startValue = convertCurrency(
      forInvestable ? startPoint.investibleTotal : startPoint.value,
      netWorthTicker,
      portfolioTicker
    );
    const endValue = convertCurrency(
      forInvestable
        ? netWorthDataPoints[netWorthDataPoints.length - 1].investibleTotal
        : netWorthDataPoints[netWorthDataPoints.length - 1].value,
      netWorthTicker,
      portfolioTicker
    );
    return {
      startValue: startValue,
      startDate: startPoint.date,
      endValue: endValue,
      endDate: netWorthDataPoints[netWorthDataPoints.length - 1].date
    };
  }

  getIndexIrrs() {
    if (
      !this.props.netWorthData === true ||
      !this.props.netWorthData.data === true ||
      !this.props.netWorthData.data.market === true
    ) {
      return null;
    }
    return { ...this.props.supportedIndexesIrr, start_date: this.props.netWorthData.data.market };
  }

  getSelectedIndexesDetails() {
    return this.props.supportedIndexes.filter(item => this.state.selectedIndexes.includes(item.id));
  }

  getPlaceholderIndexesDetails() {
    return this.props.supportedIndexes.filter(item => ["DJI.INDX", "GSPC.INDX", "TSLA.NA"].includes(item.id));
  }

  getCustodianListItems() {
    const investableAssets =
      (this.props.recapData && this.props.recapData.data && this.props.recapData.data.investableAssetsList) || [];

    const recapDataCurrency = this.props.recapData.currency;
    const result = [];
    for (const asset of investableAssets) {
      const underscoreIndex = asset.id.indexOf("_");
      result.push({
        name: asset.name,
        value: convertCurrency(asset.value, recapDataCurrency, this.props.portfolio.currency),
        id: underscoreIndex === -1 ? asset.id : asset.id.substring(0, underscoreIndex)
      });
    }
    result.sort((a, b) => {
      return b.value - a.value;
    });
    return result.filter(item => !item.value === false);
  }

  getNetWorthCagr(netWorthChange) {
    if (!netWorthChange === true) {
      return null;
    }
    if (new Date().getTime() - this.props.netWorthChartStartDate.getTime() < 30 * 24 * 60 * 60 * 1000) {
      return null;
    }
    return getCagr(
      netWorthChange.startValue,
      netWorthChange.endValue,
      netWorthChange.startDate,
      netWorthChange.endDate
    );
  }

  shouldHideCagr() {
    return isAppInWhiteLabelMode() === true && this.props.siteConfig.showCagr === 0;
  }

  getIrrPeriodLabel(selectedTimePeriod) {
    let irrPeriodLabel = "";
    if (this.state.selectedTimePeriod === timePeriods.YTD) {
      irrPeriodLabel = " • YTD";
    } else if (this.state.selectedTimePeriod === timePeriods.YEAR) {
      irrPeriodLabel = " • 1Y";
    }

    return irrPeriodLabel;
  }

  render() {
    const netWorthChange = this.getNetworthChange();
    const investableChange = this.getNetworthChange(true);
    const netWorthCagr = this.getNetWorthCagr(netWorthChange);
    const investableCagr = this.getNetWorthCagr(investableChange);
    const indexIrrs = this.getIndexIrrs();
    const irrPeriodLabel = this.getIrrPeriodLabel(this.state.selectedTimePeriod);

    return (
      <Container className={this.props.className}>
        <ContentContainer>
          <NetWorthTitle>{i18n.t("networth")}</NetWorthTitle>
          <FieldValue
            value={this.props.netWorth}
            currency={this.props.portfolio.currency}
            currencyFontSize={18}
            valueFontSize={36}
            fontWeight={400}
            showZeroAsText={true}
            letterSpacing="-1.86px"
            onClick={this.handleNWClick}
          />
          <InvestableAssetsTitleContainer>
            <InvestableAssetsTitle onClick={this.handleInvestableAssetsClick}>
              {i18n.t("investableAssets")}
            </InvestableAssetsTitle>
            <QuestionMarkIcon onClick={this.handleInvestableAssetsClick} />
          </InvestableAssetsTitleContainer>
          {this.props.recapData !== 0 && (
            <FieldValue
              value={this.props.totalInvestableAssets}
              currency={this.props.portfolio.currency}
              currencyFontSize={18}
              valueFontSize={36}
              fontWeight={400}
              onClick={this.handleInvestableAssetsClick}
              showZeroAsText={true}
              letterSpacing="-1.86px"
            />
          )}
          {!this.props.recapData && <PlaceHolderValue>{"XXXX"}</PlaceHolderValue>}
          <CagrContainer>
            {!netWorthChange === false && !netWorthCagr === false && (
              <CagrTitle onClick={this.handleTotalChangeClick}>{i18n.t("cagr") + irrPeriodLabel}</CagrTitle>
            )}
            {!netWorthCagr === true && (
              <CagrTitle
                isPlaceholder={true}
                dangerouslySetInnerHTML={{
                  __html: i18n.t("cagrPlaceholder")
                }}
              />
            )}
            <ChangeContainer>
              {!netWorthChange === false && !netWorthCagr === false && (
                <ChangeDetails onClick={this.handleTotalChangeClick}>
                  <ChangeTitle>
                    {i18n.t("networth")}
                    <DropDownArrow />
                  </ChangeTitle>
                  <NetWorthCagr
                    value={netWorthCagr}
                    darkBackground={false}
                    updated={true}
                    alignPosition="left"
                    preventAnimation={true}
                  />
                </ChangeDetails>
              )}
              {!netWorthCagr === true && (
                <ChangeDetails isPlaceholder={true}>
                  <ChangeTitle>
                    {i18n.t("networth")}
                    <DropDownArrow />
                  </ChangeTitle>
                  <PlaceholderPercentage>{"+XX%"}</PlaceholderPercentage>
                </ChangeDetails>
              )}
              {!investableChange === false && !investableCagr === false && (
                <ChangeDetails onClick={this.handleTotalChangeClick}>
                  <ChangeTitle>
                    {i18n.t("investable")}
                    <DropDownArrow />
                  </ChangeTitle>
                  <NetWorthCagr
                    value={investableCagr}
                    darkBackground={false}
                    updated={true}
                    alignPosition="left"
                    preventAnimation={true}
                  />
                </ChangeDetails>
              )}
              {!investableCagr === true && (
                <ChangeDetails isPlaceholder={true}>
                  <ChangeTitle>
                    {i18n.t("investable")}
                    <DropDownArrow />
                  </ChangeTitle>
                  <PlaceholderPercentage>{"+XX%"}</PlaceholderPercentage>
                </ChangeDetails>
              )}
            </ChangeContainer>
          </CagrContainer>
          {this.shouldHideCagr() === false && !netWorthChange === false && !netWorthCagr === false && (
            <IndexesIrrContainer>
              {!indexIrrs === false &&
                this.getSelectedIndexesDetails().map((item, index) => {
                  return (
                    <IndexesIrrDetails key={index} onClick={this.handleTotalChangeClick} isFirst={index === 0}>
                      <ChangeTitle>
                        {item.shortName}
                        <DropDownArrow />
                      </ChangeTitle>
                      <IndexIrrValue
                        value={sanitizeIrr(indexIrrs[this.state.selectedTimePeriod][item.id])}
                        darkBackground={false}
                        updated={true}
                        alignPosition="left"
                        preventAnimation={true}
                        showZero={true}
                      />
                    </IndexesIrrDetails>
                  );
                })}
            </IndexesIrrContainer>
          )}
          {this.shouldHideCagr() === false && !netWorthCagr === true && (
            <IndexesIrrContainer>
              {this.getPlaceholderIndexesDetails().map((item, index) => {
                return (
                  <IndexesIrrDetails key={index} isFirst={index === 0} isPlaceholder={true}>
                    <ChangeTitle>
                      {item.shortName}
                      <DropDownArrow />
                    </ChangeTitle>
                    <PlaceholderPercentage>{"+XX%"}</PlaceholderPercentage>
                  </IndexesIrrDetails>
                );
              })}
            </IndexesIrrContainer>
          )}
          {this.state.showSettingsDialog === true && (
            <PortfolioIrrDialog
              currency={this.props.portfolio.currency}
              netWorthChartStartDate={this.props.netWorthChartStartDate}
              investableCagr={investableCagr}
              netWorthCagr={netWorthCagr}
              supportedIndexes={this.props.supportedIndexes}
              supportedIndexesIrr={indexIrrs}
              selectedIndexes={this.state.selectedIndexes}
              selectedTimePeriod={this.state.selectedTimePeriod}
              onTimePeriodSelection={this.handleTimePeriodSelection}
              siteConfig={this.props.siteConfig}
              onDismiss={this.handlePortfolioIrrDialogOnDismiss}
            />
          )}
          {this.state.showInvestableAssetsDialog === true && (
            <CustodianListDialog
              title={i18n.t("investableAssets")}
              description={i18n.t("investableAssetsDialog.description")}
              tip={i18n.t("investableAssetsDialog.tip")}
              knowMore={"https://help.kubera.com/article/47-what-is-investable-assets"}
              currency={this.props.portfolio.currency}
              total={this.props.totalInvestableAssets}
              listItems={this.getCustodianListItems()}
              showTip={this.props.userPreferences.isInvestableAssetsTipShown === false}
              onTipDismiss={this.handleInvestableAssetDialogTipClick}
              onDismiss={this.handleInvestableAssetDialogDismiss}
            />
          )}
        </ContentContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  userPreferences: userPreferencesSelector(state),
  portfolio: currentPortfolioSelector(state),
  netWorth: portfolioNetWorth(state),
  netWorthData: currentPortfolioNetWorthDataSelector(state),
  netWorthChartStartDate: getNetWorthChartStartDateForPortfolio(state),
  supportedIndexes: supportedIndicesSelector(state),
  supportedIndexesIrr: supportedIndicesIrrSelector(state),
  irrComparisonIndexes: irrComparisonIndexesSelector(state),
  networthIrrComparisonTimePeriod: netWorthIrrComparisonTimePeriodSelector(state),
  totalInvestableAssets: recapDataTotalInvestableAssetsValueSelector(state),
  siteConfig: siteConfigSelector(state),
  recapData: recapDataSelector(state)
});

const mapDispatchToProps = {
  updateUserPreferences: updateUserPreferences,
  showCopiedToast: showCopiedToast
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NetWorthScorecard));
