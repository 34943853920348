import { store } from "../store";
import {
  categoryType,
  custodianTaxTypes,
  deletePlanningRule,
  updatePortfolio,
  createPlanningRule
} from "./PortfolioActions";
import {
  currentPortfolioSelector,
  portfolioNetWorth,
  portfolioTotalForCategory,
  custodianSelector,
  sectionSelector,
  sheetSelector,
  getTotalForSection,
  getTotalForSheet,
  getCustodianValue,
  custodianSheetSelector,
  portfolioSelector,
  currentPortfolioCustodiansUpdatedTimestampSelector
} from "../reducers/PortfolioReducer";
import { planningScenariosSelector, planningTargetDateSelector } from "../reducers/PlanningReducer";
import {
  getTickerUsingId,
  tickerTypes,
  convertCurrency,
  getTickerUsingShortName,
  getExchangeRate,
  shortFormatNumberWithCurrency,
  getSymbolForTickerUsingShortName,
  formatNumberWithKuberaNumberFormatSettings
} from "./TickerActions";
import { accountLinkingService } from "./LinkAccountActions";
import {
  months,
  getKuberaDateString,
  parseKuberaDateString,
  getUuid,
  getMonthAndYearFromDate,
  monthsBetweenDates,
  nextMonthEnd,
  currentMonthEnd,
  convertDateOfYearToDate,
  defaultDateOfYear,
  oneYearFromNowMonthEnd
} from "../../utilities/Number";
import { userAgeAtDate, dateForUserAge, userDobSelector } from "../reducers/AuthReducer";
import { toastType, Toast, showToastAction } from "./ToastActions";
import { createSelector } from "reselect";
import memoize from "lodash.memoize";

export const timePeriods = {
  MONTH: "month",
  YEAR: "year",
  HALF_DECADE: "half_decade",
  DECADE: "decade",
  TWO_DECADES: "two_decades"
};

export const getDateForTimePeriod = timePeriod => {
  switch (timePeriod) {
    case timePeriods.MONTH:
      return new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0).setHours(0, 0, 0, 0));
    case timePeriods.YEAR:
      return new Date(new Date(new Date().getFullYear() + 1, new Date().getMonth() + 1, 0).setHours(0, 0, 0, 0));
    case timePeriods.HALF_DECADE:
      return new Date(new Date(new Date().getFullYear() + 5, 11, 31).setHours(0, 0, 0, 0));
    case timePeriods.DECADE:
      return new Date(new Date(new Date().getFullYear() + 10, 11, 31).setHours(0, 0, 0, 0));
    case timePeriods.TWO_DECADES:
      return new Date(new Date(new Date().getFullYear() + 20, 11, 31).setHours(0, 0, 0, 0));
    default:
      return new Date();
  }
};

// set the frequency for repeating rules
export const repeatFrequency = {
  NO_REPEAT: "no_repeat",
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
  BI_ANNUALLY: "bi_annually",
  YEARLY: "yearly"
};

export const periods = {
  [repeatFrequency.MONTHLY]: 1,
  [repeatFrequency.QUARTERLY]: 3,
  [repeatFrequency.BI_ANNUALLY]: 6,
  [repeatFrequency.YEARLY]: 12
};

const setVestingSchedule = rule => {
  const schedule = rule.data[planningVariables.VESTING_SCHEDULE];

  rule.cliffMonths = (schedule.cliff || 0) * (schedule.cliffDuration === planningVariables.YEARS ? 12 : 1);
  rule.totalMonths = schedule.value * (schedule.duration === planningVariables.YEARS ? 12 : 1);
  rule.startDate = parseKuberaDateString(rule.data[planningVariables.DATE_AGE].date);
  rule.startDate = new Date(rule.startDate.getFullYear(), rule.startDate.getMonth() + 1, 0); // snap to end of month
  rule.endCliffDate = new Date(rule.startDate.getFullYear(), rule.startDate.getMonth() + rule.cliffMonths + 1, 0);
  rule.cliffReleased = !rule.cliffMonths || rule.endCliffDate < nextMonthEnd;
  rule.endDate = new Date(new Date(rule.startDate).setMonth(rule.startDate.getMonth() + rule.totalMonths));
  rule.frequency = periods[schedule.frequency];
  rule.monthsPassed = monthsBetweenDates(rule.startDate, nextMonthEnd);
};

// Asset and Debt Class orgnaizations used in setting rule data
export const planningDebtTypes = {
  all: {
    label: "All",
    key: "all"
  }
};

export const planningAssetTypes = {
  all: {
    label: "All",
    key: "all"
  },
  investable: {
    label: "Investable Assets",
    key: "investable"
  },
  cash: {
    label: "Cash",
    key: "cash"
  },
  stocks: {
    label: "Stocks",
    key: "stocks"
  },
  bonds: {
    label: "Bonds",
    key: "bonds"
  },
  funds: {
    label: "Non US Funds",
    key: "funds"
  },
  metals: {
    label: "Precious Metals",
    key: "metals"
  },
  investments: {
    label: "Investments",
    key: "investments"
  },
  crypto: {
    label: "Crypto",
    key: "crypto"
  },
  homes: {
    label: "Real Estate",
    key: "homes"
  },
  // domains: {
  //   label: "Domains",
  //   key: "domains"
  // },
  other: {
    label: "Miscellaneous",
    key: "other"
  },
  taxable: {
    label: "Taxable",
    key: "taxable"
  },
  taxDeferred: {
    label: "Tax Deferred",
    key: "taxDeferred"
  },
  taxFree: {
    label: "Tax Free",
    key: "taxFree"
  }
};

export const planningRulesPrecedence = {
  //implicit liquidates rule
  ZERO: "zero",

  //cash flow changes
  INCOME: "income",
  EXPENSE: "expense",

  // portfolio changes
  ASSET_SELL: "asset_sell",
  NEW_ASSET: "new_asset",
  NEW_DEBT: "new_debt",

  // debt changes
  DEBT_INTEREST: "debt_interest",
  DEBT_PAYMENT: "debt_payment",

  // asset changes
  QUANTITY_CHANGE: "quantity_change",
  ASSET_GROWTH: "asset_growth",
  ASSET_CONTRIBUTION: "asset_contribution",

  // inflation
  NET_WORTH: "net_worth"
};

const precedenceOrder = [
  planningRulesPrecedence.ZERO,
  planningRulesPrecedence.INCOME, // + cash
  planningRulesPrecedence.ASSET_SELL, // + cash
  planningRulesPrecedence.NEW_DEBT, // execute as many positive income rules as possible to avoid investable asset liquidation
  planningRulesPrecedence.EXPENSE, // take care of expenses first
  planningRulesPrecedence.DEBT_INTEREST,
  planningRulesPrecedence.DEBT_PAYMENT,
  planningRulesPrecedence.NEW_ASSET, // asset rules
  planningRulesPrecedence.QUANTITY_CHANGE,
  planningRulesPrecedence.ASSET_GROWTH,
  planningRulesPrecedence.ASSET_CONTRIBUTION,
  planningRulesPrecedence.NET_WORTH //inflation
];

// the tabs in add rule
export const planningRuleCategories = {
  ASSETS: "assets",
  DEBTS: "debts",
  INCOME: "income",
  EXPENSE: "expense"
};

// the sections in each of the tabs
export const planningRuleGroup = {
  HIDDEN: "hidden",
  CHANGE_ASSET: "change_asset",
  ASSET_BUY_SELL: "asset_buy_sell",
  ASSET_PRIVATE_FUNDS: "asset_private_funds",
  ASSET_VESTING: "asset_vesting",
  CHANGE_DEBT: "change_debt",
  DECREASE_DEBTS_CASH: "decrease_debts_cash",
  INCREASE_DEBTS_CASH: "increase_debts_cash",
  INCREASE_CASH: "increase_cash",
  DECREASE_CASH: "decrease_cash"
};

// the possible effects of all rules
export const planningRuleEffect = {
  CHANGE_ASSET: "change_asset",
  INCREASE_ASSET_DECREASE_CASH: "increase_asset_decrease_cash",
  INCREASE_CASH_DECREASE_ASSET: "increase_cash_decrease_asset",
  CHANGE_DEBT: "change_debt",
  DECREASE_DEBTS_CASH: "decrease_debts_cash",
  INCREASE_DEBTS_CASH: "increase_debts_cash",
  INCREASE_CASH: "increase_cash",
  DECREASE_CASH: "decrease_cash",
  CHANGE_NET_WORTH: "change_net_worth",
  TRANSFER: "transfer"
};

// used for ordering rules inside of a parent container
export const cashEffectRules = [
  planningRuleEffect.INCREASE_CASH,
  planningRuleEffect.DECREASE_CASH,
  planningRuleEffect.INCREASE_DEBTS_CASH,
  planningRuleEffect.INCREASE_CASH_DECREASE_ASSET,
  planningRuleEffect.DECREASE_DEBTS_CASH,
  planningRuleEffect.INCREASE_ASSET_DECREASE_CASH
];

export const nonCashEffectRules = [
  planningRuleEffect.CHANGE_ASSET,
  planningRuleEffect.CHANGE_DEBT,
  planningRuleEffect.CHANGE_NET_WORTH,
  planningRuleEffect.TRANSFER
];

const cashContainerKey = `${planningAssetTypes.cash.key}_container`;
const investableContainerKey = `${planningAssetTypes.investable.key}_container`;
const liquidates = "liquidates";
const liquidatesContainerKey = `${liquidates}_container`;
const parentContainerTotal = "parent_container_total";
const noMoreInvestableKey = "NO_MORE_INVESTABLE";

export const splitBreakdownKeys = {
  ADJUSTMENT: "adjustment",
  MAIN_EFFECT: "main_effect"
};

const getSuffixKey = (id, suffix) => `${id}_${suffix}`;
export const getSplitBreakdownKey = (id, cashAmount, ruleEffect) => {
  const suffix =
    (cashAmount > 0 && ruleEffect === planningRuleEffect.INCREASE_ASSET_DECREASE_CASH) ||
    (cashAmount < 0 && ruleEffect === planningRuleEffect.INCREASE_CASH_DECREASE_ASSET)
      ? splitBreakdownKeys.ADJUSTMENT
      : splitBreakdownKeys.MAIN_EFFECT;
  return id ? getSuffixKey(id, suffix) : suffix;
};

const getDbPCKey = id => getSuffixKey(id, parentContainerTotal);

// possible configurations for each rule
export const planningVariables = {
  ASSET_TYPE: "asset_type",
  DEBT_TYPE: "debt_type",
  RATE_PER_YEAR: "rate_per_year",
  RATE_PER_YEAR_WITH_TAX: "rate_per_year_with_tax",
  GROWTH_RATE: "growth_rate",
  PERCENTAGE: "percentage",
  REVISED_PERCENTAGE: "revised_percentage",
  ASSET_ID: "asset_id",
  DEBT_ID: "debt_id",
  AMOUNT: "amount",
  AMOUNT_WITH_TAX: "amount_with_tax",
  COST_WITH_TAX: "cost_with_tax",
  EXPECTED_AMOUNT: "expected_amount",
  DATE_AGE: "date_age",
  DATE_AGE_YEAR: "date_age_year",
  DATE_AGE_REVISED: "date_age_revised",
  MULTIPLE_DATES: "multi_date",
  TICKER_ID: "ticker_id",
  QUANTITY: "quantity",
  MONTHS: "months",
  YEARS: "years",
  REPEAT: "repeat",
  REPEAT_GRADUAL: "repeat_gradual", // deprecated
  NEW_ASSET: "new_asset",
  NEW_DEBT: "new_debt",
  NEW_INCOME: "new_income",
  NEW_EXPENSE: "new_expense",
  META: "meta",
  TICKER_NAME: "ticker_name",
  TAX: "tax",
  TAX_DEDUCTION: "tax_deduction",
  VESTING_SCHEDULE: "vesting_schedule"
};

export const displayVestingDuration = (dataObj, caps = false) => displayDuration(dataObj.duration, dataObj.value, caps);

export const displayDuration = (duration, value, caps = false) =>
  `${duration === planningVariables.YEARS ? `${caps ? "Y" : "y"}ear` : `${caps ? "M" : "m"}onth`}${
    value === 1 ? "" : "s"
  }`;

const taxFieldRuleVariables = [
  planningVariables.AMOUNT_WITH_TAX,
  planningVariables.COST_WITH_TAX,
  planningVariables.RATE_PER_YEAR_WITH_TAX
];

export const createTaxBlock = taxTotal => ({
  id: planningVariables.TAX,
  changes: {
    cumulativeDelta: taxTotal
  },
  isOverlappingRule: true
});

// used in the planning breakdown to explain calculation to user
export const ruleNodeKeys = {
  ALL: planningAssetTypes.all.label,
  OTHERS: "others",
  CASH: planningAssetTypes.cash.label,
  CASH_INFLOW: "Cash inflow from assets / debts",
  ASSET_CASH_GROWTH: "Cash growth",
  CASH_OUTFLOW: "Cash outflow towards assets / debts",
  INCOME: "Income",
  EXPENSE: "Expenses",
  NET_WORTH: "Net Worth"
};

export const defaultTicker = () => {
  const portfolio = currentPortfolioSelector(store.getState());
  return getTickerUsingShortName(portfolio.currency);
};

const defaultSymbol = () => {
  return getSymbolForTickerUsingShortName(defaultTicker().shortName);
};

// the rule data objects
export const planningRules = [
  {
    type: "rule_0",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.HIDDEN,
    effect: planningRuleEffect.TRANSFER,
    isHidden: true,
    precedence: planningRulesPrecedence.ZERO,
    label(ruleData) {
      return "Investable Asset Liquidation";
    },
    description: null,
    data: {},
    variablePlaceholder: {},
    parentContainers: ["rule_1", "rule_2", "rule_3", "rule_28", "rule_4"]
  },
  {
    type: "rule_1",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.CHANGE_ASSET,
    effect: planningRuleEffect.CHANGE_ASSET,
    precedence: planningRulesPrecedence.ASSET_GROWTH,
    label(ruleData) {
      return `Value of All Assets to change by #${planningVariables.GROWTH_RATE}#`;
    },
    description: null,
    data: {
      [planningVariables.ASSET_TYPE]: { value: planningAssetTypes.all.key },
      [planningVariables.GROWTH_RATE]: { value: 5 }
    },
    variablePlaceholder: {},
    parentContainers: [],
    // used to deal with naming conflicts(e.g. asset class, sheet, section, custodian has same name)
    getMainAssetId(ruleData) {
      return ruleData[planningVariables.ASSET_TYPE].value;
    },
    handleNamingConflict(ruleData, conflictingName) {
      return conflictingName;
    }
  },
  {
    type: "rule_2",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.CHANGE_ASSET,
    effect: planningRuleEffect.CHANGE_ASSET,
    precedence: planningRulesPrecedence.ASSET_GROWTH,
    disallowedDuplicateVariables: [planningVariables.ASSET_TYPE],
    label(ruleData) {
      return `Value of #${planningVariables.ASSET_TYPE}# to change by #${planningVariables.GROWTH_RATE}#`;
    },
    description: "“Value of Stocks to appreciate by 10% per year”",
    data: {
      [planningVariables.ASSET_TYPE]: null,
      [planningVariables.GROWTH_RATE]: { value: 7 }
    },
    variablePlaceholder: {
      [planningVariables.ASSET_TYPE]: "this type of asset"
    },
    parentContainers: [], // add changes to cash assets to cash block (patch)
    getMainAssetId(ruleData) {
      return ruleData[planningVariables.ASSET_TYPE].value;
    },
    handleNamingConflict(ruleData, conflictingName) {
      // investable not modified because it is handled differently as a nodeKey
      return conflictingName;
    }
  },
  {
    type: "rule_28",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.CHANGE_ASSET,
    effect: planningRuleEffect.CHANGE_ASSET,
    precedence: planningRulesPrecedence.ASSET_GROWTH,
    disallowedDuplicateVariables: [planningVariables.ASSET_ID],
    label(ruleData) {
      return `Value of #${planningVariables.ASSET_ID}# to change by #${planningVariables.GROWTH_RATE}#`;
    },
    description: "“Value of ‘My Home’ to change by 2% per year”",
    data: {
      [planningVariables.ASSET_ID]: null,
      [planningVariables.GROWTH_RATE]: { value: 7 }
    },
    variablePlaceholder: {
      [planningVariables.ASSET_ID]: "this Asset",
      [planningVariables.GROWTH_RATE]: { value: 7 }
    },
    parentContainers: [],
    getMainAssetId(ruleData) {
      return ruleData[planningVariables.ASSET_ID].items[0].id;
    },
    handleNamingConflict(ruleData, conflictingName) {
      const currentItem = ruleData[planningVariables.ASSET_ID].items[0];
      if (currentItem) {
        if (currentItem.isCustodian) {
          return `${conflictingName} (Asset)`;
        } else if (currentItem.isSection) {
          return `${conflictingName} (Section)`;
        } else if (currentItem.isSheet) {
          return `${conflictingName} (Sheet)`;
        }
      }
      return conflictingName;
    },
    disallowedDuplicateRules: ["rule_3"]
  },
  {
    type: "rule_3",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.CHANGE_ASSET,
    effect: planningRuleEffect.CHANGE_ASSET,
    precedence: planningRulesPrecedence.ASSET_GROWTH,
    disallowedDuplicateVariables: [planningVariables.ASSET_ID],
    label(ruleData) {
      return `Value of #${planningVariables.ASSET_ID}# to become #${planningVariables.EXPECTED_AMOUNT}# by #${planningVariables.DATE_AGE_REVISED}#`;
    },
    get description() {
      return `“Value of ‘My Home’ to become ${shortFormatNumberWithCurrency(
        1000000,
        defaultTicker().shortName,
        false,
        true
      )} by 2030”`;
    },
    data: {
      [planningVariables.ASSET_ID]: null,
      [planningVariables.EXPECTED_AMOUNT]: null,
      [planningVariables.DATE_AGE_REVISED]: null
    },
    get variablePlaceholder() {
      return {
        [planningVariables.ASSET_ID]: "this Asset",
        [planningVariables.EXPECTED_AMOUNT]: `${defaultSymbol()}XXXX`,
        [planningVariables.DATE_AGE_REVISED]: "this year"
      };
    },
    parentContainers: [], // add changes to cash assets to cash block (patch)
    getMainAssetId(ruleData) {
      return ruleData[planningVariables.ASSET_ID].items[0].id;
    },
    handleNamingConflict(ruleData, conflictingName) {
      const currentItem = ruleData[planningVariables.ASSET_ID].items[0];
      if (currentItem) {
        if (currentItem.isCustodian) {
          return `${conflictingName} (Asset)`;
        } else if (currentItem.isSection) {
          return `${conflictingName} (Section)`;
        } else if (currentItem.isSheet) {
          return `${conflictingName} (Sheet)`;
        }
      }
      return conflictingName;
    },
    disallowedDuplicateRules: ["rule_28"]
  },
  {
    type: "rule_4",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.CHANGE_ASSET,
    effect: planningRuleEffect.CHANGE_ASSET,
    precedence: planningRulesPrecedence.ASSET_GROWTH,
    disallowedDuplicateVariables: [planningVariables.TICKER_ID],
    label(ruleData) {
      return `Price of #${planningVariables.TICKER_ID}# to become #${planningVariables.EXPECTED_AMOUNT}# by #${planningVariables.DATE_AGE_REVISED}#`;
    },
    get description() {
      return `“Price of TSLA to become ${shortFormatNumberWithCurrency(
        100000,
        defaultTicker().shortName,
        false,
        true
      )} by year 2030”`;
    },
    data: {
      [planningVariables.TICKER_ID]: null,
      [planningVariables.EXPECTED_AMOUNT]: null,
      [planningVariables.DATE_AGE_REVISED]: null
    },
    get variablePlaceholder() {
      return {
        [planningVariables.TICKER_ID]: "this ticker",
        [planningVariables.EXPECTED_AMOUNT]: `${defaultSymbol()}XXXX`,
        [planningVariables.DATE_AGE_REVISED]: "this year"
      };
    },
    parentContainers: ["rule_3", "rule_28"]
  },
  {
    type: "rule_5",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.CHANGE_ASSET,
    effect: planningRuleEffect.CHANGE_ASSET,
    precedence: planningRulesPrecedence.QUANTITY_CHANGE,
    disallowedDuplicateVariables: [planningVariables.TICKER_ID],
    label(ruleData) {
      return `Quantity of #${planningVariables.TICKER_ID}# to change by #${planningVariables.RATE_PER_YEAR_WITH_TAX}#`;
    },
    description: "Use it for Crypto Staking Rewards and Bonus Shares. Changes asset value. No change in Cash",
    data: {
      [planningVariables.TICKER_ID]: null,
      [planningVariables.RATE_PER_YEAR_WITH_TAX]: { value: 5, [planningVariables.TAX]: null }
    },
    variablePlaceholder: {
      [planningVariables.TICKER_ID]: "this ticker"
    },
    parentContainers: ["rule_1", "rule_2", "rule_3", "rule_28", "rule_4"]
  },
  {
    type: "rule_7",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.ASSET_BUY_SELL,
    effect: planningRuleEffect.INCREASE_ASSET_DECREASE_CASH,
    precedence: planningRulesPrecedence.ASSET_CONTRIBUTION,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      if (!ruleData === false && ruleData[planningVariables.DATE_AGE] === undefined) {
        return `Contribute #${planningVariables.AMOUNT}# towards #${planningVariables.ASSET_ID}#. #${planningVariables.REPEAT}#`;
      }
      return `Contribute #${planningVariables.AMOUNT}# towards #${planningVariables.ASSET_ID}#, starting #${planningVariables.DATE_AGE}#. #${planningVariables.REPEAT}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT]: { value: 1000, tickerId: defaultTicker().id },
        [planningVariables.ASSET_ID]: null,
        [planningVariables.DATE_AGE]: {
          props: { allowPastDate: true },
          date: getKuberaDateString(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).getTime())
        },
        [planningVariables.REPEAT]: { frequency: repeatFrequency.MONTHLY }
      };
    },
    variablePlaceholder: {
      [planningVariables.DATE_AGE]: "this date",
      [planningVariables.ASSET_ID]: "this Asset"
    },
    handleNamingConflict(ruleData, conflictingName) {
      const currentItem = ruleData[planningVariables.ASSET_ID].items[0];
      if (currentItem) {
        if (currentItem.isCustodian) {
          return `${conflictingName} (Asset)`;
        } else if (currentItem.isSection) {
          return `${conflictingName} (Section)`;
        } else if (currentItem.isSheet) {
          return `${conflictingName} (Sheet)`;
        }
      }
      return conflictingName;
    },
    getMainAssetId(ruleData) {
      return ruleData[planningVariables.ASSET_ID].items[0].id;
    },
    parentContainers: ["rule_1", "rule_2", "rule_3", "rule_28", "rule_4"] // for breakdown component
  },
  {
    type: "rule_33",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.ASSET_BUY_SELL,
    effect: planningRuleEffect.INCREASE_ASSET_DECREASE_CASH,
    precedence: planningRulesPrecedence.NEW_ASSET,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Contribute #${planningVariables.AMOUNT}# towards a new asset: #${planningVariables.NEW_ASSET}#, starting #${planningVariables.DATE_AGE}#. #${planningVariables.REPEAT}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT]: { value: 1000, tickerId: defaultTicker().id },
        [planningVariables.NEW_ASSET]: { value: "Retirement Account" },
        [planningVariables.DATE_AGE]: { props: { allowPastDate: true } },
        [planningVariables.REPEAT]: { frequency: repeatFrequency.MONTHLY }
      };
    },
    variablePlaceholder: {
      [planningVariables.DATE_AGE]: "this date"
    },
    parentContainers: ["rule_1", "rule_2", "rule_3", "rule_28", "rule_4"] // for breakdown component
  },
  {
    type: "rule_8",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.ASSET_BUY_SELL,
    effect: planningRuleEffect.INCREASE_ASSET_DECREASE_CASH,
    precedence: planningRulesPrecedence.NEW_ASSET,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Buy a new Asset: #${planningVariables.NEW_ASSET}# for #${planningVariables.AMOUNT}# by #${planningVariables.DATE_AGE}#`;
    },
    get data() {
      return {
        [planningVariables.NEW_ASSET]: null,
        [planningVariables.AMOUNT]: { value: 100000, tickerId: defaultTicker().id },
        [planningVariables.DATE_AGE]: null
      };
    },
    variablePlaceholder: {
      [planningVariables.NEW_ASSET]: "Asset Name",
      [planningVariables.DATE_AGE]: "this date"
    },
    parentContainers: ["rule_1", "rule_2", "rule_3", "rule_28", "rule_4"]
  },
  {
    type: "rule_10",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.ASSET_BUY_SELL,
    effect: planningRuleEffect.INCREASE_CASH_DECREASE_ASSET,
    disallowedDuplicateVariables: [planningVariables.ASSET_ID],
    precedence: planningRulesPrecedence.ASSET_SELL,
    label(ruleData) {
      return `Sell #${planningVariables.ASSET_ID}# for #${planningVariables.AMOUNT_WITH_TAX}# by #${planningVariables.DATE_AGE}#`;
    },
    get data() {
      return {
        [planningVariables.ASSET_ID]: null,
        [planningVariables.AMOUNT_WITH_TAX]: {
          value: 100000,
          tickerId: defaultTicker().id,
          [planningVariables.TAX]: null,
          [planningVariables.TAX_DEDUCTION]: { value: null, tickerId: null }
        },
        [planningVariables.DATE_AGE]: null
      };
    },
    variablePlaceholder: {
      [planningVariables.ASSET_ID]: "this Asset",
      [planningVariables.DATE_AGE]: "this date"
    },
    parentContainers: ["rule_1", "rule_2", "rule_3", "rule_28"]
  },
  {
    type: "rule_34",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.ASSET_BUY_SELL,
    effect: planningRuleEffect.INCREASE_CASH_DECREASE_ASSET,
    disallowedDuplicateVariables: [planningVariables.ASSET_ID],
    isHidden: true,
    precedence: planningRulesPrecedence.ASSET_SELL,
    label(ruleData) {
      return `Withdraw #${planningVariables.AMOUNT}# from #${planningVariables.ASSET_ID}#, starting #${planningVariables.DATE_AGE}#. #${planningVariables.REPEAT}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT]: { value: 1000, tickerId: defaultTicker().id },
        [planningVariables.ASSET_ID]: { props: { onlyCustodians: true } },
        [planningVariables.DATE_AGE]: { props: { allowPastDate: true } },
        [planningVariables.REPEAT]: { frequency: repeatFrequency.MONTHLY }
      };
    },
    variablePlaceholder: {
      [planningVariables.ASSET_ID]: "this account",
      [planningVariables.DATE_AGE]: "this date"
    }
  },
  {
    type: "rule_32",
    category: planningRuleCategories.ASSETS,
    isHidden: true,
    group: planningRuleGroup.ASSET_BUY_SELL,
    effect: planningRuleEffect.INCREASE_CASH_DECREASE_ASSET,
    disallowedDuplicateVariables: [planningVariables.ASSET_ID],
    precedence: planningRulesPrecedence.ASSET_SELL,
    label(ruleData) {
      return `Fully withdraw and close #${planningVariables.ASSET_ID}# by #${planningVariables.DATE_AGE}#`;
    },
    get data() {
      return {
        [planningVariables.ASSET_ID]: { props: { onlyCustodians: true } },
        [planningVariables.DATE_AGE]: null
      };
    },
    variablePlaceholder: {
      [planningVariables.ASSET_ID]: "this account",
      [planningVariables.DATE_AGE]: "this date"
    }
    // might be conflicting with rule_10
  },
  {
    type: "rule_11",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.ASSET_PRIVATE_FUNDS,
    effect: planningRuleEffect.DECREASE_CASH,
    precedence: planningRulesPrecedence.EXPENSE,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Contribute #${planningVariables.AMOUNT}# towards #${planningVariables.ASSET_ID}# as #${planningVariables.META}# on #${planningVariables.MULTIPLE_DATES}#`;
    },
    description: "Reduces cash on hand. No change in asset value.",
    get data() {
      return {
        [planningVariables.AMOUNT]: { value: 50000, tickerId: defaultTicker().id },
        [planningVariables.ASSET_ID]: null,
        [planningVariables.META]: { value: "Capital Call" },
        [planningVariables.MULTIPLE_DATES]: null
      };
    },
    variablePlaceholder: {
      [planningVariables.ASSET_ID]: "this Asset",
      [planningVariables.MULTIPLE_DATES]: "these dates"
    },
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_9",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.ASSET_PRIVATE_FUNDS,
    effect: planningRuleEffect.INCREASE_CASH,
    precedence: planningRulesPrecedence.INCOME,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Distribution of #${planningVariables.AMOUNT_WITH_TAX}# from #${planningVariables.ASSET_ID}# on #${planningVariables.MULTIPLE_DATES}#`;
    },
    description: "Increases cash. No change in asset value.",
    get data() {
      return {
        [planningVariables.AMOUNT_WITH_TAX]: {
          value: 100000,
          tickerId: defaultTicker().id,
          [planningVariables.TAX]: null,
          [planningVariables.TAX_DEDUCTION]: { value: null, tickerId: null }
        },
        [planningVariables.ASSET_ID]: null,
        [planningVariables.MULTIPLE_DATES]: null
      };
    },
    variablePlaceholder: {
      [planningVariables.ASSET_ID]: "this Asset",
      [planningVariables.MULTIPLE_DATES]: "this date"
    },
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_6",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.ASSET_VESTING,
    effect: planningRuleEffect.INCREASE_ASSET_DECREASE_CASH,
    precedence: planningRulesPrecedence.ASSET_CONTRIBUTION,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `#${planningVariables.QUANTITY}# of #${planningVariables.ASSET_ID}#, each valued at #${planningVariables.AMOUNT}#. Vesting #${planningVariables.VESTING_SCHEDULE}# starting #${planningVariables.DATE_AGE}#. Cost being #${planningVariables.COST_WITH_TAX}#`;
    },
    description: "Best for private stock. Enter the cost as 0 if you are getting them as a gift or bonus.",
    get data() {
      return {
        [planningVariables.QUANTITY]: { props: { isUnits: true } },
        [planningVariables.ASSET_ID]: { props: { onlyCustodians: true } },
        [planningVariables.AMOUNT]: { value: 100, tickerId: defaultTicker().id },
        [planningVariables.DATE_AGE]: {
          props: { allowPastDate: true },
          date: getKuberaDateString(new Date(new Date().getFullYear() + 1, 0, 1).getTime())
        },
        [planningVariables.VESTING_SCHEDULE]: {
          frequency: repeatFrequency.MONTHLY,
          value: 4,
          duration: planningVariables.YEARS,
          cliff: 1,
          cliffDuration: planningVariables.YEARS
        },
        [planningVariables.COST_WITH_TAX]: {
          value: 1,
          tickerId: defaultTicker().id,
          [planningVariables.TAX]: null
        }
      };
    },
    variablePlaceholder: {
      [planningVariables.QUANTITY]: "x units",
      [planningVariables.ASSET_ID]: "this Asset"
    },
    parentContainers: ["rule_1", "rule_2", "rule_3", "rule_28", "rule_4"] // for breakdown component
  },
  {
    type: "rule_29",
    category: planningRuleCategories.ASSETS,
    group: planningRuleGroup.ASSET_VESTING,
    effect: planningRuleEffect.INCREASE_ASSET_DECREASE_CASH,
    precedence: planningRulesPrecedence.ASSET_CONTRIBUTION,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `#${planningVariables.QUANTITY}# of '#${planningVariables.TICKER_ID}#'. Vesting #${planningVariables.VESTING_SCHEDULE}# starting #${planningVariables.DATE_AGE}#. Cost being #${planningVariables.COST_WITH_TAX}#`;
    },
    description: "Use it for public stocks. Enter the cost as 0 if you are getting them as bonus.",
    get data() {
      return {
        [planningVariables.QUANTITY]: { props: { isUnits: true } },
        [planningVariables.TICKER_ID]: { props: { allowUnusedTickers: true } },
        [planningVariables.DATE_AGE]: {
          props: { allowPastDate: true },
          date: getKuberaDateString(new Date(new Date().getFullYear() + 1, 0, 1).getTime())
        },
        [planningVariables.VESTING_SCHEDULE]: {
          frequency: repeatFrequency.MONTHLY,
          value: 4,
          duration: planningVariables.YEARS,
          cliff: 1,
          cliffDuration: planningVariables.YEARS
        },
        [planningVariables.COST_WITH_TAX]: {
          value: 1,
          tickerId: defaultTicker().id,
          [planningVariables.TAX]: null
        }
      };
    },
    variablePlaceholder: {
      [planningVariables.QUANTITY]: "x units",
      [planningVariables.TICKER_ID]: "this Stock/Crypto ticker"
    },
    parentContainers: ["rule_1", "rule_2", "rule_4"]
  },
  {
    type: "rule_12",
    category: planningRuleCategories.DEBTS,
    group: planningRuleGroup.CHANGE_DEBT,
    effect: planningRuleEffect.CHANGE_DEBT,
    precedence: planningRulesPrecedence.DEBT_INTEREST,
    label(ruleData) {
      return `Interest on all my debts is #${planningVariables.RATE_PER_YEAR}#`;
    },
    description: null,
    data: {
      [planningVariables.DEBT_TYPE]: { value: planningDebtTypes.all.key },
      [planningVariables.RATE_PER_YEAR]: { value: 5 }
    },
    variablePlaceholder: {},
    parentContainers: ["rule_15", "rule_16"]
  },
  {
    type: "rule_13",
    category: planningRuleCategories.DEBTS,
    group: planningRuleGroup.CHANGE_DEBT,
    effect: planningRuleEffect.CHANGE_DEBT,
    precedence: planningRulesPrecedence.DEBT_INTEREST,
    disallowedDuplicateVariables: [planningVariables.DEBT_ID],
    label(ruleData) {
      return `Interest on #${planningVariables.DEBT_ID}# is #${planningVariables.RATE_PER_YEAR}#`;
    },
    data: {
      [planningVariables.DEBT_ID]: null,
      [planningVariables.RATE_PER_YEAR]: { value: 5 }
    },
    variablePlaceholder: {
      [planningVariables.DEBT_ID]: "this Debt"
    },
    parentContainers: []
  },
  {
    type: "rule_14",
    category: planningRuleCategories.DEBTS,
    group: planningRuleGroup.DECREASE_DEBTS_CASH,
    effect: planningRuleEffect.DECREASE_DEBTS_CASH,
    precedence: planningRulesPrecedence.DEBT_PAYMENT,
    disallowedDuplicateRules: ["rule_30"],
    label(ruleData) {
      return `Pay off #${planningVariables.PERCENTAGE}# of all my debts every year`;
    },
    data: {
      [planningVariables.DEBT_TYPE]: { value: planningDebtTypes.all.key },
      [planningVariables.PERCENTAGE]: { value: 5 }
    },
    variablePlaceholder: {},
    parentContainers: ["rule_1", "rule_2", "rule_13", "rule_16"]
  },
  {
    type: "rule_15",
    category: planningRuleCategories.DEBTS,
    group: planningRuleGroup.DECREASE_DEBTS_CASH,
    effect: planningRuleEffect.DECREASE_DEBTS_CASH,
    precedence: planningRulesPrecedence.DEBT_PAYMENT,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      if (!ruleData === false && ruleData[planningVariables.DATE_AGE] === undefined) {
        return `Pay #${planningVariables.AMOUNT}# towards #${planningVariables.DEBT_ID}#. #${planningVariables.REPEAT}#`;
      }
      return `Pay #${planningVariables.AMOUNT}# towards #${planningVariables.DEBT_ID}#, starting #${planningVariables.DATE_AGE}#. #${planningVariables.REPEAT}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT]: { value: 2000, tickerId: defaultTicker().id },
        [planningVariables.DEBT_ID]: null,
        [planningVariables.DATE_AGE]: {
          props: { allowPastDate: true },
          date: getKuberaDateString(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).getTime())
        },
        [planningVariables.REPEAT]: { frequency: repeatFrequency.MONTHLY }
      };
    },
    variablePlaceholder: {
      [planningVariables.DEBT_ID]: "this Debt",
      [planningVariables.DATE_AGE]: "this date"
    },
    parentContainers: ["rule_1", "rule_2", "rule_13"]
  },
  {
    type: "rule_30",
    category: planningRuleCategories.DEBTS,
    group: planningRuleGroup.DECREASE_DEBTS_CASH,
    effect: planningRuleEffect.DECREASE_DEBTS_CASH,
    precedence: planningRulesPrecedence.DEBT_PAYMENT,
    disallowedDuplicateRules: ["rule_14"],
    label(ruleData) {
      return `Pay off all my debts in the next #${planningVariables.MONTHS}#`;
    },
    description: null,
    data: {
      [planningVariables.DEBT_TYPE]: { value: planningDebtTypes.all.key },
      [planningVariables.MONTHS]: { value: 24 }
    },
    variablePlaceholder: {},
    parentContainers: ["rule_1", "rule_2", "rule_13", "rule_16"]
  },
  {
    type: "rule_16",
    category: planningRuleCategories.DEBTS,
    group: planningRuleGroup.INCREASE_DEBTS_CASH,
    effect: planningRuleEffect.INCREASE_DEBTS_CASH,
    precedence: planningRulesPrecedence.NEW_DEBT,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Take out a new debt: #${planningVariables.NEW_DEBT}# of #${planningVariables.AMOUNT}# by #${planningVariables.DATE_AGE}#`;
    },
    get data() {
      return {
        [planningVariables.NEW_DEBT]: { value: "Personal Loan" },
        [planningVariables.AMOUNT]: { value: 10000, tickerId: defaultTicker().id },
        [planningVariables.DATE_AGE]: null
      };
    },
    variablePlaceholder: {
      [planningVariables.NEW_DEBT]: "Personal Loan",
      [planningVariables.DATE_AGE]: "this date"
    },
    parentContainers: ["rule_1", "rule_2"] // to properly show cash impact
  },
  {
    type: "rule_17",
    category: planningRuleCategories.INCOME,
    group: planningRuleGroup.INCREASE_CASH,
    effect: planningRuleEffect.INCREASE_CASH,
    precedence: planningRulesPrecedence.INCOME,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Income of #${planningVariables.AMOUNT_WITH_TAX}# from #${planningVariables.NEW_INCOME}#. #${planningVariables.REPEAT}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT_WITH_TAX]: {
          value: 10000,
          tickerId: defaultTicker().id,
          [planningVariables.TAX]: null,
          [planningVariables.TAX_DEDUCTION]: { value: null, tickerId: null }
        },
        [planningVariables.NEW_INCOME]: { value: "Salary" },
        [planningVariables.REPEAT]: {
          frequency: repeatFrequency.MONTHLY,
          changeBy: {
            increasing: true,
            percentage: 10,
            frequency: repeatFrequency.YEARLY,
            dateOfYear: "2023-01-01"
          },
          till:
            !userDobSelector(store.getState()) === true
              ? null
              : { age: 50, date: getKuberaDateString(dateForUserAge(store.getState(), 50)) }
        }
      };
    },
    variablePlaceholder: {},
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_18",
    category: planningRuleCategories.INCOME,
    group: planningRuleGroup.INCREASE_CASH,
    effect: planningRuleEffect.INCREASE_CASH,
    precedence: planningRulesPrecedence.INCOME,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      if (!ruleData === false && ruleData[planningVariables.DATE_AGE] === undefined) {
        return `Income of #${planningVariables.AMOUNT_WITH_TAX}# from #${planningVariables.ASSET_ID}# as #${planningVariables.NEW_INCOME}#. #${planningVariables.REPEAT}#`;
      }
      return `Income of #${planningVariables.AMOUNT_WITH_TAX}# from #${planningVariables.ASSET_ID}# as #${planningVariables.NEW_INCOME}#, starting #${planningVariables.DATE_AGE}#. #${planningVariables.REPEAT}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT_WITH_TAX]: {
          value: 2000,
          tickerId: defaultTicker().id,
          [planningVariables.TAX]: null,
          [planningVariables.TAX_DEDUCTION]: { value: null, tickerId: null }
        },
        [planningVariables.ASSET_ID]: null,
        [planningVariables.NEW_INCOME]: { value: "Rent" },
        [planningVariables.DATE_AGE]: {
          props: { allowPastDate: true },
          date: getKuberaDateString(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).getTime())
        },
        [planningVariables.REPEAT]: { frequency: repeatFrequency.MONTHLY }
      };
    },
    variablePlaceholder: {
      [planningVariables.ASSET_ID]: "this Asset",
      [planningVariables.DATE_AGE]: "this date"
    },
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_19",
    category: planningRuleCategories.INCOME,
    group: planningRuleGroup.INCREASE_CASH,
    effect: planningRuleEffect.INCREASE_CASH,
    precedence: planningRulesPrecedence.INCOME,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Income of #${planningVariables.RATE_PER_YEAR_WITH_TAX}# from #${planningVariables.ASSET_ID}# as #${planningVariables.NEW_INCOME}#, paid #${planningVariables.REPEAT}#`;
    },
    data: {
      [planningVariables.RATE_PER_YEAR_WITH_TAX]: { value: 3, [planningVariables.TAX]: null },
      [planningVariables.ASSET_ID]: null,
      [planningVariables.NEW_INCOME]: { value: "Interest" },
      [planningVariables.REPEAT]: {
        frequency: repeatFrequency.YEARLY,
        props: { disableRevisions: true, shortText: true }
      }
    },
    variablePlaceholder: {
      [planningVariables.ASSET_ID]: "this Asset"
    },
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_20",
    category: planningRuleCategories.INCOME,
    group: planningRuleGroup.INCREASE_CASH,
    effect: planningRuleEffect.INCREASE_CASH,
    precedence: planningRulesPrecedence.INCOME,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Windfall income of #${planningVariables.AMOUNT_WITH_TAX}# as #${planningVariables.NEW_INCOME}#, expected by #${planningVariables.DATE_AGE}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT_WITH_TAX]: {
          value: 500000,
          tickerId: defaultTicker().id,
          [planningVariables.TAX]: null,
          [planningVariables.TAX_DEDUCTION]: { value: null, tickerId: null }
        },
        [planningVariables.NEW_INCOME]: { value: "Bonus" },
        [planningVariables.DATE_AGE]: null
      };
    },
    variablePlaceholder: {
      [planningVariables.DATE_AGE]: "this date"
    },
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_31",
    category: planningRuleCategories.INCOME,
    group: planningRuleGroup.INCREASE_CASH,
    effect: planningRuleEffect.INCREASE_CASH,
    precedence: planningRulesPrecedence.INCOME,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Future Income of #${planningVariables.AMOUNT_WITH_TAX}# from #${planningVariables.NEW_INCOME}#, starting #${planningVariables.DATE_AGE}#. #${planningVariables.REPEAT}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT_WITH_TAX]: {
          value: 10000,
          tickerId: defaultTicker().id,
          [planningVariables.TAX]: null,
          [planningVariables.TAX_DEDUCTION]: { value: null, tickerId: null }
        },
        [planningVariables.NEW_INCOME]: { value: "Social Security" },
        [planningVariables.DATE_AGE]:
          !userDobSelector(store.getState()) === true
            ? null
            : { age: 67, date: getKuberaDateString(dateForUserAge(store.getState(), 67)) },
        [planningVariables.REPEAT]: { frequency: repeatFrequency.MONTHLY }
      };
    },
    variablePlaceholder: {
      [planningVariables.DATE_AGE]: "this date"
    },
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_21",
    category: planningRuleCategories.EXPENSE,
    group: planningRuleGroup.DECREASE_CASH,
    effect: planningRuleEffect.DECREASE_CASH,
    precedence: planningRulesPrecedence.EXPENSE,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Expense of #${planningVariables.AMOUNT}# towards #${planningVariables.NEW_EXPENSE}#. #${planningVariables.REPEAT}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT]: { value: 6000, tickerId: defaultTicker().id },
        [planningVariables.NEW_EXPENSE]: { value: "Expenses" },
        [planningVariables.REPEAT]: { frequency: repeatFrequency.MONTHLY }
      };
    },
    variablePlaceholder: {},
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_22",
    category: planningRuleCategories.EXPENSE,
    group: planningRuleGroup.DECREASE_CASH,
    effect: planningRuleEffect.DECREASE_CASH,
    precedence: planningRulesPrecedence.EXPENSE,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Expense of #${planningVariables.AMOUNT}# for #${planningVariables.ASSET_ID}# towards #${planningVariables.NEW_EXPENSE}#. #${planningVariables.REPEAT}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT]: { value: 1000, tickerId: defaultTicker().id },
        [planningVariables.ASSET_ID]: null,
        [planningVariables.NEW_EXPENSE]: { value: "Maintenance" },
        [planningVariables.REPEAT]: { frequency: repeatFrequency.YEARLY }
      };
    },
    variablePlaceholder: {
      [planningVariables.ASSET_ID]: "this Asset"
    },
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_23",
    category: planningRuleCategories.EXPENSE,
    group: planningRuleGroup.DECREASE_CASH,
    effect: planningRuleEffect.DECREASE_CASH,
    precedence: planningRulesPrecedence.EXPENSE,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Expense of #${planningVariables.PERCENTAGE}# of #${planningVariables.ASSET_ID}# towards #${planningVariables.NEW_EXPENSE}#. #${planningVariables.REPEAT}#`;
    },
    data: {
      [planningVariables.PERCENTAGE]: { value: 1 },
      [planningVariables.ASSET_ID]: null,
      [planningVariables.NEW_EXPENSE]: { value: "Asset Management" },
      [planningVariables.REPEAT]: {
        props: { disableRevisions: true },
        frequency: repeatFrequency.YEARLY
      }
    },
    variablePlaceholder: {
      [planningVariables.ASSET_ID]: "this Asset"
    },
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_24",
    category: planningRuleCategories.EXPENSE,
    group: planningRuleGroup.DECREASE_CASH,
    effect: planningRuleEffect.DECREASE_CASH,
    precedence: planningRulesPrecedence.EXPENSE,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Big expense of #${planningVariables.AMOUNT}# towards #${planningVariables.NEW_EXPENSE}#, expected by #${planningVariables.DATE_AGE}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT]: { value: 100000, tickerId: defaultTicker().id },
        [planningVariables.NEW_EXPENSE]: { value: "World Trip" },
        [planningVariables.DATE_AGE]: null
      };
    },
    variablePlaceholder: {
      [planningVariables.DATE_AGE]: "this date"
    },
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_25",
    category: planningRuleCategories.EXPENSE,
    group: planningRuleGroup.DECREASE_CASH,
    effect: planningRuleEffect.DECREASE_CASH,
    precedence: planningRulesPrecedence.EXPENSE,
    disallowedDuplicateVariables: [],
    label(ruleData) {
      return `Future expense of #${planningVariables.AMOUNT}# towards #${planningVariables.NEW_EXPENSE}#, starting #${planningVariables.DATE_AGE}#. #${planningVariables.REPEAT}#`;
    },
    get data() {
      return {
        [planningVariables.AMOUNT]: { value: 8000, tickerId: defaultTicker().id },
        [planningVariables.NEW_EXPENSE]: { value: "College fees" },
        [planningVariables.DATE_AGE]: null,
        [planningVariables.REPEAT]: {
          props: { disableRevisions: false },
          frequency: repeatFrequency.MONTHLY
        }
      };
    },
    variablePlaceholder: {
      [planningVariables.DATE_AGE]: "this date"
    },
    parentContainers: ["rule_1", "rule_2"]
  },
  {
    type: "rule_26",
    category: planningRuleCategories.EXPENSE,
    group: planningRuleGroup.DECREASE_CASH,
    effect: planningRuleEffect.CHANGE_NET_WORTH,
    precedence: planningRulesPrecedence.NET_WORTH,
    label(ruleData) {
      return `Inflation rate is #${planningVariables.RATE_PER_YEAR}#`;
    },
    description: "Shows net worth adjusted for inflation",
    data: {
      [planningVariables.RATE_PER_YEAR]: { value: 3 }
    },
    variablePlaceholder: {}
  },
  {
    type: "rule_27",
    category: planningRuleCategories.EXPENSE,
    group: planningRuleGroup.DECREASE_CASH,
    effect: planningRuleEffect.DECREASE_CASH,
    precedence: planningRulesPrecedence.EXPENSE,
    label(ruleData) {
      return `Withdrawal rate to be at #${planningVariables.PERCENTAGE}# till #${planningVariables.DATE_AGE_YEAR}#. Then revise to #${planningVariables.REVISED_PERCENTAGE}#`;
    },
    description: "A flat rate of cash withdrawal every month, so that you don’t have to mention the expenses",
    get data() {
      return {
        [planningVariables.PERCENTAGE]: { value: 6 },
        [planningVariables.DATE_AGE_YEAR]:
          !dateForUserAge(store.getState(), 50) === true
            ? null
            : { date: getKuberaDateString(dateForUserAge(store.getState(), 50)), age: 50 },
        [planningVariables.REVISED_PERCENTAGE]: { value: 4 }
      };
    },
    variablePlaceholder: {
      [planningVariables.DATE_AGE_YEAR]: "this date"
    },
    parentContainers: ["rule_1", "rule_2"]
  }
];

const liquidationEligibleRules = new Set(["rule_1", "rule_2", "rule_3", "rule_28", "rule_4"]);

const overridingPrecedenceGroups = new Set([
  planningRulesPrecedence.ASSET_GROWTH, // rule_3, rule_4, rule_28, rule_2, rule_1
  planningRulesPrecedence.DEBT_INTEREST, // rule_12, rule_13
  planningRulesPrecedence.DEBT_PAYMENT // rule_15, rule_14, rule_30
]);

const setOverridingRules = (ruleGroup, cashRuleId) => {
  for (let i = 0; i < ruleGroup.length; i++) {
    const rule = ruleGroup[i];
    for (let j = i - 1; j >= 0; j--) {
      if (rule.custodians.size === 0) break;

      const overridingRule = ruleGroup[j];
      const sharedKeys = findIntersectingKeys(overridingRule.initialCustodiansMap, rule.custodians);
      if (sharedKeys.size === 0) continue;

      const pushDataPoint = (overridingRule, containerRule, cashOverride = false) => {
        if (!containerRule.overridingCustodians) containerRule.overridingCustodians = new Set();
        let finalSharedKeys = sharedKeys;
        for (const key of sharedKeys) {
          if (containerRule.overridingCustodians.has(key)) {
            finalSharedKeys.delete(key);
          }
          containerRule.overridingCustodians.add(key);
          // with the inverse cash override, there is a chance that overriding custodians are represented twice
        }
        const initialValue = findSetTotalFromSourceMap(finalSharedKeys, containerRule.initialCustodiansMap); // containerRule may have smaller values for the same id
        if (!initialValue) return;
        const { id, type, data, nodeKey, mainAssetId } = overridingRule;
        containerRule.overridingDataPointsInit.push({
          id,
          type,
          data,
          nodeKey,
          mainAssetId,
          changes: {
            initialValue
          },
          isOverridingRule: true,
          cashOverride,
          containerNodeKey: containerRule.nodeKey
        });
      };
      rule.id === cashRuleId ? pushDataPoint(rule, overridingRule, true) : pushDataPoint(overridingRule, rule);
      // since all cash values have to be shown in cash block, remove from other block (inverse cash override)

      for (const key of sharedKeys) rule.custodians.delete(key);
    }
  }
};

export const getPlanningDateStringWithMonthlyInterval = (date, monthsPassed) => {
  date.setDate(1);
  // incrementing month by 1 to get last date of current month
  date = new Date(date.getFullYear(), date.getMonth() + monthsPassed + 1, 0);
  date.setHours(0, 0, 0, 0);
  return getKuberaDateString(date);
};

// utility functions for calculations, could be moved to Number.js
export const getPlanningDateString = (date, daysPassed) => {
  date = new Date(date.setDate(date.getDate() + daysPassed));
  date.setHours(0, 0, 0, 0);
  return getKuberaDateString(date);
};

const numberOfMonthsToTargetDate = targetDate => {
  var currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return monthsBetweenDates(currentDate, targetDate);
};

// given a rule id, this finds the object from planningRules
export const getRuleObject = rule => {
  var ruleObject = planningRules.find(item => item.type === rule.type);
  if (!ruleObject === true) {
    return null;
  }

  ruleObject = { ...ruleObject, ...rule };
  return ruleObject;
};

export const duplicateRulesInstanceIds = scenarioRules => {
  var duplicateInstanceIds = [];
  for (let index = scenarioRules.length - 1; index > 0; index--) {
    const rule = scenarioRules[index];
    const ruleObject = getRuleObject(rule);
    for (let testIndex = index - 1; testIndex >= 0; testIndex--) {
      const ruleToTest = scenarioRules[testIndex];
      if (rule.type !== ruleToTest.type && !ruleObject.disallowedDuplicateRules) {
        continue;
      }

      // disallowedDuplicateRules is an array of rule ids that cannot be duplicated.
      if (ruleObject.disallowedDuplicateRules) {
        // check if the rule to be compared can not be duplicated with base rule id
        if (ruleObject.disallowedDuplicateRules.indexOf(ruleToTest.type) !== -1) {
          // for rule which can not be duplicated, check if the variable can duplicated
          if (ruleObject.disallowedDuplicateVariables && ruleObject.disallowedDuplicateVariables.length > 0) {
            for (const variable of ruleObject.disallowedDuplicateVariables) {
              const ruleDataString = !rule.data[variable] === false ? JSON.stringify(rule.data[variable]) : "";
              const testRuleDataString =
                !ruleToTest.data[variable] === false ? JSON.stringify(ruleToTest.data[variable]) : "";
              const onlyOneExists =
                Object.prototype.hasOwnProperty.call(rule.data, variable) ^
                Object.prototype.hasOwnProperty.call(ruleToTest.data, variable); // in some cases there may be a variable in only one rule
              if ((ruleDataString || testRuleDataString) && (ruleDataString === testRuleDataString || onlyOneExists)) {
                // mark a rule as a duplicate instance only if other rule is enabled
                if (Object.prototype.hasOwnProperty.call(ruleToTest, "disabled") && !ruleToTest.disabled) {
                  duplicateInstanceIds.push(ruleObject.id);
                } else if (Object.prototype.hasOwnProperty.call(ruleObject, "disabled") && !ruleObject.disabled) {
                  duplicateInstanceIds.push(ruleToTest.id);
                }
                break;
              }
            }
          } else {
            if (Object.prototype.hasOwnProperty.call(ruleToTest, "disabled") && !ruleToTest.disabled) {
              duplicateInstanceIds.push(ruleObject.id);
            } else if (Object.prototype.hasOwnProperty.call(ruleObject, "disabled") && !ruleObject.disabled) {
              duplicateInstanceIds.push(ruleToTest.id);
            }
          }
        } else if (rule.type === ruleToTest.type) {
          // checking for same rule ids
          if (ruleObject.disallowedDuplicateVariables && ruleObject.disallowedDuplicateVariables.length > 0) {
            // check if the variable can duplicated
            for (const variable of ruleObject.disallowedDuplicateVariables) {
              const ruleDataString = !rule.data[variable] === false ? JSON.stringify(rule.data[variable]) : "";
              const testRuleDataString =
                !ruleToTest.data[variable] === false ? JSON.stringify(ruleToTest.data[variable]) : "";
              if (ruleDataString === testRuleDataString) {
                // mark a rule as a duplicate instance only if other rule is enabled
                if (Object.prototype.hasOwnProperty.call(ruleToTest, "disabled") && !ruleToTest.disabled) {
                  duplicateInstanceIds.push(rule.id);
                } else if (Object.prototype.hasOwnProperty.call(ruleObject, "disabled") && !ruleObject.disabled) {
                  duplicateInstanceIds.push(ruleToTest.id);
                }
                break;
              }
            }
          } else {
            duplicateInstanceIds.push(rule.id);
          }
        }
      } else if (!ruleObject.disallowedDuplicateRules === true) {
        if (!ruleObject.disallowedDuplicateVariables === true) {
          duplicateInstanceIds.push(rule.id);
        } else if (ruleObject.disallowedDuplicateVariables && ruleObject.disallowedDuplicateVariables.length > 0) {
          for (const variable of ruleObject.disallowedDuplicateVariables) {
            const ruleDataString = !rule.data[variable] === false ? JSON.stringify(rule.data[variable]) : "";
            const testRuleDataString =
              !ruleToTest.data[variable] === false ? JSON.stringify(ruleToTest.data[variable]) : "";
            if (ruleDataString === testRuleDataString) {
              if (Object.prototype.hasOwnProperty.call(ruleToTest, "disabled") && !ruleToTest.disabled) {
                duplicateInstanceIds.push(rule.id);
              } else if (Object.prototype.hasOwnProperty.call(ruleObject, "disabled") && !ruleObject.disabled) {
                duplicateInstanceIds.push(ruleToTest.id);
              } else if (ruleToTest.disabled === undefined && ruleObject.disabled === undefined) {
                // if disable/enable is not touched and rules are cloned
                duplicateInstanceIds.push(rule.id);
              }
              break;
            }
          }
        }
      }
    }
  }
  return duplicateInstanceIds;
};

export const getFilteredRuleObjects = scenarioRules => {
  const duplicateRuleInstanceIds = duplicateRulesInstanceIds(scenarioRules);
  return scenarioRules
    .map(rule => getRuleObject(rule))
    .filter(
      item =>
        item.type === "rule_0" ||
        (item !== null &&
          !item.isHidden === true &&
          !item.disabled === true &&
          isRuleDataValid(item) === true &&
          duplicateRuleInstanceIds.includes(item.id) === false)
    );
};

// finds in the intersection of two maps, two sets or a map and a set
const findIntersectingKeys = (map1, map2) => {
  const shared = new Set();
  const smallerMap = map1.size < map2.size ? map1 : map2;
  const largerMap = map1.size < map2.size ? map2 : map1;

  for (const key of smallerMap.keys()) {
    if (largerMap.has(key)) {
      shared.add(key);
    }
  }
  return shared;
};

const findSetTotalFromSourceMap = (setKeys, sourceMap) => {
  let sum = 0;
  if (setKeys && sourceMap) {
    [...setKeys].forEach(value => {
      const valToAdd = sourceMap.has(value)
        ? sourceMap.get(value).ignore
          ? 0
          : sourceMap.get(value).total
        : undefined;
      sum += valToAdd === undefined ? 0 : valToAdd;
    });
  }
  return sum;
};

export const printPlanningSchema = () => {
  const ruleTypeDataMap = {};
  planningRules.forEach(rule => {
    if (rule.data && !rule.isHidden) {
      const dataKeys = Object.keys(rule.data);
      if (dataKeys.length > 0) {
        ruleTypeDataMap[rule.type] = dataKeys.join(",");
      }
    }
  });
  console.log(JSON.stringify(ruleTypeDataMap));
};

// helper functions for implicit rule logic
const handleRuleExists = (obj, rule) => {
  obj.percent = undefined; // do not create an implicit rule if an explicit rule exists
  obj.blockId = rule.id;
};

// the main function for calculating planning data
export const fetchPlanningData = (portfolio, onSuccess, minDate = null) => {
  return (dispatch, getState) => {
    if (!portfolio) {
      portfolio = currentPortfolioSelector(getState());
    }
    var targetDate = planningTargetDateSelector(getState(), portfolio);
    if (minDate && targetDate.getTime() < minDate.getTime()) {
      targetDate = minDate;
    }

    const scenarios = planningScenariosSelector(getState(), portfolio);
    const numberOfMonthsUntilTargetDate = numberOfMonthsToTargetDate(targetDate) + 1;
    const initialNetWorth = portfolioNetWorth(getState(), portfolio);
    const initialAssetsTotal = portfolioTotalForCategory(store.getState(), portfolio, categoryType.ASSET);
    const [initialInvestableTotal, initialInvestableMap] = getAssetDataForFilter(
      getState(),
      portfolio,
      filterTypes.ASSET_TYPE,
      planningAssetTypes.investable.key
    );
    const initialDebtsTotal = portfolioTotalForCategory(getState(), portfolio, categoryType.DEBT);
    const [initialCashTotal, initialCashOnHandMap] = getAssetDataForFilter(
      getState(),
      portfolio,
      filterTypes.ASSET_TYPE,
      planningAssetTypes.cash.key
    );

    var planningData = [];

    // template for artifical assets(they represent and tax impact of a rule)
    const artificialAssetObj = {
      total: 0,
      value: 0,
      valueTickerId: defaultTicker().id,
      ignore: false,
      isAsset: true,
      isInvestable: true,
      isArtificialAsset: true // easily determine artificial assets in calculateCumulativeChangesForRule
    };

    const tickerToDataMap = {
      [tickerTypes.STOCK]: { assetClass: planningAssetTypes.stocks.key },
      [tickerTypes.FIAT]: { assetClass: planningAssetTypes.metals.key },
      [tickerTypes.CRYPTO]: { assetClass: planningAssetTypes.crypto.key }
    };

    for (const scenario of scenarios) {
      var dataForScenario = [];

      const investableMap = new Map(initialInvestableMap);
      const cashOnHandMap = new Map(initialCashOnHandMap);
      // adds implicit cash and investable rules if there is any rule with a cash effect
      const filteredObjs = getFilteredRuleObjects(scenario.rule);
      const clonedRules = window.kbStructuredClone(scenario.rule);

      // central db across rules, storing assets, parent container totals, and other data
      const db = new Map();

      const addPCKeysToDB = (parentObjId, keys) => {
        if (!keys.size) return;
        const dbPCKey = getDbPCKey(parentObjId);
        if (!db.has(dbPCKey)) db.set(dbPCKey, new Set());
        const currSet = db.get(dbPCKey);
        keys.forEach(key => currSet.add(key));
      };

      const liquidatesRuleContainer = {
        exists: false,
        id: null,
        containerKey: liquidatesContainerKey
      };
      const cashRuleContainer = {
        percent: undefined,
        blockId: null,
        assetType: planningAssetTypes.cash.key,
        containerKey: cashContainerKey
      };
      const investableRuleContainer = {
        ...cashRuleContainer,
        assetType: planningAssetTypes.investable.key,
        containerKey: investableContainerKey
      };
      const selectedTickers = new Set();
      for (const rule of filteredObjs) {
        const selectedTicker = rule.data[planningVariables.TICKER_ID];
        if (selectedTicker !== undefined) {
          selectedTickers.add(selectedTicker.items[0]);
        }
        // determining if there should be an implicit cash growth rule added
        if (cashEffectRules.includes(rule.effect) || taxFieldRuleVariables.some(variable => rule.data[variable])) {
          liquidatesRuleContainer.exists = true;
          cashRuleContainer.percent = 0; // there exists at least one rule that will end up in inflow/outflow/income/expense or will have a tax impact
          investableRuleContainer.percent = 0;
        }
      }
      // if investable or cash growth exists, set percent to undefined so we don't add it again
      for (const rule of filteredObjs) {
        if (rule.type === "rule_2") {
          const assetType = rule.data[planningVariables.ASSET_TYPE].value;
          if (assetType === planningAssetTypes.cash.key) {
            handleRuleExists(cashRuleContainer, rule);
          } else if (assetType === planningAssetTypes.investable.key) {
            handleRuleExists(investableRuleContainer, rule);
          }
        }
      }
      if (liquidatesRuleContainer.exists) {
        liquidatesRuleContainer.id = getUuid();
        clonedRules.push({
          id: liquidatesRuleContainer.id,
          type: "rule_0"
        });
        db.set(liquidatesContainerKey, liquidatesRuleContainer.id);
      }
      const createAndAddImplicitRule = obj => {
        if (obj.percent !== undefined) {
          obj.blockId = getUuid();
          clonedRules.push({
            id: obj.blockId,
            type: "rule_2",
            data: {
              [planningVariables.ASSET_TYPE]: { value: obj.assetType },
              [planningVariables.GROWTH_RATE]: { value: obj.percent }
            },
            isImplicitRule: true
          });
        }
        if (obj.blockId) {
          db.set(obj.containerKey, obj.blockId);
        }
      };
      createAndAddImplicitRule(cashRuleContainer);
      createAndAddImplicitRule(investableRuleContainer);

      const unorderedObjects = getFilteredRuleObjects(clonedRules);
      if (!planningReady(getState()) || unorderedObjects.length === 0) {
        var dataForEmptyScenario = [];
        for (let i = 0; i <= numberOfMonthsUntilTargetDate; i++) {
          dataForEmptyScenario.push({
            date: getPlanningDateStringWithMonthlyInterval(new Date(), i),
            networth: initialNetWorth,
            assetsTotal: initialAssetsTotal,
            investableTotal: initialInvestableTotal,
            debtsTotal: initialDebtsTotal,
            cashTotal: initialCashTotal,
            rules: []
          });
        }
        planningData.push({
          data: dataForEmptyScenario,
          currency: portfolio.currency,
          scenario: scenario
        });
        continue;
      }

      const newCustodianMap = new Map(); // for tracking new custodians introduced as rules
      // adding new assets based on rules
      for (const rule of unorderedObjects) {
        const addArtificialAsset = (suffix, assetClass = suffix) => {
          const key = getSuffixKey(rule.id, suffix);
          const obj = {
            ...artificialAssetObj,
            assetClass,
            isLiquidates: suffix === liquidates
          };
          if (
            assetClass === planningAssetTypes.cash.key ||
            (suffix === liquidates && rule.id === cashRuleContainer.blockId)
          ) {
            cashOnHandMap.set(key, obj);
            // liquidates subtracts from investable, so we do not want to treat it as cash in most cases except for in the cash block
          }
          investableMap.set(key, obj);
          newCustodianMap.set(key, obj);
        };
        // creating artificial cash objects for all rules that have some cash impact
        if (cashEffectRules.includes(rule.effect)) {
          addArtificialAsset(planningAssetTypes.cash.key);
          addArtificialAsset(planningAssetTypes.investable.key); // to be commented out
        }

        // if rule has to consider tax, add an artificial cash asset representing the tax
        if (taxFieldRuleVariables.some(variable => rule.data[variable])) {
          rule.hasTax = true;
          addArtificialAsset(planningAssetTypes.taxable.key, planningAssetTypes.cash.key);
        }

        if (liquidatesRuleContainer.exists && liquidationEligibleRules.has(rule.type)) {
          rule.hasLiquidates = true;
          addArtificialAsset(liquidates);
        }

        // creating artificial assets for rules that define a new asset
        const tickerVestRule = rule.type === "rule_29";
        const newEmptyAsset = tickerVestRule || rule.type === "rule_33";
        if (
          !rule.data[planningVariables.NEW_ASSET] === false ||
          !rule.data[planningVariables.NEW_DEBT] === false ||
          newEmptyAsset
        ) {
          const isAsset = !rule.data[planningVariables.NEW_ASSET] === false || tickerVestRule;
          const futureCustodianValue = rule.data[planningVariables.AMOUNT] || {
            tickerId: defaultTicker().id,
            value: 0
          };
          const tickerId = tickerVestRule
            ? rule.data[planningVariables.TICKER_ID].items[0]
            : futureCustodianValue.tickerId;
          const tickerObj = getTickerUsingId(tickerId);
          const exchangeRate = getExchangeRate(tickerObj.shortName, portfolio.currency);
          const key = getSuffixKey(rule.id, isAsset ? planningVariables.NEW_ASSET : planningVariables.NEW_DEBT);
          const obj = {
            total: newEmptyAsset ? 0 : futureCustodianValue.value * exchangeRate,
            value: newEmptyAsset ? 0 : futureCustodianValue.value,
            valueTickerId: tickerId,
            ignore: !tickerVestRule,
            isAsset,
            isInvestable: tickerVestRule,
            assetClass: tickerVestRule && tickerToDataMap[tickerObj.type]?.assetClass
          };
          newCustodianMap.set(key, obj);
          if (obj.isInvestable) investableMap.set(key, obj);
        }
      }

      // next, populate all rules with initial custodians. then, we will delete overriding custodians and group the remaining custodians
      const ruleNodeKeysToAssetIDs = new Map(); // for preventing node keys that have the same name but are different assets
      for (const rule of unorderedObjects) {
        const initialCustodiansMap = getRuleInitialCustodians(
          getState(),
          portfolio,
          rule,
          newCustodianMap,
          cashOnHandMap
        );
        rule.initialCustodiansMap = initialCustodiansMap;
        rule.custodians = new Set(initialCustodiansMap.keys());
        rule.initialValue = findSetTotalFromSourceMap(rule.custodians, initialCustodiansMap);
        rule.overridingDataPointsInit = [];
        rule.nodeKey = getRuleNodeKey(rule);

        // testing for duplicate node keys that have different assets
        const excludedNodeKeys = [
          ruleNodeKeys.INCOME,
          ruleNodeKeys.CASH_INFLOW,
          ruleNodeKeys.EXPENSE,
          ruleNodeKeys.CASH_OUTFLOW,
          ruleNodeKeys.CASH,
          ruleNodeKeys.ALL
        ]; // these node keys don't count as duplicate names
        if (!rule.getMainAssetId || excludedNodeKeys.includes(rule.nodeKey)) {
          // duplicate name correction not supported by these rules
          continue;
        }
        // if a node key has different asset ids(meaning different asset ids refer to the same name), each different id should have its own row with a different name
        rule.mainAssetId = rule.getMainAssetId(rule.data);
        const otherObjs = ruleNodeKeysToAssetIDs.get(rule.nodeKey) || [];

        if (!otherObjs.find(item => item.mainAssetId === rule.mainAssetId)) {
          // if the asset id exists under the same name, no need to push it
          otherObjs.push({
            mainAssetId: rule.mainAssetId,
            alternateName: rule.handleNamingConflict(rule.data, rule.nodeKey)
          });
        }

        ruleNodeKeysToAssetIDs.set(rule.nodeKey, otherObjs);
      }

      const assetIdToAlternateName = new Map();
      for (const assetIdObjs of ruleNodeKeysToAssetIDs.values()) {
        if (assetIdObjs.length > 1) {
          // if there is more than one asset that can have the same name, alternate names must be used
          for (const assetIdObj of assetIdObjs) {
            assetIdToAlternateName.set(assetIdObj.mainAssetId, assetIdObj.alternateName);
          }
        }
      }

      for (const rule of unorderedObjects) {
        if (rule.mainAssetId) rule.nodeKey = assetIdToAlternateName.get(rule.mainAssetId) || rule.nodeKey; // using an alternate name if necessary
      }

      const precedenceToRuleGroups = new Map();
      for (const rule of unorderedObjects) {
        const { precedence } = rule;
        if (!precedenceToRuleGroups.has(precedence)) precedenceToRuleGroups.set(precedence, []);
        precedenceToRuleGroups.get(precedence).push(rule);
      }

      const scenarioRuleObjects = [];
      for (const precedence of precedenceOrder) {
        const group = precedenceToRuleGroups.get(precedence);
        if (!group) continue;
        const sortedGroup = group.sort((a, b) => {
          // implicit rules are not added by user so should be given lowest precedence
          if (a.isImplicitRule === b.isImplicitRule) {
            return a.numCustodians - b.numCustodians; // numCustodians is initialCustodiansMap.size before artificial assets are added
          }
          return a.isImplicitRule ? 1 : -1;
        });
        if (overridingPrecedenceGroups.has(precedence)) setOverridingRules(sortedGroup, cashRuleContainer.blockId);
        scenarioRuleObjects.push(...sortedGroup);
      }

      const cashRule = scenarioRuleObjects.find(item => item.id === cashRuleContainer.blockId);
      let liquidationRule = null; // will be set later in clean up loop
      const assetToRules = new Map();

      for (const rule of scenarioRuleObjects) {
        rule.investableCustodians = new Set();
        rule.cashCustodians = new Set();
        for (const key of rule.custodians) {
          if (!newCustodianMap.has(key)) {
            // first step in compacting assets that are shared by the same set of rules
            if (!assetToRules.has(key)) assetToRules.set(key, new Set());
            assetToRules.get(key).add(rule.id);
            rule.custodians.delete(key);
          } else {
            if (investableMap.has(key)) rule.investableCustodians.add(key);
            if (cashOnHandMap.has(key)) rule.cashCustodians.add(key);
          }

          if (!db.has(key)) {
            // the asset will be retrieved and deleted from db later
            const obj = rule.initialCustodiansMap.get(key);
            obj.tickerPrice = getExchangeRate(getTickerUsingId(obj.valueTickerId).shortName, portfolio.currency) || 1; // optimization to prevent calling getExchangeRate multiple times, handle unknown ticker
            obj.value = obj.total / obj.tickerPrice; // .total considers ownership percentage and polarity with debts, but .value does not
            obj.assetSize = 1;
            db.set(key, obj);
          }
        }
      }

      const ruleGroupsToAssets = new Map();
      for (const asset of assetToRules.keys()) {
        const sortedRuleArray = [...assetToRules.get(asset)].sort(); // the same set of rules have the same ordering which leads to the same key
        const isInvestable = investableMap.has(asset);
        const isCash = cashOnHandMap.has(asset);
        const currKey = getSuffixKey(
          sortedRuleArray.join(","),
          getSuffixKey(
            isInvestable
              ? isCash
                ? planningAssetTypes.cash.key
                : planningAssetTypes.investable.key
              : planningAssetTypes.investments.key,
            categoryType.ASSET
          )
        ); // ex: rule_id1,rule_id2,rule_id3_investable_asset
        for (const ruleIId of sortedRuleArray) {
          const currRule = scenarioRuleObjects.find(item => item.id === ruleIId); // add this asset to all rules that share it
          currRule.custodians.add(currKey);
          if (isInvestable) {
            currRule.investableCustodians.add(currKey);
            if (isCash) {
              cashOnHandMap.set(currKey, {}); // key simply has to exist here, as this map is used later on for finding cash assets [can be deletd]
              currRule.cashCustodians.add(currKey);
            }
          }
        }
        if (!ruleGroupsToAssets.has(currKey)) ruleGroupsToAssets.set(currKey, new Set());
        ruleGroupsToAssets.get(currKey).add(asset);
      }

      for (const assetGroup of ruleGroupsToAssets.keys()) {
        // now that all assets are grouped, we can compact them
        const allAssetIds = [...ruleGroupsToAssets.get(assetGroup)];
        const firstAsset = db.get(allAssetIds[0]);
        const maintainTicker = selectedTickers.has(firstAsset.valueTickerId);
        const tickerPrice = maintainTicker ? firstAsset.tickerPrice : 1; // if there is a rule set on a specific ticker, do not convert to base currency

        let total = 0;
        for (const id of allAssetIds) {
          total += db.get(id).total;
          db.delete(id);
          cashOnHandMap.delete(id);
        }

        db.set(assetGroup, {
          total,
          value: total / tickerPrice,
          valueTickerId: maintainTicker ? firstAsset.valueTickerId : defaultTicker().id,
          tickerPrice,
          assetSize: allAssetIds.length
        });
      }

      // finding parent containers to group changes of rules based on their assets
      for (const rule of scenarioRuleObjects) {
        if (!rule.parentContainers) continue;

        rule.parentContainerMap = new Map();
        const allKeys = new Set(rule.custodians);

        const addToPCMap = (sharedKeys, potentialParentObj) => {
          if (sharedKeys.size === 0) return;

          for (const key of sharedKeys) allKeys.delete(key);

          let finalKeys = sharedKeys;
          addPCKeysToDB(potentialParentObj.id, finalKeys);
          if (rule.parentContainerMap.has(potentialParentObj.id)) {
            // parent obj already has this child instance id
            finalKeys = new Set([...sharedKeys, ...rule.parentContainerMap.get(potentialParentObj.id)]);
          } else {
            potentialParentObj.childInstanceIds = potentialParentObj.childInstanceIds || [];
            potentialParentObj.childInstanceIds.push(rule.id); // so that breakdown component can find the children
          }
          rule.parentContainerMap.set(potentialParentObj.id, finalKeys);
        };

        if (cashRule && rule.id !== cashRule.id) addToPCMap(rule.cashCustodians, cashRule);
        for (const potentialParentObj of scenarioRuleObjects) {
          if (allKeys.size === 0) break;
          if (!rule.parentContainers.includes(potentialParentObj.type)) continue;

          const sharedKeys = findIntersectingKeys(potentialParentObj.custodians, allKeys);
          addToPCMap(sharedKeys, potentialParentObj);
        }
        rule.parentContainerMap.set(rule.id, allKeys);
        addPCKeysToDB(rule.id, allKeys);
      }

      // generic clean up, preprocessing for specific rules, to handle edge cases and prevent repeated calculations in calculateCumulativeChangesForRule
      for (const rule of scenarioRuleObjects) {
        db.set(rule.id, {}); // container for rule state
        rule.numCashAssets = rule.cashCustodians.size;
        const dbPCKey = getDbPCKey(rule.id);
        if (db.has(dbPCKey)) {
          const allAssets = db.get(dbPCKey);
          const newObj = {
            cumulativeDelta: findSetTotalFromSourceMap(allAssets, db),
            investableCumulativeDelta: findSetTotalFromSourceMap(
              findIntersectingKeys(allAssets, rule.investableCustodians),
              db
            ),
            cashCumulativeDelta: findSetTotalFromSourceMap(findIntersectingKeys(allAssets, rule.cashCustodians), db),
            nodeKey: rule.nodeKey
          };
          db.set(dbPCKey, newObj);
          // for keeping track of deltas added to liquidation containers
        }
        let startDate = parseKuberaDateString(rule.data[planningVariables.DATE_AGE]?.date);
        const repeatData = rule.data[planningVariables.REPEAT];
        if (startDate) {
          startDate = startDate < currentMonthEnd ? currentMonthEnd : startDate;
          startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
          const finalDateString = getKuberaDateString(startDate.getTime());
          rule.data[planningVariables.DATE_AGE].date = finalDateString;
          if (repeatData) repeatData.dateOfYear = finalDateString; // execute repeat on same month as startDate
          // ensure startDate is in the future, normalize it to end of the month
        }
        if (repeatData?.changeBy?.percentage && repeatData.frequency === repeatFrequency.YEARLY) {
          repeatData.changeBy.dateOfYear = repeatData.dateOfYear || defaultDateOfYear;
          repeatData.changeBy.frequency = repeatFrequency.YEARLY;
          // if frequency is yearly and there is compounding, compound in the same month and ensure changeBy is yearly too
        }
        if (rule.type === "rule_0") {
          const { blockId } = cashRuleContainer;
          rule.containerIds = [...rule.custodians].map(id => id.split("_")[0]).filter(id => id !== blockId);
          rule.cashOutputSet = new Set([getSuffixKey(blockId, liquidates)]);
          liquidationRule = rule;
        } else if (rule.type === "rule_3") {
          // preprocessing to find the dailyChange and also handling overriding differently
          /**
           * overriding must be handled differently because the expected amount should also change
           * e.g. instance 1: asset_1(in section 1) goes from 50,000 to 100,000 and section 1 goes from 100,000 to 150,000
           * section 1's actual initial value will be 50,000 and it should go to 0 because asset_1 will go to 100,000 so the total will be 150,000 anyway
           */
          let expectedAmount = getAmountFromExpectedAmount(rule.data[planningVariables.EXPECTED_AMOUNT]);
          let targetDate = new Date(rule.data[planningVariables.DATE_AGE_REVISED].date);
          const monthsToTarget = numberOfMonthsToTargetDate(targetDate);
          let fullValue = findSetTotalFromSourceMap(
            new Set(rule.initialCustodiansMap.keys()),
            rule.initialCustodiansMap
          );
          rule.zeroStart = fullValue === 0;
          fullValue = rule.zeroStart ? 0.01 : fullValue; // deal with 0 values
          let initialValue = fullValue;
          if (rule.overridingDataPointsInit) {
            initialValue = rule.overridingDataPointsInit.reduce(
              (acc, obj) => acc - (obj.cashOverride ? 0 : obj.changes.initialValue),
              initialValue
            ); //cashOverride is there to always give preference to cash container in overriding
            expectedAmount = (expectedAmount / fullValue) * initialValue;
          }
          rule.monthlyChange = calculateLinearChange(initialValue, expectedAmount, 1, monthsToTarget, false);
          const { revisedPercentage, increasing } = rule.data[planningVariables.DATE_AGE_REVISED];
          if (!isNaN(revisedPercentage) && revisedPercentage !== null) {
            rule.revisedDelta = revisedPercentage * (increasing ? 1 : -1);
            targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0);
            rule.data[planningVariables.DATE_AGE_REVISED].date = getKuberaDateString(targetDate.getTime());
          }
        } else if (rule.type === "rule_4") {
          // preprocessing to find the initialTickerPrice and the tickerPriceDelta
          const tickerId = rule.data[planningVariables.TICKER_ID].items[0];
          rule.initialTickerPrice = getExchangeRate(getTickerUsingId(tickerId).shortName, portfolio.currency);
          let targetDate = new Date(rule.data[planningVariables.DATE_AGE_REVISED].date);
          const monthsToTarget = numberOfMonthsToTargetDate(targetDate);

          const expectedPrice = getAmountFromExpectedAmount(rule.data[planningVariables.EXPECTED_AMOUNT]);
          rule.tickerPriceDelta = calculateLinearChange(
            rule.initialTickerPrice,
            expectedPrice,
            1,
            monthsToTarget,
            false
          );
          const { revisedPercentage, increasing } = rule.data[planningVariables.DATE_AGE_REVISED];
          if (!isNaN(revisedPercentage) && revisedPercentage !== null) {
            rule.revisedDelta = ((expectedPrice * revisedPercentage) / 1200) * (increasing ? 1 : -1);
            targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0);
            rule.data[planningVariables.DATE_AGE_REVISED].date = getKuberaDateString(targetDate.getTime());
          }
        } else if (rule.type === "rule_6") {
          const quantity = rule.data[planningVariables.QUANTITY].value;
          rule.perLotValue = quantity * getAmountFromExpectedAmount(rule.data[planningVariables.AMOUNT]);
          rule.perLotCost = quantity * getAmountFromExpectedAmount(rule.data[planningVariables.COST_WITH_TAX]);

          setVestingSchedule(rule);
        } else if (rule.type === "rule_9" || rule.type === "rule_11") {
          rule.dateIndex = 0; // used to track the current contribution/distribution date
        } else if (rule.type === "rule_14") {
          rule.decayPercentage = calculateMonthlyDecayPercentage(rule.data[planningVariables.PERCENTAGE].value);
        } else if (rule.type === "rule_26") {
          rule.decayPercentage = calculateMonthlyDecayPercentage(rule.data[planningVariables.RATE_PER_YEAR].value);
        } else if (rule.type === "rule_29") {
          rule.perLotQuantity = rule.data[planningVariables.QUANTITY].value;
          rule.perLotCost =
            rule.perLotQuantity * getAmountFromExpectedAmount(rule.data[planningVariables.COST_WITH_TAX]);

          setVestingSchedule(rule);
        } else if (rule.type === "rule_30") {
          const totalDebt = findSetTotalFromSourceMap(rule.custodians, db);
          rule.monthsRemaining = totalDebt < 0 ? 0 : rule.data[planningVariables.MONTHS].value; // this value is stored here to be decremented since the monthly payoff is totalDebt / monthsRemaining
        }

        // memory intensive and not required
        delete rule.initialCustodiansMap;
        delete rule.overridingCustodians;
      }

      for (var i = 0; i < numberOfMonthsUntilTargetDate; i++) {
        // initialize data for day
        if (!dataForScenario[i] === true) {
          dataForScenario[i] = {
            date: getPlanningDateStringWithMonthlyInterval(new Date(), i)
          };
        }
        if (!dataForScenario[i].rules === true) {
          dataForScenario[i].rules = [];
        }
        dataForScenario[i].networth =
          dataForScenario[i].networth === undefined ? initialNetWorth : dataForScenario[i].networth;
        dataForScenario[i].assetsTotal =
          dataForScenario[i].assetsTotal === undefined ? initialAssetsTotal : dataForScenario[i].assetsTotal;
        dataForScenario[i].investableTotal =
          dataForScenario[i].investableTotal === undefined
            ? initialInvestableTotal
            : dataForScenario[i].investableTotal;
        dataForScenario[i].debtsTotal =
          dataForScenario[i].debtsTotal === undefined ? initialDebtsTotal : dataForScenario[i].debtsTotal;
        dataForScenario[i].cashTotal =
          dataForScenario[i].cashTotal === undefined ? initialCashTotal : dataForScenario[i].cashTotal;
        dataForScenario[i].workingCash =
          dataForScenario[i].workingCash === undefined
            ? i === 0
              ? initialCashTotal
              : dataForScenario[i - 1].cashTotal
            : dataForScenario[i].workingCash;

        // loop through all rules for the day
        for (let ruleIndex = 0; ruleIndex < scenarioRuleObjects.length; ruleIndex++) {
          const rule = scenarioRuleObjects[ruleIndex];
          const previousDateData = i === 0 ? null : dataForScenario[i - 1].rules;
          const previousDateChanges =
            !previousDateData === true
              ? { cumulativeDelta: 0 }
              : previousDateData.find(item => item.id === rule.id).changes;

          // solely for display purposes.
          const overridingDataPoints = rule.overridingDataPointsInit || [];

          const changesForRule = calculateCumulativeChangesForRule(
            rule,
            previousDateChanges,
            dataForScenario[i].date,
            i,
            {
              cashRule,
              allCashCustodians: new Set(cashOnHandMap.keys()),
              liquidationRule
            },
            scenarioRuleObjects,
            dataForScenario,
            db
          );
          var cumulativeDelta = changesForRule.cumulativeDelta;
          const totalTax = changesForRule.cumulativeTax || 0;
          const cdWithTax = cumulativeDelta + totalTax;
          const investableCumulativeDelta = (changesForRule.investableCumulativeDelta || 0) + totalTax;
          dataForScenario[i].workingCash = dataForScenario[i].workingCash + (changesForRule.cashDelta || 0);
          var cashCumulativeDelta =
            changesForRule.cashCumulativeDelta === undefined ? cumulativeDelta : changesForRule.cashCumulativeDelta; // tax is already added to cashCumulativeDelta
          const lqTotal = changesForRule.cumulativeLq || 0;
          dataForScenario[i].rules.push({
            id: rule.id,
            type: rule.type,
            changes: changesForRule
          });
          if (overridingDataPoints.length > 0 && i !== 0) {
            dataForScenario[i].rules[dataForScenario[i].rules.length - 1].overridingRules = overridingDataPoints;
          }
          if (rule.effect === planningRuleEffect.CHANGE_ASSET) {
            dataForScenario[i].networth = dataForScenario[i].networth + cdWithTax;
            dataForScenario[i].assetsTotal = dataForScenario[i].assetsTotal + cdWithTax;
            dataForScenario[i].investableTotal = dataForScenario[i].investableTotal + investableCumulativeDelta;
            dataForScenario[i].cashTotal = dataForScenario[i].cashTotal + cashCumulativeDelta + lqTotal;
          } else if (rule.effect === planningRuleEffect.INCREASE_ASSET_DECREASE_CASH) {
            // investableCD is being used instead of cashCD because cashCD becomes 0 when all cash runs out. in all other cases, cashCD = investableCD
            // this applies to all dual effect rules
            dataForScenario[i].cashTotal =
              dataForScenario[i].cashTotal - cashCumulativeDelta + (changesForRule.cashAssetInflow || 0) + lqTotal;
            dataForScenario[i].networth = dataForScenario[i].networth + cumulativeDelta + investableCumulativeDelta;
            dataForScenario[i].assetsTotal =
              dataForScenario[i].assetsTotal + cumulativeDelta + investableCumulativeDelta;
            dataForScenario[i].investableTotal =
              dataForScenario[i].investableTotal +
              investableCumulativeDelta +
              (changesForRule.investableAssetInflow || 0);
          } else if (rule.effect === planningRuleEffect.INCREASE_CASH_DECREASE_ASSET) {
            dataForScenario[i].cashTotal =
              dataForScenario[i].cashTotal + cashCumulativeDelta + (changesForRule.cashAssetInflow || 0) + lqTotal;
            dataForScenario[i].networth = dataForScenario[i].networth + investableCumulativeDelta + cumulativeDelta;
            dataForScenario[i].assetsTotal =
              dataForScenario[i].assetsTotal + investableCumulativeDelta + cumulativeDelta;
            dataForScenario[i].investableTotal =
              dataForScenario[i].investableTotal +
              investableCumulativeDelta +
              (changesForRule.investableAssetInflow || 0);
          } else if (rule.effect === planningRuleEffect.CHANGE_DEBT) {
            dataForScenario[i].networth = dataForScenario[i].networth - cumulativeDelta;
            dataForScenario[i].debtsTotal = dataForScenario[i].debtsTotal + cumulativeDelta;
          } else if (rule.effect === planningRuleEffect.DECREASE_DEBTS_CASH) {
            dataForScenario[i].debtsTotal = dataForScenario[i].debtsTotal + cumulativeDelta;
            dataForScenario[i].cashTotal = dataForScenario[i].cashTotal + cashCumulativeDelta + lqTotal;
            dataForScenario[i].assetsTotal = dataForScenario[i].assetsTotal + cumulativeDelta;
            dataForScenario[i].investableTotal = dataForScenario[i].investableTotal + cumulativeDelta;
          } else if (rule.effect === planningRuleEffect.INCREASE_DEBTS_CASH) {
            dataForScenario[i].debtsTotal = dataForScenario[i].debtsTotal + cumulativeDelta;
            dataForScenario[i].cashTotal = dataForScenario[i].cashTotal + cashCumulativeDelta + lqTotal;
            dataForScenario[i].assetsTotal = dataForScenario[i].assetsTotal + cumulativeDelta;
            dataForScenario[i].investableTotal = dataForScenario[i].investableTotal + cumulativeDelta;
          } else if (rule.effect === planningRuleEffect.INCREASE_CASH) {
            dataForScenario[i].networth = dataForScenario[i].networth + cdWithTax;
            dataForScenario[i].cashTotal = dataForScenario[i].cashTotal + cashCumulativeDelta + lqTotal;
            dataForScenario[i].assetsTotal = dataForScenario[i].assetsTotal + cdWithTax;
            dataForScenario[i].investableTotal = dataForScenario[i].investableTotal + cdWithTax;
          } else if (rule.effect === planningRuleEffect.DECREASE_CASH) {
            dataForScenario[i].networth = dataForScenario[i].networth + cumulativeDelta;
            dataForScenario[i].cashTotal = dataForScenario[i].cashTotal + cashCumulativeDelta + lqTotal;
            dataForScenario[i].assetsTotal = dataForScenario[i].assetsTotal + cumulativeDelta;
            dataForScenario[i].investableTotal = dataForScenario[i].investableTotal + cumulativeDelta;
          } else if (rule.effect === planningRuleEffect.CHANGE_NET_WORTH) {
            const { rawNetworth } = dataForScenario[i];
            dataForScenario[i].rawNetworth = rawNetworth === undefined ? dataForScenario[i].networth : rawNetworth;
            dataForScenario[i].networth = dataForScenario[i].networth + cumulativeDelta;
          }
        }
      }

      planningData.push({
        data: dataForScenario,
        currency: portfolio.currency,
        scenario: scenario,
        processedRules: scenarioRuleObjects.map(item => {
          const originalObj = clonedRules.find(rule => rule.id === item.id);
          // the above properties are what breakdown component is used to having
          return {
            ...originalObj,
            nodeKey: item.nodeKey,
            childInstanceIds: item.childInstanceIds,
            mainAssetId: item.mainAssetId,
            initialValue: item.initialValue,
            numCashAssets: item.numCashAssets
          };
          // have to pass stuff in one by one because it struggles to make a copy since there are functions in the object
        }),
        cashBlockId: cashRuleContainer.blockId,
        investableBlockId: investableRuleContainer.blockId
      });
    }

    onSuccess(planningData);
  };
};

// gets applicable asset types for rule 2
export const getNonEmptyAssetTypes = () => {
  const currentPortfolio = currentPortfolioSelector(store.getState());
  var assetTypes = { ...planningAssetTypes };
  var applicableAssetTypes = [];

  for (const assetTypeKey in assetTypes) {
    if (
      getAssetDataForFilter(
        store.getState(),
        currentPortfolio,
        filterTypes.ASSET_TYPE,
        planningAssetTypes[assetTypeKey].key
      )[0] > 0
    ) {
      applicableAssetTypes.push(planningAssetTypes[assetTypeKey]);
    }
  }
  return applicableAssetTypes;
};

const generateFilterForAssetClass = (assetClass, custodiansDataMap) => {
  switch (assetClass) {
    case planningAssetTypes.all.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return custodianDetails?.ctr === "Asset";
      };
    case planningAssetTypes.investable.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return custodianDetails?.ctr === "Asset" && (custodian.type === 0 || custodian.type === 2);
      };
    case planningAssetTypes.cash.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return custodianDetails?.ctr === "Asset" && custodian.type === 2;
      };
    case planningAssetTypes.stocks.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return custodianDetails?.aCls === "Stocks" || shouldBreakdownCustodian(custodianDetails);
      };
    case planningAssetTypes.bonds.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return custodianDetails?.aCls === "Bonds" || shouldBreakdownCustodian(custodianDetails);
      };
    case planningAssetTypes.funds.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return custodianDetails?.aCls === "Funds" && custodianDetails?.cntN !== "USA"; // funds label is now "Non US Funds"
      };
    case planningAssetTypes.metals.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return custodianDetails?.aCls === "Precious metals" || shouldBreakdownCustodian(custodianDetails);
      };
    case planningAssetTypes.investments.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return custodianDetails?.aCls === "Investments" && !custodian.holdingsCount;
      };
    case planningAssetTypes.crypto.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return (
          (custodianDetails?.oACls === "Crypto" || shouldBreakdownCustodian(custodianDetails)) &&
          !custodian.holdingsCount
        );
      };
    case planningAssetTypes.homes.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return (
          custodianDetails?.aCls === "Homes" ||
          custodianDetails?.aCls === "Real Estate" ||
          shouldBreakdownCustodian(custodianDetails)
        );
      };
    case planningAssetTypes.other.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return (
          !custodian.holdingsCount &&
          (custodianDetails?.aCls === "Others" || shouldBreakdownCustodian(custodianDetails))
        );
      };
    case planningAssetTypes.taxable.key:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        const taxDetails = custodian.taxDetails && JSON.parse(custodian.taxDetails);
        const taxableAssetType = taxDetails && taxDetails.taxableAssetType;
        return (
          custodianDetails?.ctr === "Asset" && (!taxableAssetType || taxableAssetType === custodianTaxTypes.TAXABLE)
        );
      };
    case planningAssetTypes.taxDeferred.key:
      return custodian => {
        const taxDetails = custodian.taxDetails && JSON.parse(custodian.taxDetails);
        const taxableAssetType = taxDetails && taxDetails.taxableAssetType;
        return taxableAssetType && taxableAssetType === custodianTaxTypes.TAX_DEFERRED;
      };
    case planningAssetTypes.taxFree.key:
      return custodian => {
        const taxDetails = custodian.taxDetails && JSON.parse(custodian.taxDetails);
        const taxableAssetType = taxDetails && taxDetails.taxableAssetType;
        return taxableAssetType && taxableAssetType === custodianTaxTypes.TAX_FREE;
      };
    default:
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return custodianDetails?.ctr === "Asset";
      };
  }
};

export const filterTypes = {
  ASSET_TYPE: "asset_type",
  CUSTODIAN: "custodian",
  TICKER: "ticker"
};

const generateAssetFilter = (portfolio, filterType, payload, custodiansDataMap) => {
  switch (filterType) {
    case filterTypes.ASSET_TYPE: {
      return generateFilterForAssetClass(payload, custodiansDataMap);
    }
    case filterTypes.CUSTODIAN: {
      const { isSheet, isSection, id } = payload;
      const basicCheck = custodian => custodian && (custodian.valueTickerId === 0 || custodian.valueTickerId); // instead of checking custodiansDataMap
      const sectionInSheet = memoize(
        (portfolio, sectionId) => {
          const section = portfolio.details.section.find(section => section.id === sectionId);
          return section?.sheetId === id;
        },
        (_, sectionId) => sectionId
      ); // rememoize every time this func is called just in case portfolio changes
      if (isSheet) {
        return custodian => {
          if (!basicCheck(custodian)) return false;
          return sectionInSheet(portfolio, custodian.sectionId);
        };
      } else if (isSection) {
        return custodian => {
          return basicCheck(custodian) && custodian.sectionId === id;
        };
      } else {
        return custodian => {
          return basicCheck(custodian) && (custodian.id === id || custodian.parentId === id);
        };
      }
    }
    case filterTypes.TICKER: {
      return custodian => {
        const custodianDetails = custodiansDataMap.get(custodian.id);
        return custodianDetails?.ctr === "Asset" && custodian.valueTickerId === payload;
      };
    }
  }
};

const custodiansWithFilterSelector = (state, portfolio, filter, assetsFilter, applyFilterToParent = false) => {
  // return a map of id to obj here to deal with multiple portfolios
  const linkedPortfolioIds = new Map();
  let startingCustodians = portfolio.details.custodian.filter(item => {
    if (item.ownership === undefined) return false; // usually junk custodians, also a required field
    const { linkType, linkProviderAccountId } = item;

    if (linkType === accountLinkingService.KUBERA_PORTFOLIO && portfolioSelector(state, linkProviderAccountId)) {
      // do not include linked portolio custodians, instead break them down later
      if (assetsFilter(item) && (!applyFilterToParent || filter(item))) {
        // if applyFilterToParent is true, apply filter now, otherwise apply it later
        linkedPortfolioIds.set(linkProviderAccountId, item.ownership);
        // only save asset ownership percentage
      }
      return false;
    }

    return filter(item);
  });

  for (const [id, ownership] of linkedPortfolioIds.entries()) {
    const linkedPortfolio = portfolioSelector(state, id);
    const childCustodians = custodiansWithFilterSelector(
      state,
      linkedPortfolio,
      applyFilterToParent ? assetsFilter : filter,
      assetsFilter
    ).map(custodian => {
      return {
        ...custodian,
        ownership: (custodian.ownership * ownership) / 100
      };
    });
    startingCustodians = startingCustodians.concat(childCustodians); // add child custodians
  }
  return startingCustodians;
};

export const getAssetDataForFilter = (state, portfolio, filterType, payload) =>
  getAssetDataForFilterMemoized(filterType, payload)(state, portfolio);

export const planningReady = state => {
  const portfolio = currentPortfolioSelector(state);
  return portfolio?.details?.networth?.data?.custodiansFundData?.length > 0;
};

const getAssetDataForFilterMemoized = memoize(
  (filterType, payload) => {
    const getMemoizedState = memoize(
      state => state,
      state => currentPortfolioCustodiansUpdatedTimestampSelector(state)
    );
    return createSelector(
      [
        getMemoizedState,
        (state, portfolio) => {
          if (!portfolio) portfolio = currentPortfolioSelector(state);
          return portfolio;
        },
        (state, portfolio) => {
          if (!portfolio) portfolio = currentPortfolioSelector(state);
          return portfolio?.details?.networth?.data?.custodiansFundData;
        }
      ],
      (state, portfolio, custodiansFundData) => {
        const fundData = custodiansFundData || [];
        const custodiansDataMap = new Map(fundData.map(item => [item.cid, item]));
        const myFilter = generateAssetFilter(portfolio, filterType, payload, custodiansDataMap);
        let myMap = new Map();
        switch (filterType) {
          case filterTypes.ASSET_TYPE:
            myMap = assetsMapForFilter(state, portfolio, myFilter, custodiansDataMap, payload);
            break;
          case filterTypes.CUSTODIAN:
            myMap = assetsMapForFilter(state, portfolio, myFilter, custodiansDataMap, null, true);
            break;
          case filterTypes.TICKER:
            myMap = assetsMapForFilter(state, portfolio, myFilter, custodiansDataMap);
            break;
        }
        return [findSetTotalFromSourceMap(myMap.keys(), myMap), myMap];
      }
    );
  },
  (filterType, payload) => `${filterType}-${JSON.stringify(payload)}`
);

const assetClassToFundKey = {
  [planningAssetTypes.stocks.key]: ["usStock", "nonUsStock"],
  [planningAssetTypes.bonds.key]: ["bond"],
  [planningAssetTypes.crypto.key]: ["crypto"],
  [planningAssetTypes.homes.key]: ["realty"],
  [planningAssetTypes.metals.key]: ["metal"],
  [planningAssetTypes.other.key]: []
};

const shouldBreakdownCustodian = custodianDetails =>
  custodianDetails?.aCls === "Funds" && custodianDetails?.cntN === "USA" && custodianDetails?.adlM;

const breakdownFund = custodianDetails => {
  const assetAllocationForFund = custodianDetails.adlM.assetAllocation || {};
  let equityRemaining = 100;
  const equityPercentageObj = {};

  for (const [assetClass, allocKeys] of Object.entries(assetClassToFundKey)) {
    for (const allocKey of allocKeys) {
      const valueObj = assetAllocationForFund[allocKey];
      if (!valueObj) continue;
      const value = valueObj.long - valueObj.short;
      if (value === 0) continue;
      equityPercentageObj[assetClass] = (equityPercentageObj[assetClass] || 0) + value;
      equityRemaining -= value;
    }
  }
  equityPercentageObj[planningAssetTypes.other.key] = equityRemaining;
  return equityPercentageObj;
};

const assetsMapForFilter = (
  state,
  portfolio,
  filter,
  custodiansDataMap,
  assetType = null,
  filterLinkedPortfoliosAtParentLevel = false
) => {
  const filteredCustodians = custodiansWithFilterSelector(
    state,
    portfolio,
    filter,
    generateAssetFilter(portfolio, filterTypes.ASSET_TYPE, planningAssetTypes.all.key, custodiansDataMap),
    filterLinkedPortfoliosAtParentLevel
  );
  const category = categoryType.ASSET;
  const idValueMap = new Map();
  filteredCustodians.forEach(temp => {
    const value = getCustodianValue(temp, category, getTickerUsingShortName(portfolio.currency), false);
    const total = getCustodianValue(temp, category, getTickerUsingShortName.bind(state)(portfolio.currency));

    const addToMap = (suffix, coeff) => {
      if (!coeff) return;
      const myKey = suffix ? getSuffixKey(temp.id, suffix) : temp.id;
      let finalValue = (value * coeff) / 100;
      let finalTotal = (total * coeff) / 100;
      const existingValue = idValueMap.get(myKey);
      if (existingValue) {
        finalValue += existingValue.value;
        finalTotal += existingValue.total;
        // custodian is from the same linked portfolio
      }
      idValueMap.set(myKey, {
        value: finalValue,
        total: finalTotal,
        valueTickerId: temp.valueTickerId
      });
    };

    const custodianDetails = custodiansDataMap.get(temp.id);
    if (shouldBreakdownCustodian(custodianDetails)) {
      // dealing with funds that have multiple asset classes. should break down to each asset class
      const breakdown = breakdownFund(custodianDetails);
      if (assetType && assetClassToFundKey[assetType]) {
        addToMap(assetType, breakdown[assetType]);
        // return the specific component of the fund
        return;
      }
      for (const [assetClass, coeff] of Object.entries(breakdown)) {
        addToMap(assetClass, coeff);
        // return broken down custodian
      }
      return;
    }
    addToMap(null, 100);
  });
  reconcileParentChildAssets(idValueMap);
  return idValueMap;
};

// used in breakdown component to display rule changes
export const getRuleNodeKey = ruleObject => {
  if (ruleObject && ruleObject.nodeKey) {
    return ruleObject.nodeKey;
  }
  if (ruleObject.effect === planningRuleEffect.CHANGE_NET_WORTH) {
    return ruleNodeKeys.NET_WORTH;
  }
  if (ruleObject.category === planningRuleCategories.INCOME) {
    return ruleNodeKeys.INCOME;
  }
  if (ruleObject.effect === planningRuleEffect.INCREASE_CASH) {
    return ruleNodeKeys.CASH_INFLOW;
  }
  if (ruleObject.category === planningRuleCategories.EXPENSE) {
    return ruleNodeKeys.EXPENSE;
  }
  if (ruleObject.effect === planningRuleEffect.DECREASE_CASH) {
    return ruleNodeKeys.CASH_OUTFLOW;
  }
  if (!ruleObject.data[planningVariables.ASSET_TYPE] === false) {
    return planningAssetTypes[ruleObject.data[planningVariables.ASSET_TYPE].value].label;
  }
  if (!ruleObject.data[planningVariables.DEBT_TYPE] === false) {
    return planningDebtTypes[ruleObject.data[planningVariables.DEBT_TYPE].value].label;
  }
  if (!ruleObject.data[planningVariables.TICKER_ID] === false) {
    const ticker = getTickerUsingId(ruleObject.data[planningVariables.TICKER_ID].items[0]);
    return `${ticker.name} (${ticker.code})`;
  }
  if (!ruleObject.data[planningVariables.ASSET_ID] === false) {
    const custodianItem = getCustodianItems(ruleObject.data[planningVariables.ASSET_ID].items)[0];
    return `${custodianItem.name}`;
  }
  if (!ruleObject.data[planningVariables.DEBT_ID] === false) {
    const custodianItems = getCustodianItems(ruleObject.data[planningVariables.DEBT_ID].items);
    return `${custodianItems[0].name}`;
  }
  if (!ruleObject.data[planningVariables.NEW_ASSET] === false) {
    return ruleObject.data[planningVariables.NEW_ASSET].value;
  }
  if (!ruleObject.data[planningVariables.NEW_DEBT] === false) {
    return ruleObject.data[planningVariables.NEW_DEBT].value;
  }
  return null;
};

const reconcileParentChildAssets = myMap => {
  for (const key of myMap.keys()) {
    const truncatedKey = key.split("_")[0];
    if (myMap.get(truncatedKey) && truncatedKey !== key) {
      myMap.delete(truncatedKey);
    }
  }
};

const getRuleInitialCustodians = (state, portfolio, rule, newCustodianMap, initialCashOnHandMap) => {
  const taxKeyArray = () => (rule.hasTax ? [planningAssetTypes.taxable.key] : []);
  const liquidatesKeyArray = () => (rule.hasLiquidates ? [liquidates] : []);

  const addArtificialCashToMap = (map, extraSuffixes = []) =>
    addSuffixesToMap(map, [planningAssetTypes.cash.key, planningAssetTypes.investable.key, ...extraSuffixes]);

  const addSuffixesToMap = (map, suffixes) => {
    for (const suffix of suffixes) {
      const expectedKey = getSuffixKey(rule.id, suffix);
      map.set(expectedKey, newCustodianMap.get(expectedKey));
    }
    return map;
  };
  const callFilterFunc = (filterType, payload) =>
    new Map(getAssetDataForFilter(state, portfolio, filterType, payload)[1]);
  switch (rule.type) {
    case "rule_0": {
      const assetCustodians = new Map();
      rule.numCustodians = 0;
      for (const [key, value] of newCustodianMap.entries()) {
        if (value.isLiquidates) {
          assetCustodians.set(key, value);
        }
      }
      return assetCustodians;
    }
    case "rule_1": {
      const assetCustodians = callFilterFunc(filterTypes.ASSET_TYPE, planningAssetTypes.all.key);
      rule.numCustodians = assetCustodians.size;
      for (const [key, value] of newCustodianMap.entries()) {
        if (!value.isLiquidates && value.isAsset) {
          assetCustodians.set(key, value);
        }
      }
      return addSuffixesToMap(assetCustodians, liquidatesKeyArray());
    }
    case "rule_2":
    case "rule_27": {
      const assetClass =
        rule.type === "rule_27" ? planningAssetTypes.investable.key : rule.data[planningVariables.ASSET_TYPE].value;
      // rule_27 is withdraws a % of investable assets
      const assetCustodians = callFilterFunc(filterTypes.ASSET_TYPE, assetClass);
      rule.numCustodians = assetCustodians.size;
      for (const [key, value] of newCustodianMap.entries()) {
        if (
          !value.isLiquidates &&
          value.isAsset &&
          (value.assetClass === assetClass || (assetClass === planningAssetTypes.investable.key && value.isInvestable))
        ) {
          assetCustodians.set(key, value);
        }
      }
      return addSuffixesToMap(assetCustodians, liquidatesKeyArray());
    }
    case "rule_4":
    case "rule_5": {
      const tickerId = rule.data[planningVariables.TICKER_ID].items[0];
      const myCustodians = callFilterFunc(filterTypes.TICKER, tickerId);
      rule.numCustodians = myCustodians.size;
      for (const [key, value] of newCustodianMap.entries()) {
        if (!value.isLiquidates && value.valueTickerId === tickerId) {
          myCustodians.set(key, value);
        }
      }
      for (const s of liquidatesKeyArray()) {
        const key = getSuffixKey(rule.id, s);
        const obj = newCustodianMap.get(key);
        obj.valueTickerId = tickerId;
        myCustodians.set(key, obj); // liquidates asset should have same ticker for proper calculation
      }

      return addSuffixesToMap(myCustodians, taxKeyArray());
    }
    case "rule_3":
    case "rule_28": {
      const custodians = callFilterFunc(filterTypes.CUSTODIAN, rule.data[planningVariables.ASSET_ID].items[0]);
      rule.numCustodians = custodians.size;
      return addSuffixesToMap(custodians, liquidatesKeyArray());
    }
    case "rule_8":
    case "rule_33": {
      rule.numCustodians = 0;
      return addArtificialCashToMap(new Map(), [planningVariables.NEW_ASSET]);
    }
    case "rule_29": {
      const custodianMap = new Map();
      rule.numCustodians = 0;
      const expectedKey = getSuffixKey(rule.id, planningVariables.NEW_ASSET);
      custodianMap.set(expectedKey, newCustodianMap.get(expectedKey));
      return addArtificialCashToMap(custodianMap, taxKeyArray());
    }
    case "rule_12":
    case "rule_14":
    case "rule_30": {
      let debtCustodianItems = portfolioTotalForCategory(state, portfolio, categoryType.DEBT, false, true)[1];
      rule.numCustodians = debtCustodianItems.size;
      for (const [key, value] of newCustodianMap.entries()) {
        if (!value.isLiquidates && !value.isAsset) {
          debtCustodianItems.set(key, value);
        }
      }
      return rule.type === "rule_12" ? debtCustodianItems : addArtificialCashToMap(debtCustodianItems);
    }
    case "rule_13":
    case "rule_15": {
      const custodians = getCustodianItems(rule.data[planningVariables.DEBT_ID].items, false, true); // memoize in the future
      rule.numCustodians = custodians.size;
      return rule.type === "rule_13" ? custodians : addArtificialCashToMap(custodians);
    }

    case "rule_16": {
      rule.numCustodians = 0;
      return addArtificialCashToMap(new Map(), [planningVariables.NEW_DEBT]);
    }
    case "rule_17":
    case "rule_20":
    case "rule_21":
    case "rule_24":
    case "rule_25":
    case "rule_31": {
      rule.numCustodians = 0;
      return addArtificialCashToMap(new Map(), taxKeyArray());
    }
    case "rule_9":
    case "rule_11":
    case "rule_18":
    case "rule_19":
    case "rule_22":
    case "rule_23":
    case "rule_6":
    case "rule_10": {
      const custodians = callFilterFunc(filterTypes.CUSTODIAN, rule.data[planningVariables.ASSET_ID].items[0]);
      rule.numCustodians = custodians.size;
      return addArtificialCashToMap(custodians, taxKeyArray());
    }
    case "rule_7": {
      const { isCustodian, id } = rule.data[planningVariables.ASSET_ID].items[0];
      const custodians = callFilterFunc(filterTypes.CUSTODIAN, rule.data[planningVariables.ASSET_ID].items[0]);
      rule.numCustodians = custodians.size;
      if (isCustodian && custodians.size > 1) {
        // checking if the asset is a custodian
        for (const key of custodians.keys()) {
          if (initialCashOnHandMap.has(key) && key.includes("_") && key.split("_")[0] === id) {
            custodians.delete(key); // don't add cash to cash holdings
          }
        }
      }

      return addArtificialCashToMap(custodians, taxKeyArray());
    }
    default:
      rule.numCustodians = 0;
      return new Map();
  }
};

export const getCustodianItems = (custodianItems, onlyInvestable = false, asMap = false) => {
  const retArray = [];
  const idValueMap = new Map();
  const currentPortfolio = currentPortfolioSelector(store.getState());
  for (const item of custodianItems) {
    var entry = { ...item };
    if (entry.isCustodian === true) {
      const custodian = custodianSelector(store.getState(), entry.id);

      if (custodian) {
        entry = { ...entry, ...custodian };

        entry.section = sectionSelector(store.getState(), entry.sectionId);
        entry.sheet = sheetSelector(store.getState(), entry.section.sheetId);
        entry.total = getCustodianValue(
          custodian,
          entry.sheet.category,
          getTickerUsingShortName(currentPortfolio.currency)
        );
        if (asMap) {
          idValueMap.set(entry.id, entry);
        } else {
          retArray.push(entry);
        }
      }
    } else if (entry.isSection) {
      const section = sectionSelector(store.getState(), entry.id);

      if (section) {
        if (!asMap) {
          entry = { ...entry, ...section };
          entry.sheet = sheetSelector(store.getState(), entry.sheetId);
          entry.total = getTotalForSection(store.getState(), currentPortfolio, section, onlyInvestable);
          retArray.push(entry);
        } else {
          const sectionItemsMap = getTotalForSection(
            store.getState(),
            currentPortfolio,
            section,
            onlyInvestable,
            true
          )[1];
          sectionItemsMap.forEach((value, key) => {
            idValueMap.set(key, value);
          });
        }
      }
    } else if (entry.isSheet) {
      const sheet = sheetSelector(store.getState(), entry.id);

      if (sheet) {
        if (!asMap) {
          entry = { ...entry, ...sheet };
          entry.total = getTotalForSheet(store.getState(), currentPortfolio, sheet, onlyInvestable);
          retArray.push(entry);
        } else {
          const sheetItemMap = getTotalForSheet(store.getState(), currentPortfolio, sheet, onlyInvestable, true)[1];
          sheetItemMap.forEach((value, key) => {
            idValueMap.set(key, value);
          });
        }
      }
    }
  }
  return asMap ? idValueMap : retArray.filter(item => item !== null);
};

export const getCurrentValueForCustodianItems = (items, onlyInvestable = false) => {
  const custodianItems = getCustodianItems(items, onlyInvestable);
  return custodianItems.reduce((total, item) => {
    return total + item.total;
  }, 0);
};

// checks if each data key is valid in a rule
const isRuleDataValid = rule => {
  for (const variableKey of Object.keys(rule.data)) {
    if (isVariableDataValid(variableKey, rule.data[variableKey]) === false) {
      return false;
    }
  }
  return true;
};

const isVariableDataValid = (variableKey, variableData) => {
  if (!variableData === true) {
    return false;
  }

  switch (variableKey) {
    case planningVariables.REVISED_PERCENTAGE:
    case planningVariables.PERCENTAGE:
    case planningVariables.QUANTITY:
    case planningVariables.VESTING_SCHEDULE:
    case planningVariables.MONTHS:
    case planningVariables.GROWTH_RATE:
    case planningVariables.RATE_PER_YEAR_WITH_TAX:
    case planningVariables.RATE_PER_YEAR: {
      return !variableData.value === false || variableData.value === 0;
    }
    case planningVariables.DEBT_TYPE:
    case planningVariables.ASSET_TYPE: {
      return !variableData.value === false;
    }
    case planningVariables.DEBT_ID:
    case planningVariables.ASSET_ID: {
      return (
        !variableData.items === false &&
        variableData.items.length > 0 &&
        getCustodianItems(variableData.items).length > 0
      );
    }
    case planningVariables.TICKER_ID: {
      return !variableData.items === false && variableData.items.length > 0;
    }
    case planningVariables.COST_WITH_TAX:
    case planningVariables.AMOUNT:
    case planningVariables.AMOUNT_WITH_TAX:
    case planningVariables.EXPECTED_AMOUNT: {
      return variableData.value !== null && variableData.value !== undefined && !variableData.tickerId === false;
    }
    case planningVariables.DATE_AGE:
    case planningVariables.DATE_AGE_REVISED:
    case planningVariables.DATE_AGE_YEAR: {
      return !variableData.date === false;
    }
    case planningVariables.MULTIPLE_DATES: {
      return !variableData.dates === false;
    }
    case planningVariables.NEW_EXPENSE:
    case planningVariables.NEW_INCOME:
    case planningVariables.NEW_DEBT:
    case planningVariables.META:
    case planningVariables.NEW_ASSET:
    case planningVariables.TICKER_NAME: {
      return !variableData.value === false;
    }
    case planningVariables.REPEAT: {
      return !variableData.frequency === false;
    }
  }
  return false;
};

// 1, 2, 3, 4, 5, 7, 28, 12, 13, 15
const calculateCumulativeChangesForRule = (
  rule,
  previousDateChanges,
  dateString,
  monthsPassed,
  cashRuleObjs,
  scenarioRuleObjects,
  dataForScenario,
  db
) => {
  var cumulativeDelta = 0;
  if (monthsPassed === 0) {
    return { cumulativeDelta: cumulativeDelta };
  }
  var cashCumulativeDelta = null;
  var investableCumulativeDelta = null;
  var cumulativeTax = null;
  var cumulativeInterestPayment = null;
  var cumulativeLq = previousDateChanges.cumulativeLq || 0;
  var amountForDay = null;
  var parentContainerMap = null;
  var cashAssetInflow = null;
  var investableAssetInflow = null;
  var subtext = null;

  // initially, this did not apply to a lot of rules so they were functions. this is no longer the case
  const cashAssetSet = new Set([getSuffixKey(rule.id, planningAssetTypes.cash.key)]);
  const investableAssetSet = new Set([getSuffixKey(rule.id, planningAssetTypes.investable.key)]);
  const taxAssetSet = new Set([getSuffixKey(rule.id, planningAssetTypes.taxable.key)]);
  const liquidatesAssetSet = new Set([getSuffixKey(rule.id, liquidates)]);
  const artificalAssets = new Set([...cashAssetSet, ...investableAssetSet, ...taxAssetSet, ...liquidatesAssetSet]);

  const getActiveAssets = () => {
    if (!rule.custodians) {
      return 0;
    }
    return [...rule.custodians].reduce(
      (count, asset) =>
        count + (db.has(asset) && !(db.get(asset).ignore || artificalAssets.has(asset)) ? db.get(asset).assetSize : 0),
      0
    );
  };

  const totalCash = dataForScenario[monthsPassed].workingCash;

  const stopRuleIfNeeded = cashDelta => {
    // if cash runs out during or before the execution of this rule, stop is completely instead of breaking up the contribution amount
    if (totalCash + cashDelta >= 0) return false;

    db.get(rule.id).stop = true;
    subtext = `This rule stopped in ${getMonthAndYearFromDate(
      parseKuberaDateString(dateString)
    )} due to insufficient cash`;
    return true;
  };
  const assetChangeType = {
    ADD_CONTRIBUTION: "ADD_CONTRIBUTION", // delta should be a static amount
    ADD_INTEREST: "ADD_INTEREST", // delta should be an interest rate
    TICKER_PRICE_CHANGE: "TICKER_PRICE_CHANGE", // delta should be a static change in the ticker price
    REPAY_DEBT: "REPAY_DEBT", // delta should be a positive static amount. needs to be handled separately from add contribution because the contribution amount is depedant on the current value
    REPAY_DEBT_PERCENTAGE: "REPAY_DEBT_PERCENTAGE",
    SELL: "SELL", // no delta required
    ACTIVATE: "ACTIVATE", // used when a future buy date is reached
    ADD_QUANTITY: "ADD_QUANTITY"
  };

  // goes through custodians, applies necessary changes and tracks the deltas
  const modifyCustodians = (changeType, delta, custodianSet = rule.custodians, ignoreArtificialAsset = true) => {
    let relativeChange = 0;
    let relativeInvestableChange = 0;
    let relativeCashChange = 0;
    let interestPayment = 0;
    if (custodianSet) {
      let totalContribution = delta;

      for (const currCust of custodianSet) {
        let localCD = 0;
        const valueInfo = db.get(currCust);
        if (!valueInfo || valueInfo.ignore || (ignoreArtificialAsset && valueInfo.isArtificialAsset)) {
          // an artificial cash asset like cash outflow for contributes rule should be considered separately from the rest of the assets
          if (valueInfo && changeType === assetChangeType.ACTIVATE && valueInfo.ignore) {
            valueInfo.ignore = false;
            localCD = valueInfo.total;
          } else {
            continue;
          }
        }
        let currentPrice = (valueInfo && valueInfo.tickerPrice) || 1;
        let totalValue = valueInfo.value;
        let interest = valueInfo?.interest || 0;

        if (changeType === assetChangeType.ADD_CONTRIBUTION) {
          const newQuantity = (delta * valueInfo.assetSize) / (delta === Number.MIN_VALUE ? 1 : currentPrice); // Number.MIN_VALUE is used as a magic number to show a breakdown row even if the change is 0
          totalValue += newQuantity;
          localCD = newQuantity * currentPrice;
        } else if (changeType === assetChangeType.ADD_INTEREST) {
          const ignore = !valueInfo.isArtificialAsset && (delta > 0 && valueInfo.total < 0); // do not compound negative cash margin balance
          const cmp = calculateCompoundedChangeFromPreviousDay(
            ignore ? 0 : delta,
            totalValue,
            totalValue,
            monthsPassed
          );
          const edgeCase = valueInfo.isArtificialAsset && delta < 0 && valueInfo.total < 0; // if liquidating a container with negative growth, the compound change should be positive
          totalValue =
            !edgeCase && (delta < 0 || valueInfo.total < 0) ? Math.min(cmp, totalValue) : Math.max(cmp, totalValue);
          localCD = (totalValue - valueInfo.value) * currentPrice;
          interest += localCD;
        } else if (changeType === assetChangeType.TICKER_PRICE_CHANGE) {
          const actualDelta = currentPrice + delta <= 0 ? -currentPrice : delta;
          currentPrice += actualDelta;
          localCD = valueInfo?.tickerPrice === 0 ? 0 : totalValue * actualDelta; // having a tickerPrice of 0 can be problematic
        } else if (changeType === assetChangeType.REPAY_DEBT) {
          const actualChange = Math.min(totalContribution, valueInfo.total); // don't overpay debt
          totalValue -= actualChange / currentPrice; // value is named as quantity
          totalContribution -= actualChange;
          localCD = -actualChange;
          interestPayment = Math.min(actualChange, interest);
          interest -= interestPayment;
        } else if (changeType === assetChangeType.REPAY_DEBT_PERCENTAGE) {
          const actualChange = delta * valueInfo.total;
          totalValue -= actualChange / currentPrice;
          localCD = -actualChange;
          interestPayment = Math.min(actualChange, interest);
          interest -= interestPayment;
        } else if (changeType === assetChangeType.SELL) {
          valueInfo.ignore = true;
          localCD = totalValue ? -totalValue * currentPrice : Number.MIN_VALUE; // edge case where you're selling a cash asset which was depleted due to cash running out
        } else if (changeType === assetChangeType.ADD_QUANTITY) {
          totalValue += delta;
          localCD = delta * currentPrice;
        }
        db.set(currCust, {
          ...valueInfo,
          value: totalValue,
          tickerPrice: currentPrice,
          total: totalValue * currentPrice,
          interest
        });
        relativeChange += localCD;
        if (rule.investableCustodians && rule.investableCustodians.has(currCust)) relativeInvestableChange += localCD;
        if (rule.cashCustodians && rule.cashCustodians.has(currCust)) relativeCashChange += localCD;
      }
    }
    return { relativeChange, relativeInvestableChange, relativeCashChange, interestPayment };
  };

  const applyRuleChanges = (changeType, delta, ignoreArtificialAsset = true) => {
    const pCMap =
      previousDateChanges.parentContainerMap && previousDateChanges.parentContainerMap.size
        ? new Map([...previousDateChanges.parentContainerMap].map(([key, value]) => [key, { ...value }]))
        : new Map();
    const cumulativeDeltaObj = {
      cumulativeDelta: 0,
      investableCumulativeDelta: 0,
      cashCumulativeDelta: 0,
      interestPayment: 0
    };
    for (const [parentInstanceId, custodianSet] of rule.parentContainerMap) {
      const { relativeChange, relativeInvestableChange, relativeCashChange, interestPayment } = modifyCustodians(
        changeType,
        delta,
        custodianSet,
        ignoreArtificialAsset
      );

      const pCObj = pCMap.get(parentInstanceId) || {
        cumulativeDelta: 0,
        investableCumulativeDelta: 0,
        cashCumulativeDelta: 0
      };

      const dbPCObj = db.get(getDbPCKey(parentInstanceId));

      const addNumToObjsKey = (key, num) => {
        pCObj[key] += num;
        cumulativeDeltaObj[key] += num;
        if (dbPCObj) dbPCObj[key] += num;
      };
      addNumToObjsKey("cumulativeDelta", relativeChange);
      addNumToObjsKey("investableCumulativeDelta", relativeInvestableChange);
      addNumToObjsKey("cashCumulativeDelta", relativeCashChange);
      cumulativeDeltaObj.interestPayment += interestPayment;

      pCMap.set(parentInstanceId, pCObj);
    }
    parentContainerMap = pCMap;
    return cumulativeDeltaObj;
  };

  const setPCRuleCDs = (changeType, delta, ignoreArtificialAsset = true) => {
    const changes = applyRuleChanges(changeType, delta, ignoreArtificialAsset);
    cumulativeDelta = (previousDateChanges.cumulativeDelta || 0) + changes.cumulativeDelta;
    investableCumulativeDelta =
      (previousDateChanges.investableCumulativeDelta || 0) + changes.investableCumulativeDelta;
    cashCumulativeDelta = (previousDateChanges.cashCumulativeDelta || 0) + changes.cashCumulativeDelta;
  };

  // specialized function only for updating value of a rule's artificial cashAsset and liquidating if required
  const addCashChange = (contribution, isTaxChange = false) => {
    const { relativeChange } = modifyCustodians(
      assetChangeType.ADD_CONTRIBUTION,
      contribution,
      isTaxChange ? taxAssetSet : cashAssetSet,
      false
    );
    const cashBlockId = db.get(cashContainerKey);
    const key = isTaxChange ? getSuffixKey(cashBlockId, planningAssetTypes.taxable.key) : cashBlockId;
    const oldObj = parentContainerMap.get(key) || { cumulativeDelta: 0 };
    const effectOne = oldObj.cumulativeDelta - (previousDateChanges.parentContainerMap?.get(key)?.cumulativeDelta || 0);
    oldObj.cumulativeDelta += relativeChange;
    parentContainerMap.set(key, oldObj);
    const incAssetDecCash =
      rule.effect === planningRuleEffect.INCREASE_ASSET_DECREASE_CASH && effectOne > 0 && relativeChange < 0;
    const decAssetIncCash =
      rule.effect === planningRuleEffect.INCREASE_CASH_DECREASE_ASSET && effectOne < 0 && relativeChange > 0;
    // if there are two calculations for the same rule, save both numbers separately for additional context
    if (!isTaxChange && (incAssetDecCash || decAssetIncCash)) {
      const updateSplitBreakdown = val => {
        const myKey = getSplitBreakdownKey(key, val, rule.effect);
        const oldObj = parentContainerMap.get(myKey) || { cumulativeDelta: 0 };
        oldObj.cumulativeDelta += val;
        parentContainerMap.set(myKey, oldObj);
      };
      updateSplitBreakdown(effectOne);
      updateSplitBreakdown(relativeChange);
    }

    const todaysInflow = isTaxChange
      ? cashCumulativeDelta
      : (cashAssetInflow || 0) - (previousDateChanges.cashAssetInflow || 0);
    const balance = totalCash + contribution + todaysInflow;
    if (contribution < 0 && balance <= 0) {
      // liquidate investable assets and drain out all cash
      const lq = cashRuleObjs.liquidationRule;

      let totalInvestable = 0;
      const dist = {};
      for (const id of lq.containerIds) {
        const val = db.get(getDbPCKey(id)).investableCumulativeDelta;
        const finalVal = val < 0 ? 0 : val; // if container is negative, it can't be liquidated
        dist[id] = finalVal;
        totalInvestable += finalVal;
      }

      const lqAmt = totalInvestable + balance >= 0 ? balance : -totalInvestable;
      if (totalInvestable <= 0) {
        db.set(noMoreInvestableKey, true);
        totalInvestable = 1; // avoid divide by 0
      }

      const lqChangeObj = dataForScenario[monthsPassed].rules[0].changes;
      const cashBlockId = cashRuleObjs.cashRule.id;
      for (const id of lq.containerIds) {
        const myAmt = (lqAmt * dist[id]) / totalInvestable;
        if (myAmt === 0) continue;
        const { relativeChange } = modifyCustodians(
          assetChangeType.ADD_CONTRIBUTION,
          myAmt,
          [getSuffixKey(id, liquidates)],
          false
        );
        const dbPCObj = db.get(getDbPCKey(id));
        dbPCObj.cumulativeDelta += relativeChange;
        dbPCObj.investableCumulativeDelta += relativeChange; // no need to update cashCumulativeDelta since it is not used
        const targetObj = lqChangeObj.parentContainerMap.get(id);
        targetObj.cumulativeDelta += relativeChange;
        modifyCustodians(assetChangeType.ADD_CONTRIBUTION, -relativeChange, lq.cashOutputSet, false); // positive cash change
        const cashAssetObj = lqChangeObj.parentContainerMap.get(cashBlockId);
        cashAssetObj.cumulativeDelta -= relativeChange;
        lqChangeObj.cashCumulativeDelta -= relativeChange;
        cumulativeLq -= relativeChange;
      }
      modifyCustodians(assetChangeType.REPAY_DEBT_PERCENTAGE, 1, cashRuleObjs.allCashCustodians, false);
    }

    if (isTaxChange) {
      cumulativeTax = (previousDateChanges.cumulativeTax || 0) + contribution;
      cashCumulativeDelta += (rule.effect === planningRuleEffect.INCREASE_ASSET_DECREASE_CASH ? -1 : 1) * contribution;
    }
    return contribution;
  };

  const addTaxChange = (amt, amtData) => addCashChange(calculateTax(amt, amtData), true);

  const executeIncomeRule = (amt, taxData) => {
    executeCashRule(amt);
    addTaxChange(amt, taxData);
  };

  const updateRepaymentCDs = relChangeObj => {
    // these values are not equal when all cash runs out. cumulativeDelta will be changing but cashCumulativeDelta will be reset by addCashChange
    const amt = relChangeObj.cumulativeDelta;
    cumulativeDelta = (previousDateChanges.cumulativeDelta || 0) + amt;
    cashCumulativeDelta = (previousDateChanges.cashCumulativeDelta || 0) + amt;
    cumulativeInterestPayment =
      (previousDateChanges.cumulativeInterestPayment || 0) + (relChangeObj.interestPayment || 0);
    addCashChange(amt);
  };

  const executeContributionRule = (contribution, cashCost) => {
    const numActiveAssets = getActiveAssets();
    const relativeChanges = applyRuleChanges(
      assetChangeType.ADD_CONTRIBUTION,
      contribution / (contribution === Number.MIN_VALUE || numActiveAssets === 0 ? 1 : numActiveAssets) // avoid divide by 0 and make sure Number.MIN_VALUE does not become 0
    );
    updateContributionRuleCDs(relativeChanges, cashCost);
  };

  const updateContributionRuleCDs = (relativeChanges, cashCost) => {
    cumulativeDelta = (previousDateChanges.cumulativeDelta || 0) + relativeChanges.cumulativeDelta;
    const actualCost = relativeChanges.cumulativeDelta && cashCost; // relativeChange could be 0 because asset is sold
    investableCumulativeDelta = (previousDateChanges.investableCumulativeDelta || 0) - actualCost;
    cashCumulativeDelta = (previousDateChanges.cashCumulativeDelta || 0) + actualCost;
    cashAssetInflow = (previousDateChanges.cashAssetInflow || 0) + relativeChanges.cashCumulativeDelta;
    investableAssetInflow =
      (previousDateChanges.investableAssetInflow || 0) + relativeChanges.investableCumulativeDelta;
    addCashChange(-actualCost);
  };

  const executeCashRule = contribution => {
    parentContainerMap =
      previousDateChanges.parentContainerMap && previousDateChanges.parentContainerMap.size
        ? new Map([...previousDateChanges.parentContainerMap].map(([key, value]) => [key, { ...value }]))
        : new Map();
    const relativeChange = addCashChange(contribution);
    cumulativeDelta = (previousDateChanges.cumulativeDelta || 0) + relativeChange;
    cashCumulativeDelta = (previousDateChanges.cashCumulativeDelta || 0) + relativeChange;
  };

  const getUnitsAndCost = (perLotUnit, perLotCost) => {
    let units = 0;
    let cost = 0;

    const currDateObj = parseKuberaDateString(dateString);
    if (currDateObj.getTime() >= rule.startDate.getTime() && currDateObj.getTime() <= rule.endDate.getTime()) {
      // is in vesting window
      if (currDateObj.getTime() >= rule.endCliffDate) {
        if (!rule.cliffReleased) {
          const numUnits = Math.floor(rule.cliffMonths / rule.frequency);
          units += perLotUnit * numUnits;
          cost += perLotCost * numUnits;
          rule.cliffReleased = true;
        } else if (rule.monthsPassed >= rule.frequency && rule.monthsPassed % rule.frequency === 0) {
          units += perLotUnit;
          cost += perLotCost;
        }
      }
      rule.monthsPassed++;
    }
    return { units, cost };
  };
  switch (rule.type) {
    case "rule_0": {
      cashCumulativeDelta = previousDateChanges.cashCumulativeDelta || 0;
      parentContainerMap = new Map();
      const cashBlockId = cashRuleObjs.cashRule.id;
      parentContainerMap.set(cashBlockId, {
        cumulativeDelta: previousDateChanges.parentContainerMap?.get(cashBlockId)?.cumulativeDelta || 0
      });
      for (const id of rule.containerIds) {
        parentContainerMap.set(id, {
          cumulativeDelta: previousDateChanges.parentContainerMap?.get(id)?.cumulativeDelta || 0
        });
      } // initialize parentContainerMap, it will be updated by different rules in addCashChange
      break;
    }
    case "rule_1":
    case "rule_2":
    case "rule_28": {
      const rate = rule.data[planningVariables.GROWTH_RATE].value;
      setPCRuleCDs(assetChangeType.ADD_INTEREST, rate, false);
      break;
    }
    case "rule_5":
      const rateData = rule.data[planningVariables.RATE_PER_YEAR_WITH_TAX];
      setPCRuleCDs(assetChangeType.ADD_INTEREST, rateData.value);
      addTaxChange(cumulativeDelta - (previousDateChanges.cumulativeDelta || 0), rateData);
      break;
    case "rule_12":
    case "rule_13":
      setPCRuleCDs(assetChangeType.ADD_INTEREST, rule.data[planningVariables.RATE_PER_YEAR].value);
      break;
    case "rule_3": {
      const { date } = rule.data[planningVariables.DATE_AGE_REVISED];
      const thereafter =
        rule.revisedDelta !== undefined && isDateApplicableForRule(dateString, date) && dateString !== date;
      let currDelta = thereafter ? rule.revisedDelta : rule.monthlyChange;
      if (!thereafter && rule.zeroStart) {
        currDelta += 0.01;
        rule.zeroStart = false;
        // calculations assume that 0 is treated as 0.01
      }
      let willBeNegative = !thereafter && currDelta < 0; // add_interest handles negative percentages
      let remaining = 0;
      if (willBeNegative) {
        remaining = findSetTotalFromSourceMap(rule.custodians, db);
        willBeNegative = remaining + currDelta <= 0;
      }
      setPCRuleCDs(
        thereafter ? assetChangeType.ADD_INTEREST : assetChangeType.ADD_CONTRIBUTION,
        thereafter ? currDelta : (willBeNegative ? -remaining : currDelta) / (getActiveAssets() || 1)
      );
      break;
    }
    case "rule_4": {
      const { date } = rule.data[planningVariables.DATE_AGE_REVISED];
      const currDelta =
        rule.revisedDelta !== undefined && isDateApplicableForRule(dateString, date) && dateString !== date
          ? rule.revisedDelta
          : rule.tickerPriceDelta;
      const stop = currDelta < 0 && (db.get(getDbPCKey(rule.id))?.cumulativeDelta || 0) <= 0; // weird rounding? bug where large negative numbers were becoming positive
      setPCRuleCDs(assetChangeType.TICKER_PRICE_CHANGE, stop ? 0 : currDelta, false);
      break;
    }
    case "rule_6": {
      const { units, cost } = getUnitsAndCost(rule.perLotValue, rule.perLotCost);
      executeContributionRule(units, cost);
      addTaxChange(units - cost, rule.data[planningVariables.COST_WITH_TAX]);
      break;
    }
    case "rule_33": {
      /* eslint-disable no-fallthrough */
      if (isDateApplicableForRule(dateString, rule.data[planningVariables.DATE_AGE].date)) {
        modifyCustodians(assetChangeType.ACTIVATE);
      }
    }
    /* eslint-enable no-fallthrough */
    case "rule_7": {
      subtext = previousDateChanges.subtext || null; // in case the rule has been stopped previously, the subtext should be carried over
      const previousAmount =
        previousDateChanges.amountForDay === undefined
          ? getAmountFromExpectedAmount(rule.data[planningVariables.AMOUNT])
          : previousDateChanges.amountForDay;
      const startDateString = rule.data[planningVariables.DATE_AGE]?.date; // some old version of the rule has no date age
      amountForDay = getAmountForRepeatingRule(previousAmount, dateString, rule);
      let contribution = 0;
      let cost = 0;
      if (isDateApplicableForRepeatingRule(dateString, rule, startDateString) && !db.get(rule.id).stop) {
        const stopRule = stopRuleIfNeeded(-amountForDay);
        contribution = stopRule ? Number.MIN_VALUE : amountForDay;
        cost = stopRule ? 0 : amountForDay;
      }
      executeContributionRule(contribution, cost);
      break;
    }
    case "rule_8":
    case "rule_16": {
      const relativeChangeObj = applyRuleChanges(
        isDateApplicableForRule(dateString, rule.data[planningVariables.DATE_AGE].date)
          ? assetChangeType.ACTIVATE
          : assetChangeType.ADD_CONTRIBUTION,
        0
      );
      if (rule.type === "rule_8") {
        updateContributionRuleCDs(relativeChangeObj, relativeChangeObj.cumulativeDelta);
      } else {
        cumulativeDelta = (previousDateChanges.cumulativeDelta || 0) + relativeChangeObj.cumulativeDelta;
        investableCumulativeDelta =
          (previousDateChanges.investableCumulativeDelta || 0) + relativeChangeObj.cumulativeDelta;
        cashCumulativeDelta = (previousDateChanges.cumulativeDelta || 0) + relativeChangeObj.cumulativeDelta;
        addCashChange(relativeChangeObj.cumulativeDelta);
      }

      break;
    }
    case "rule_9":
    case "rule_11": {
      subtext = previousDateChanges.subtext || null;
      const isDistributionRule = rule.type === "rule_9";
      const amtData = rule.data[isDistributionRule ? planningVariables.AMOUNT_WITH_TAX : planningVariables.AMOUNT];
      if (getActiveAssets() === 0 || db.get(rule.id).stop) {
        isDistributionRule ? executeIncomeRule(0, amtData) : executeCashRule(0);
        break;
      }
      const dates = rule.data[planningVariables.MULTIPLE_DATES].dates;
      let todaysChange = 0;
      const amt = (isDistributionRule ? 1 : -1) * getAmountFromExpectedAmount(amtData);
      while (rule.dateIndex < dates.length && isDateApplicableForRule(dateString, dates[rule.dateIndex])) {
        todaysChange += amt;
        rule.dateIndex++;
      }
      executeCashRule(!isDistributionRule && stopRuleIfNeeded(todaysChange) ? Number.MIN_VALUE : todaysChange);
      if (isDistributionRule) addTaxChange(todaysChange, amtData);

      break;
    }
    case "rule_10": {
      const relChangeObj = applyRuleChanges(
        isDateApplicableForRule(dateString, rule.data[planningVariables.DATE_AGE].date)
          ? assetChangeType.SELL
          : assetChangeType.ADD_CONTRIBUTION,
        0
      );
      const amtData = rule.data[planningVariables.AMOUNT_WITH_TAX];
      const actualCashChange = relChangeObj.cumulativeDelta && getAmountFromExpectedAmount(amtData);
      cumulativeDelta = relChangeObj.cumulativeDelta || previousDateChanges.cumulativeDelta || 0;
      investableCumulativeDelta = actualCashChange || previousDateChanges.investableCumulativeDelta || 0;
      cashCumulativeDelta = actualCashChange || previousDateChanges.cashCumulativeDelta || 0;
      cashAssetInflow = relChangeObj.cashCumulativeDelta || previousDateChanges.cashAssetInflow || 0;
      investableAssetInflow = relChangeObj.investableCumulativeDelta || previousDateChanges.investableAssetInflow || 0;

      addCashChange(actualCashChange);
      addTaxChange(actualCashChange, amtData);

      break;
    }
    case "rule_14": {
      updateRepaymentCDs(applyRuleChanges(assetChangeType.REPAY_DEBT_PERCENTAGE, rule.decayPercentage));
      break;
    }
    case "rule_15": {
      const previousAmount =
        previousDateChanges.amountForDay === undefined
          ? getAmountFromExpectedAmount(rule.data[planningVariables.AMOUNT])
          : previousDateChanges.amountForDay;
      const startDateString = rule.data[planningVariables.DATE_AGE]?.date; // some old version of the rule has no date age
      amountForDay = getAmountForRepeatingRule(previousAmount, dateString, rule);
      const relChangeObj = applyRuleChanges(
        assetChangeType.REPAY_DEBT,
        isDateApplicableForRepeatingRule(dateString, rule, startDateString) ? amountForDay : 0
      );
      updateRepaymentCDs(relChangeObj);
      break;
    }
    case "rule_17": {
      const amtData = rule.data[planningVariables.AMOUNT_WITH_TAX];
      const previousAmount =
        previousDateChanges.amountForDay === undefined
          ? getAmountFromExpectedAmount(amtData)
          : previousDateChanges.amountForDay;
      amountForDay = getAmountForRepeatingRule(previousAmount, dateString, rule);
      executeIncomeRule(isDateApplicableForRepeatingRule(dateString, rule) ? amountForDay : 0, amtData);
      break;
    }
    case "rule_18": {
      const startDateString = rule.data[planningVariables.DATE_AGE]?.date; // some old version of the rule has no date age
      const amtData = rule.data[planningVariables.AMOUNT_WITH_TAX];
      if (getActiveAssets() === 0) {
        executeIncomeRule(0, amtData);
        break;
      }
      const previousAmount =
        previousDateChanges.amountForDay === undefined
          ? getAmountFromExpectedAmount(amtData)
          : previousDateChanges.amountForDay;
      amountForDay = getAmountForRepeatingRule(previousAmount, dateString, rule);
      executeIncomeRule(
        isDateApplicableForRepeatingRule(dateString, rule, startDateString) ? amountForDay : 0,
        amtData
      );
      break;
    }
    case "rule_19": {
      const interestData = rule.data[planningVariables.RATE_PER_YEAR_WITH_TAX];
      if (getActiveAssets() === 0 || !isDateApplicableForRepeatingRule(dateString, rule)) {
        executeIncomeRule(0, interestData);
        break;
      }

      const artificialAssetValue = findSetTotalFromSourceMap(artificalAssets, db);
      const realAssetValue = findSetTotalFromSourceMap(rule.custodians, db) - artificialAssetValue;
      const repeatData = rule.data[planningVariables.REPEAT];
      const contribution = ((realAssetValue * interestData.value) / 100) * (periods[repeatData.frequency] / 12);

      executeIncomeRule(contribution, interestData);
      break;
    }
    case "rule_20": {
      if (!isDateApplicableForRule(dateString, rule.data[planningVariables.DATE_AGE].date)) break;
      const amtData = rule.data[planningVariables.AMOUNT_WITH_TAX];
      executeIncomeRule(db.get(rule.id).stop ? 0 : getAmountFromExpectedAmount(amtData), amtData);
      db.get(rule.id).stop = true;
      break;
    }
    case "rule_21": {
      const previousAmount =
        previousDateChanges.amountForDay === undefined
          ? getAmountFromExpectedAmount(rule.data[planningVariables.AMOUNT])
          : previousDateChanges.amountForDay;
      amountForDay = getAmountForRepeatingRule(previousAmount, dateString, rule);
      executeCashRule(isDateApplicableForRepeatingRule(dateString, rule) ? -amountForDay : 0);
      break;
    }
    case "rule_22": {
      if (getActiveAssets() === 0) {
        executeCashRule(0);
        break;
      }
      const previousAmount =
        previousDateChanges.amountForDay === undefined
          ? getAmountFromExpectedAmount(rule.data[planningVariables.AMOUNT])
          : previousDateChanges.amountForDay;
      amountForDay = getAmountForRepeatingRule(previousAmount, dateString, rule);

      executeCashRule(isDateApplicableForRepeatingRule(dateString, rule) ? -amountForDay : 0);

      break;
    }
    case "rule_23": {
      if (getActiveAssets() === 0) {
        executeCashRule(0);
        break;
      }
      const percentage = rule.data[planningVariables.PERCENTAGE].value;
      const assetValue =
        findSetTotalFromSourceMap(rule.custodians, db) - findSetTotalFromSourceMap(artificalAssets, db);
      const initialAmount = (assetValue * percentage) / 100;
      amountForDay = getAmountForRepeatingRule(initialAmount, dateString, rule);
      executeCashRule(isDateApplicableForRepeatingRule(dateString, rule) ? -amountForDay : 0);
      break;
    }
    case "rule_24": {
      const amount = getAmountFromExpectedAmount(rule.data[planningVariables.AMOUNT]);
      if (isDateApplicableForRule(dateString, rule.data[planningVariables.DATE_AGE].date)) {
        executeCashRule(db.get(rule.id).stop ? 0 : -amount);
        db.get(rule.id).stop = true;
      }
      break;
    }
    case "rule_25": {
      const previousAmount =
        previousDateChanges.amountForDay === undefined
          ? getAmountFromExpectedAmount(rule.data[planningVariables.AMOUNT])
          : previousDateChanges.amountForDay;

      amountForDay = getAmountForRepeatingRule(previousAmount, dateString, rule);

      executeCashRule(
        isDateApplicableForRepeatingRule(dateString, rule, rule.data[planningVariables.DATE_AGE]?.date)
          ? -amountForDay
          : 0
      );
      break;
    }
    case "rule_26": {
      if (monthsPassed < 13) {
        break;
      }
      const { networth } = dataForScenario[monthsPassed];
      cumulativeDelta = networth > 0 ? networth * (Math.pow(1 - rule.decayPercentage, monthsPassed - 12) - 1) : 0; // do not apply inflation changes to negative networth
      investableCumulativeDelta = 0;
      cashCumulativeDelta = 0; // be explicit so cumulativeDelta does not get treated as cashCumulativeDelta
      break;
    }
    case "rule_27": {
      if (getActiveAssets() === 0) {
        executeCashRule(0);
        break;
      }
      const lq = cashRuleObjs.liquidationRule;

      let totalInvestable = 0;
      const dist = {};
      for (const id of lq.containerIds) {
        const val = db.get(getDbPCKey(id)).investableCumulativeDelta;
        const finalVal = val < 0 ? 0 : val; // if container is negative, it can't be liquidated
        dist[id] = finalVal;
        totalInvestable += finalVal;
      }
      const assetValue = totalCash + totalInvestable; // totalInvestable is mutually exclusive with totalCash
      const percentage = rule.data[planningVariables.PERCENTAGE].value;
      const revisedPercentage = rule.data[planningVariables.REVISED_PERCENTAGE].value;
      const isAfterRevisedDate = isDateApplicableForRule(dateString, rule.data[planningVariables.DATE_AGE_YEAR].date);
      const applicablePercentage = isAfterRevisedDate ? revisedPercentage : percentage;
      const monthlyWithdrawal = ((applicablePercentage / 100) * assetValue) / 12;
      executeCashRule(-monthlyWithdrawal);
      break;
    }
    case "rule_29": {
      const { units, cost } = getUnitsAndCost(rule.perLotQuantity, rule.perLotCost);
      const relativeChanges = applyRuleChanges(assetChangeType.ADD_QUANTITY, units);
      updateContributionRuleCDs(relativeChanges, cost);
      addTaxChange(relativeChanges.cumulativeDelta - cost, rule.data[planningVariables.COST_WITH_TAX]);
      break;
    }
    case "rule_30": {
      const relChangeObj = applyRuleChanges(
        rule.monthsRemaining ? assetChangeType.REPAY_DEBT_PERCENTAGE : assetChangeType.ADD_CONTRIBUTION,
        rule.monthsRemaining ? 1 / rule.monthsRemaining : 0
      );
      updateRepaymentCDs(relChangeObj);
      if (rule.monthsRemaining > 0) {
        rule.monthsRemaining--;
        // this rule is only active during the initial duration, even if there is a new debt taken out after
      }
      break;
    }
    case "rule_31": {
      const amtData = rule.data[planningVariables.AMOUNT_WITH_TAX];
      const previousAmount =
        previousDateChanges.amountForDay === undefined
          ? getAmountFromExpectedAmount(amtData)
          : previousDateChanges.amountForDay;
      amountForDay = getAmountForRepeatingRule(previousAmount, dateString, rule);
      executeIncomeRule(
        isDateApplicableForRepeatingRule(dateString, rule, rule.data[planningVariables.DATE_AGE]?.date)
          ? amountForDay
          : 0,
        amtData
      );
      break;
    }
    case "rule_32": {
      // const custodianItem = rule.data[planningVariables.ASSET_ID].items[0];

      // if (isDateApplicableForRule(dateString, rule.data[planningVariables.DATE_AGE].date)) {
      //   cumulativeDelta = -initialValue;
      //   cashCumulativeDelta = initialValue;

      //   custodianItemValueMap = dataForScenario[monthsPassed].custodianItemValueMap || {};
      //   custodianItemValueMap[custodianItem.id] = 0;
      // }
      break;
    }
    case "rule_34": {
      // const previousAmount =
      //   previousDateChanges.amountForDay === undefined
      //     ? getAmountFromExpectedAmount(rule.data[planningVariables.AMOUNT])
      //     : previousDateChanges.amountForDay;
      // amountForDay = getAmountForRepeatingRule(previousAmount, dateString, rule);

      // const withdrawal = amountForDay;
      // const custodianItem = rule.data[planningVariables.ASSET_ID].items[0];
      // const custodian = custodianSelector(store.getState(), custodianItem.id);
      // const startDateString = rule.data[planningVariables.DATE_AGE]
      //   ? rule.data[planningVariables.DATE_AGE].date
      //   : undefined;

      // custodianItemValueMap = dataForScenario[monthsPassed].custodianItemValueMap || {};

      // if (
      //   isDateApplicableForRepeatingRule(dateString, rule, startDateString) &&
      //   custodianItemValueMap[custodianItem.id] !== 0
      // ) {
      //   cashCumulativeDelta =
      //     previousDateChanges.cashCumulativeDelta === undefined
      //       ? 0
      //       : previousDateChanges.cashCumulativeDelta +
      //         Math.min(withdrawal, initialValue - previousDateChanges.cashCumulativeDelta);

      //   cumulativeDelta = -cashCumulativeDelta;
      // } else {
      //   cashCumulativeDelta = previousDateChanges.cashCumulativeDelta || 0;
      //   cumulativeDelta = previousDateChanges.cumulativeDelta || 0;
      // }

      // if (
      //   !custodian === false &&
      //   (custodian.type === custodianTypes.INVESTABLE || custodian.type === custodianTypes.CASH_IN_HAND)
      // ) {
      //   investableCumulativeDelta = cumulativeDelta;
      // }

      // custodianItemValueMap[custodianItem.id] = initialValue + cumulativeDelta;
      break;
    }
    default:
      cumulativeDelta = 0;
  }

  var changes = { cumulativeDelta: cumulativeDelta };
  changes.cashDelta =
    (rule.effect === planningRuleEffect.INCREASE_ASSET_DECREASE_CASH ? -1 : 1) *
      ((cashCumulativeDelta || 0) - (previousDateChanges.cashCumulativeDelta || 0)) +
    (cashAssetInflow || 0) -
    (previousDateChanges.cashAssetInflow || 0) +
    cumulativeLq -
    (previousDateChanges.cumulativeLq || 0); // used to get the total cash in between rule executions
  if (cashCumulativeDelta !== null) {
    changes.cashCumulativeDelta = cashCumulativeDelta;
  }
  if (investableCumulativeDelta !== null) {
    changes.investableCumulativeDelta = investableCumulativeDelta;
  }
  if (cumulativeTax !== null) {
    changes.cumulativeTax = cumulativeTax;
  }
  if (cumulativeInterestPayment !== null) {
    changes.cumulativeInterestPayment = cumulativeInterestPayment;
  }
  if (cumulativeLq !== null) {
    changes.cumulativeLq = cumulativeLq;
  }
  if (amountForDay !== null) {
    changes.amountForDay = amountForDay;
  }
  // show changes of a rule in different blocks inside breakdown component
  if (parentContainerMap !== null) {
    changes.parentContainerMap = parentContainerMap;
  }
  // used to mitigate changes in the cashTotal/investableTotal for the day, like if you're contributing to a cash asset cash total should be 0
  if (cashAssetInflow !== null) {
    changes.cashAssetInflow = cashAssetInflow;
  }
  if (investableAssetInflow !== null) {
    changes.investableAssetInflow = investableAssetInflow;
  }
  // used to provide additional context for the changes(like when a contributes rule stops executing due to cash being over)
  if (subtext !== null) {
    changes.subtext = subtext;
  }
  return changes;
};

export const getAssetRules = rules => {
  if (!rules || rules.length === 0) {
    return [];
  }
  return rules.filter(rule => {
    const ruleObject = getRuleObject(rule);
    return (
      !ruleObject === false &&
      ruleObject.effect !== planningRuleEffect.CHANGE_NET_WORTH &&
      ([planningRuleCategories.ASSETS, planningRuleCategories.INCOME, planningRuleCategories.EXPENSE].includes(
        ruleObject.category
      ) ||
        [planningRuleEffect.DECREASE_DEBTS_CASH, planningRuleEffect.INCREASE_DEBTS_CASH].includes(ruleObject.effect))
    );
  });
};

// used in cashbreakdown and cashflow breakdown
export const getRulesCashBreakdown = (processedRules, dataPoint) => {
  const rules = getAssetRules(processedRules);
  if (rules.length === 0) {
    return {};
  }

  const breakdown = {};
  const ruleObjects = getFilteredRuleObjects(rules);

  const insertNode = (object, data) => {
    var key = getRuleNodeKey(object);

    if (object.effect === planningRuleEffect.CHANGE_ASSET) {
      key = ruleNodeKeys.ASSET_CASH_GROWTH;
    } else if (key === ruleNodeKeys.CASH || object.numCashAssets > 0) {
      key = ruleNodeKeys.CASH_INFLOW;
    }

    var overriddenKeys = [];
    var isNonCashRule = key === ruleNodeKeys.ASSET_CASH_GROWTH;
    if (!data.overridingRules === false) {
      for (const overriddenRule of data.overridingRules) {
        const overriddenRuleObject = getRuleObject(rules.find(item => item.id === overriddenRule.id));
        const label = getRuleNodeKey(overriddenRuleObject);

        if (overriddenKeys.includes(label) === false) {
          overriddenKeys.push(label);
        }
      }
    }

    if (key === planningAssetTypes.investable.label && overriddenKeys.includes(ruleNodeKeys.CASH) === false) {
      key = ruleNodeKeys.CASH_INFLOW;
      isNonCashRule = true;
    } else if (
      key === planningAssetTypes.all.label &&
      overriddenKeys.includes(planningAssetTypes.investable.label) === false &&
      overriddenKeys.includes(ruleNodeKeys.CASH) === false
    ) {
      key = ruleNodeKeys.CASH_INFLOW;
      isNonCashRule = true;
    }

    if (
      [
        ruleNodeKeys.INCOME,
        ruleNodeKeys.EXPENSE,
        ruleNodeKeys.CASH_INFLOW,
        ruleNodeKeys.CASH_OUTFLOW,
        ruleNodeKeys.ASSET_CASH_GROWTH
      ].includes(key) === false
    ) {
      return;
    }

    const cashData = JSON.parse(JSON.stringify(data));

    if (isNonCashRule === true) {
      cashData.changes.cumulativeDelta = getCashCumulativeDeltaForRule(cashData, object.effect);
      cashData.isNonCashRule = key !== ruleNodeKeys.ASSET_CASH_GROWTH; // adds the (Cash) at the end of the row only if the nodeKey is not Cash growth, otherwise it is redundant
    } else if (cashData.changes.cashAssetInflow !== undefined) {
      cashData.changes.cumulativeDelta = cashData.changes.cashAssetInflow;
      if (cashData.changes.cashAssetInflow < 0) {
        key = ruleNodeKeys.CASH_OUTFLOW;
      }
      cashData.isNonCashRule = key !== ruleNodeKeys.ASSET_CASH_GROWTH;
    }

    if (!breakdown[key] === true) {
      breakdown[key] = { initialValue: 0, rules: [] };
    }

    breakdown[key].label = key;
    breakdown[key].rules.push(cashData);
  };

  const insertCashOutflowNode = (object, data) => {
    const cashflowRuleData = JSON.parse(JSON.stringify(data));
    cashflowRuleData.changes.cumulativeDelta = getCashCumulativeDeltaForRule(data, object.effect);

    const addNode = nodeKey => {
      if (!breakdown[nodeKey] === true) {
        breakdown[nodeKey] = {
          initialValue: 0,
          label: nodeKey,
          rules: []
        };
      }
      breakdown[nodeKey].rules.push(cashflowRuleData);
    };

    if (object.category === planningRuleCategories.EXPENSE) {
      addNode(ruleNodeKeys.EXPENSE);
    } else {
      addNode(ruleNodeKeys.CASH_OUTFLOW);
    }
  };

  const insertCashInflowNode = (object, data) => {
    const cashflowRuleData = JSON.parse(JSON.stringify(data));
    cashflowRuleData.changes.cumulativeDelta = getCashCumulativeDeltaForRule(data, object.effect);

    const addNode = nodeKey => {
      if (!breakdown[nodeKey] === true) {
        breakdown[nodeKey] = {
          initialValue: 0,
          label: nodeKey,
          rules: []
        };
      }
      breakdown[nodeKey].rules.push(cashflowRuleData);
    };

    if (object.category === planningRuleCategories.INCOME) {
      addNode(ruleNodeKeys.INCOME);
    } else {
      addNode(ruleNodeKeys.CASH_INFLOW);
    }
  };

  let totalTax = 0;
  for (const ruleObject of ruleObjects) {
    const ruleData = dataPoint.rules.find(rule => rule.id === ruleObject.id);
    totalTax += ruleData.changes.cumulativeTax || 0;
    if (ruleObject.effect === planningRuleEffect.CHANGE_ASSET) {
      insertNode(ruleObject, ruleData);
    } else if (ruleObject.effect === planningRuleEffect.INCREASE_ASSET_DECREASE_CASH) {
      insertNode(ruleObject, ruleData);
      insertCashOutflowNode(ruleObject, ruleData);
    } else if (ruleObject.effect === planningRuleEffect.INCREASE_CASH_DECREASE_ASSET) {
      insertNode(ruleObject, ruleData);
      insertCashInflowNode(ruleObject, ruleData);
    } else if (
      ruleObject.effect === planningRuleEffect.DECREASE_DEBTS_CASH ||
      ruleObject.effect === planningRuleEffect.DECREASE_CASH
    ) {
      insertCashOutflowNode(ruleObject, ruleData);
    } else if (
      ruleObject.effect === planningRuleEffect.INCREASE_DEBTS_CASH ||
      ruleObject.effect === planningRuleEffect.INCREASE_CASH ||
      ruleObject.effect === planningRuleEffect.TRANSFER
    ) {
      insertCashInflowNode(ruleObject, ruleData);
    }
  }

  if (!breakdown[ruleNodeKeys.EXPENSE] === true) {
    breakdown[ruleNodeKeys.EXPENSE] = { initialValue: 0, rules: [], label: ruleNodeKeys.EXPENSE };
  }
  breakdown[ruleNodeKeys.EXPENSE].rules.push(createTaxBlock(totalTax));
  // Set keys in the order of display
  const moveKeyToEnd = key => {
    if (!breakdown[key] === false) {
      const data = breakdown[key];
      delete breakdown[key];
      breakdown[key] = data;
    }
  };
  moveKeyToEnd(ruleNodeKeys.INCOME);
  moveKeyToEnd(ruleNodeKeys.EXPENSE);
  moveKeyToEnd(ruleNodeKeys.CASH_INFLOW);
  moveKeyToEnd(ruleNodeKeys.CASH_OUTFLOW);
  moveKeyToEnd(ruleNodeKeys.ASSET_CASH_GROWTH);
  return breakdown;
};

// handles change by and thereafter parameters in planning rules that repeat
const getAmountForRepeatingRule = (previousAmount, dateString, rule) => {
  const ruleData = rule.data[planningVariables.REPEAT];
  const changeBy = ruleData.changeBy;

  const dateForDay = parseKuberaDateString(dateString);
  const thereafter =
    !ruleData.thereafter === false && !ruleData.thereafter.percentage === false ? ruleData.thereafter : null;
  var amountForDay = previousAmount;
  let tillDate = parseKuberaDateString(ruleData.till?.date);
  if (tillDate) tillDate = new Date(tillDate.getFullYear(), tillDate.getMonth() + 1, 0, 0, 0, 0, 0);

  if (!thereafter === true && !tillDate === false && dateForDay.getTime() > tillDate.getTime()) {
    return 0;
  }

  const calculateChange = params => {
    var newAmount = amountForDay;
    if (params.frequency === repeatFrequency.YEARLY) {
      const dateOfYearString = !params.dateOfYear === false ? params.dateOfYear : "2023-01-01";
      const dateOfYear = parseKuberaDateString(dateOfYearString);
      if (dateOfYear.getMonth() === dateForDay.getMonth()) {
        const changeAmount = (params.percentage / 100) * newAmount;
        newAmount = newAmount + (params.increasing ? 1 : -1) * changeAmount;
      }
    } else if (params.frequency === repeatFrequency.MONTHLY) {
      const changeAmount = (params.percentage / 100) * newAmount;
      newAmount = newAmount + (params.increasing ? 1 : -1) * changeAmount;
    }
    return newAmount;
  };
  // do not start compounding until the next occurrence of the rule
  const startDate = parseKuberaDateString(rule.data[planningVariables.DATE_AGE]?.date);
  const dateTooEarly =
    ruleData.changeBy?.frequency === repeatFrequency.YEARLY
      ? dateForDay < oneYearFromNowMonthEnd
      : dateForDay.getTime() === nextMonthEnd.getTime(); // no start date
  if (startDate ? startDate >= dateForDay : dateTooEarly) return amountForDay;

  if (!thereafter === false && !tillDate === false && dateForDay.getTime() > tillDate.getTime()) {
    amountForDay = calculateChange(thereafter);
  } else if (!changeBy === false && !changeBy.percentage === false) {
    amountForDay = calculateChange(changeBy);
  }
  return amountForDay;
};

const isDateApplicableForRule = (dateString, ruleDateString) => {
  const dateForDay = parseKuberaDateString(dateString);
  const ruleDate = parseKuberaDateString(ruleDateString);

  if (dateForDay.getTime() >= ruleDate.getTime()) {
    return true;
  }
  return false;
};

const isDateApplicableForRepeatingRule = (dateString, rule, startDateString = undefined) => {
  if (!startDateString === false && isDateApplicableForRule(dateString, startDateString) === false) {
    return false;
  }

  const ruleData = rule.data[planningVariables.REPEAT];
  const { frequency } = ruleData;
  const dateForDay = parseKuberaDateString(dateString);
  const thereafter =
    !ruleData.thereafter === false && !ruleData.thereafter.percentage === false ? ruleData.thereafter : null;
  let tillDate = parseKuberaDateString(ruleData.till?.date);
  if (tillDate) tillDate = new Date(tillDate.getFullYear(), tillDate.getMonth() + 1, 0, 0, 0, 0, 0);

  if (!thereafter === true && !tillDate === false && dateForDay.getTime() > tillDate.getTime()) {
    return false;
  }
  const targetMonth = convertDateOfYearToDate(ruleData).getMonth();
  const startDate = parseKuberaDateString(startDateString) || nextMonthEnd;
  let diffMonths = monthsBetweenDates(startDate, dateForDay);

  if (frequency === repeatFrequency.NO_REPEAT) {
    return dateForDay.getTime() === startDate.getTime();
  } else if (frequency === repeatFrequency.MONTHLY) {
    return true;
  } else {
    const currPeriod = periods[frequency];
    return (startDate.getMonth() + diffMonths) % currPeriod === targetMonth % currPeriod;
  }
};

const calculateLinearChange = (initialValue, finalValue, monthsPassed, totalMonths, stopAtFinalValue = true) => {
  if (stopAtFinalValue === true && monthsPassed >= totalMonths) {
    return finalValue - initialValue;
  }
  return ((finalValue - initialValue) / totalMonths) * monthsPassed;
};

const getAmountFromExpectedAmount = data => {
  const currentPortfolio = currentPortfolioSelector(store.getState());
  return convertCurrency(data.value, getTickerUsingId(data.tickerId).shortName, currentPortfolio.currency);
};

/**
 * monthly compounding works here, so we can't reduce by (example) 50% each month because that would mean the principal(P) would be 50% less in one month instead of one year
 * so, instead we solve for the decay percentage(D) that would make the principal(P) 50% by next year in the formula: P * (D)^12 = 0.5P
 */
const calculateMonthlyDecayPercentage = annualPercentage =>
  1 - Math.pow(1 - Math.min(annualPercentage / 100, 1), 1 / 12);

const calculateTax = (amt, taxVar) => {
  const tax = taxVar[planningVariables.TAX];
  if (!tax || !amt || amt < 0) return 0;
  const taxDeduction = taxVar[planningVariables.TAX_DEDUCTION];
  return (amt - (taxDeduction ? Math.min(getAmountFromExpectedAmount(taxDeduction), amt) : 0)) * (tax / -100);
};

const calculateCompoundedChangeFromPreviousDay = (ratePerYear, previousDayValue, previousDayDelta, monthsPassed) => {
  if (monthsPassed === 0) {
    return 0;
  }
  const monthlyRate = Math.pow(1 + ratePerYear / 100, 1 / 12) - 1;
  return previousDayValue * monthlyRate + previousDayDelta;
};

// scenario / rule update functions
export const updateNetworthChartSelectedScenarios = scenarioIds => {
  return dispatch => {
    if (!scenarioIds === true) {
      return;
    }

    const currentPortfolio = currentPortfolioSelector(store.getState());
    currentPortfolio.networthChartScenario = scenarioIds;
    dispatch(updatePortfolio(currentPortfolio));
  };
};

export const updateTargetDate = targetDate => {
  return dispatch => {
    if (!targetDate === true) {
      return;
    }

    const currentPortfolio = currentPortfolioSelector(store.getState());
    // convert milli seconds to seconds
    currentPortfolio.tsPlanningTargetDate = targetDate.getTime() / 1000;
    dispatch(updatePortfolio(currentPortfolio));
  };
};

export const deleteRuleFromScenario = (scenario, ruleId) => {
  return dispatch => {
    var updatedScenario = scenario;
    const ruleIndex = updatedScenario.rule.findIndex(item => item.id === ruleId);
    const rule = updatedScenario.rule[ruleIndex];

    if (ruleIndex !== -1) {
      updatedScenario.rule.splice(ruleIndex, 1);
    }

    const toast = new Toast(
      toastType.UNDO,
      "Removed",
      undefined,
      () => {
        updatedScenario.rule.splice(ruleIndex, 0, rule);
        dispatch(createPlanningRule(rule));
      },
      () => {}
    );
    dispatch(showToastAction(toast));
    dispatch(deletePlanningRule(rule));
  };
};

// grouping for charts? unclear what this does...
export const planningGroupByPeriod = {
  MONTH: "month",
  QUARTER: "quarter",
  YEAR: "year",
  HALF_DECADE: "half_decade",
  DECADE: "decade"
};

export const getGroupedDataForScenario = (dataForScenario, minFullPeriods, maxFullPeriods, isCashFlowChart = false) => {
  const dataPointsForScenario = dataForScenario.data;
  const dataPointsCount = dataPointsForScenario.length;
  var groupBy = planningGroupByPeriod.MONTH;
  if (dataPointsCount === 0) {
    return {};
  }

  if (dataPointsCount / (10 * 12) > minFullPeriods || dataPointsCount / (5 * 12) > maxFullPeriods) {
    groupBy = planningGroupByPeriod.DECADE;
  } else if (dataPointsCount / (5 * 12) > minFullPeriods || dataPointsCount / 12 > maxFullPeriods) {
    groupBy = planningGroupByPeriod.HALF_DECADE;
  } else if (dataPointsCount / 12 > minFullPeriods || dataPointsCount / 3 > maxFullPeriods) {
    groupBy = planningGroupByPeriod.YEAR;
  } else if (dataPointsCount / 3 > minFullPeriods || dataPointsCount > maxFullPeriods) {
    groupBy = planningGroupByPeriod.QUARTER;
  } else if (dataPointsCount > minFullPeriods) {
    groupBy = planningGroupByPeriod.MONTH;
  }
  var groupedData = { groupBy: groupBy, currency: dataForScenario.currency, data: [] };
  var startData = dataPointsForScenario[0];
  var nextEndPointDateString = getNextEndDateForGroupByPeriod(groupBy, startData.date);
  for (var i = 0; i < dataPointsCount; i++) {
    const date = dataPointsForScenario[i].date;
    if (date === nextEndPointDateString || i === dataPointsCount - 1) {
      groupedData.data.push({ startData: startData, endData: dataPointsForScenario[i] });

      if (i !== dataPointsCount - 1) {
        startData = dataPointsForScenario[i];
        nextEndPointDateString = getNextEndDateForGroupByPeriod(groupBy, startData.date);
      }
    }
  }
  return groupedData;
};

export const getNextEndDateForGroupByPeriod = (groupByPeriod, lastEndDateString) => {
  var lastEndDate = parseKuberaDateString(lastEndDateString);
  var endDate = parseKuberaDateString(lastEndDateString);

  switch (groupByPeriod) {
    case planningGroupByPeriod.MONTH: {
      endDate = new Date(lastEndDate.getFullYear(), lastEndDate.getMonth() + 2, 0);
      break;
    }
    case planningGroupByPeriod.QUARTER: {
      endDate = getNextEndOfQuarterDate(lastEndDate);
      break;
    }
    case planningGroupByPeriod.YEAR: {
      endDate = new Date(lastEndDate.getFullYear() + 1, lastEndDate.getMonth() + 1, 0);
      break;
    }
    case planningGroupByPeriod.HALF_DECADE: {
      endDate = new Date(lastEndDate.getFullYear() + 5, lastEndDate.getMonth() + 1, 0);
      break;
    }
    case planningGroupByPeriod.DECADE: {
      endDate = new Date(lastEndDate.getFullYear() + 10, lastEndDate.getMonth() + 1, 0);
      break;
    }
    default: {
      break;
    }
  }
  return getPlanningDateString(endDate, 0);
};

const getNextEndOfQuarterDate = date => {
  /**
   * January 1st - March 31st  = First Quarter
   * April 1st - June 30th = Second Quarter
   * July 1st - September 30th = Third Quarter
   * October 1st - December 31st = Fourth Quarter
   */

  const quarter = Math.floor((date.getMonth() + 4) / 3);
  const endDateForTheQuarter = new Date(date.getFullYear(), quarter * 3, 0);
  return endDateForTheQuarter;
};

export const getLineChartGroupedDataForScenario = (dataForScenario, minFullPeriods = 7, maxFullPeriods = 52) => {
  const groupedData = getGroupedDataForScenario(dataForScenario, minFullPeriods, maxFullPeriods, false);
  var dataPoints = [];
  if (!groupedData.data === false) {
    for (var i = 0; i < groupedData.data.length; i++) {
      dataPoints.push(groupedData.data[i].startData);
    }
    if (
      dataPoints.length > 0 &&
      dataPoints[dataPoints.length - 1].date !== groupedData.data[groupedData.data.length - 1].endData.date
    ) {
      dataPoints.push(groupedData.data[groupedData.data.length - 1].endData);
    }
  }
  return { currency: groupedData.currency, data: dataPoints, id: dataForScenario.scenario.id };
};

export const getCashCumulativeDeltaForRule = (rule, effect = null) => {
  if (!effect === true) {
    effect = getRuleObject(rule).effect;
  }
  const taxDelta = rule.changes.cumulativeTax || 0;
  if (effect === planningRuleEffect.INCREASE_CASH_DECREASE_ASSET) {
    if (
      !rule.changes.cashCumulativeDelta === false &&
      rule.changes.cashCumulativeDelta !== rule.changes.cumulativeDelta
    ) {
      return rule.changes.cashCumulativeDelta - taxDelta;
    }
    return (
      -1 *
        (rule.changes.cashCumulativeDelta === undefined
          ? rule.changes.cumulativeDelta
          : rule.changes.cashCumulativeDelta) -
      taxDelta
    );
  } else if (effect === planningRuleEffect.INCREASE_ASSET_DECREASE_CASH) {
    return (
      -1 *
        (rule.changes.cashCumulativeDelta === undefined
          ? rule.changes.cumulativeDelta
          : rule.changes.cashCumulativeDelta) -
      taxDelta
    );
  } else if (
    effect === planningRuleEffect.DECREASE_DEBTS_CASH ||
    effect === planningRuleEffect.INCREASE_DEBTS_CASH ||
    effect === planningRuleEffect.INCREASE_CASH ||
    effect === planningRuleEffect.DECREASE_CASH ||
    effect === planningRuleEffect.CHANGE_ASSET ||
    effect === planningRuleEffect.TRANSFER
  ) {
    return (
      (rule.changes.cashCumulativeDelta === undefined
        ? rule.changes.cumulativeDelta
        : rule.changes.cashCumulativeDelta) - taxDelta
    );
  }
  return 0;
};

// nodeKey needs to be patched in because an alternate name for the asset could be used
export const getRuleText = (ruleType, ruleData, nodeKey = undefined) => {
  var ruleObject = { ...planningRules.find(item => item.type === ruleType) };
  if (!ruleObject === true) {
    return "";
  }

  ruleObject.data = ruleData;

  var label = ruleObject.label(ruleObject.data);
  for (const key in ruleObject.data) {
    label = label.replace(`#${key}#`, getRuleVariableDisplayValue(key, ruleObject, "#variable_string#", nodeKey));
  }
  return label;
};

export const getNameList = list => {
  let displayString = "";
  for (let i = 0; i < list.length; i++) {
    if (i === 0) {
      displayString = list[0].trim();
    } else if (i < 2) {
      displayString += `, ${list[i].trim()}`;
    } else {
      displayString += ` +${list.length - i}`;
      break;
    }
  }
  return displayString;
};

const repeatFrequencyToReadableName = {
  [repeatFrequency.MONTHLY]: "every month",
  [repeatFrequency.QUARTERLY]: "every 3 months",
  [repeatFrequency.BI_ANNUALLY]: "every 6 months",
  [repeatFrequency.YEARLY]: "every year",
  [repeatFrequency.NO_REPEAT]: "Does not repeat"
};

const getReadableDateAgeYear = (ruleObject, key) => {
  const dateString = ruleObject.data[key].date;
  const date = parseKuberaDateString(dateString);
  const userAge = userAgeAtDate(store.getState(), date);
  const userAgeString = !userAge === false ? ` (age ${userAge})` : "";
  return `${months[date.getMonth()]} ${date.getFullYear()}${userAgeString}`;
};

export const getRuleVariableDisplayValue = (key, ruleObject, linkTemplate = "#variable_string#", nodeKey) => {
  //planningVariables.REPEAT and planningVariables.DATE_AGE_REVISED are dynamically linked (HTML is returned instead of just a string)

  if (
    !ruleObject.data[key] === true ||
    (Object.keys(ruleObject.data[key]).length === 1 && !ruleObject.data[key].props === false)
  ) {
    return key === planningVariables.DATE_AGE_REVISED
      ? linkTemplate.replace("#variable_string#", ruleObject.variablePlaceholder[key])
      : ruleObject.variablePlaceholder[key]; // .REPEAT is assumed to never be empty
  }

  switch (key) {
    case planningVariables.ASSET_TYPE:
      return planningAssetTypes[ruleObject.data[key].value].label;
    case planningVariables.GROWTH_RATE:
    case planningVariables.RATE_PER_YEAR_WITH_TAX:
    case planningVariables.RATE_PER_YEAR:
      return `${formatNumberWithKuberaNumberFormatSettings(ruleObject.data[key].value)}% per year`;
    case planningVariables.REVISED_PERCENTAGE:
    case planningVariables.PERCENTAGE:
      return `${formatNumberWithKuberaNumberFormatSettings(ruleObject.data[key].value)}%`;
    case planningVariables.COST_WITH_TAX:
    case planningVariables.AMOUNT:
    case planningVariables.AMOUNT_WITH_TAX:
    case planningVariables.EXPECTED_AMOUNT: {
      const data = ruleObject.data[key];
      const amountTicker = getTickerUsingId(data.tickerId);
      let displayString = `${shortFormatNumberWithCurrency(
        Math.kuberaFloor(data.value),
        amountTicker.shortName,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        false
        // @TODO: If earlier logic is required
        /* portfolio.currency === amountTicker.shortName ||
            getSymbolForTickerUsingShortName(portfolio.currency) !==
              getSymbolForTickerUsingShortName(amountTicker.shortName) */
      )}`;

      if (key === planningVariables.COST_WITH_TAX) {
        displayString = `${displayString} per unit`;
      }
      return displayString;
    }
    case planningVariables.DATE_AGE_REVISED: {
      let finalString = linkTemplate.replace("#variable_string#", getReadableDateAgeYear(ruleObject, key));
      const { revisedPercentage, increasing } = ruleObject.data[key];
      if (!isNaN(revisedPercentage) && revisedPercentage !== null) {
        finalString += ". Thereafter ";
        const formattedNum = formatNumberWithKuberaNumberFormatSettings(revisedPercentage);
        const numStr = `change by ${increasing === true || revisedPercentage === 0 ? "" : "-"}${formattedNum}%`;
        finalString += linkTemplate.replace("#variable_string#", numStr);
      }
      return finalString;
    }
    case planningVariables.DATE_AGE_YEAR: {
      return getReadableDateAgeYear(ruleObject, key);
    }
    case planningVariables.DATE_AGE: {
      const dateString = ruleObject.data[key].date;
      const date = parseKuberaDateString(dateString);
      const userAge = userAgeAtDate(store.getState(), date);
      const userAgeString = !userAge === false ? ` (age ${userAge})` : "";
      return `${months[date.getMonth()]} ${date.getFullYear()}${userAgeString}`;
    }
    case planningVariables.DEBT_ID:
    case planningVariables.ASSET_ID: {
      const itemList = getCustodianItems(ruleObject.data[key].items).map(item => item.name);
      if (itemList.length === 0) {
        return ruleObject.variablePlaceholder[key];
      }
      return (ruleObject.handleNamingConflict && nodeKey) || getNameList(itemList); // if a node key is given, then it could be an alternate name for rules that have that behavior
    }
    case planningVariables.TICKER_ID: {
      const itemList = ruleObject.data[key].items.map(id => {
        const ticker = getTickerUsingId(id);
        return `${ticker.name} (${ticker.code})`;
      });
      if (itemList.length === 0) {
        return ruleObject.variablePlaceholder[key];
      }
      return getNameList(itemList);
    }
    case planningVariables.QUANTITY: {
      const ruleData = ruleObject.data[key];
      const isUnits = !ruleData.props === false && ruleData.props.isUnits === true;
      return `${ruleObject.data[key].value}${isUnits === true ? " units" : ""}`;
    }
    case planningVariables.VESTING_SCHEDULE: {
      const frequencyDisplayMap = {
        [repeatFrequency.MONTHLY]: "month",
        [repeatFrequency.QUARTERLY]: "3 months",
        [repeatFrequency.BI_ANNUALLY]: "6 months",
        [repeatFrequency.YEARLY]: "year"
      };
      const dataObj = ruleObject.data[key];
      return `every ${frequencyDisplayMap[dataObj.frequency]}, for ${dataObj.value} ${displayVestingDuration(dataObj)}${
        dataObj.cliff ? `, ${dataObj.cliff} ${displayDuration(dataObj.cliffDuration, dataObj.cliff)} cliff` : ""
      }`;
    }
    case planningVariables.MONTHS: {
      return `${ruleObject.data[key].value} months`;
    }
    case planningVariables.REPEAT: {
      const ruleData = ruleObject.data[key];
      const props = planningRules.find(item => item.type === ruleObject.type)?.data[key].props;
      const frequency = ruleData.frequency;
      let displayString = repeatFrequencyToReadableName[frequency];
      if (ruleObject.data[planningVariables.DATE_AGE] === undefined) {
        const monthString = months[convertDateOfYearToDate(ruleData).getMonth()];
        switch (frequency) {
          case repeatFrequency.QUARTERLY:
          case repeatFrequency.BI_ANNUALLY:
          case repeatFrequency.YEARLY:
            displayString += `, starting ${monthString}`;
            break;
          default:
            break;
        }
      }
      const prefix = props?.shortText || frequency === repeatFrequency.NO_REPEAT ? "" : "Repeats ";
      displayString = prefix + linkTemplate.replace("#variable_string#", displayString);

      if (!ruleData.changeBy === false && !ruleData.changeBy.percentage === false) {
        displayString += ". Revised to";

        displayString += linkTemplate.replace(
          "#variable_string#",
          ` ${ruleData.changeBy.increasing === true ? "+" : "-"}${formatNumberWithKuberaNumberFormatSettings(
            ruleData.changeBy.percentage
          )}%`
        );

        if (ruleData.changeBy.frequency === repeatFrequency.MONTHLY) {
          displayString += " every month";
        } else if (ruleData.changeBy.frequency === repeatFrequency.YEARLY) {
          displayString += " every year";
          if (frequency !== repeatFrequency.YEARLY) {
            const dateOfYear = parseKuberaDateString(ruleData.changeBy.dateOfYear || "2024-01-01");
            displayString += " in " + linkTemplate.replace("#variable_string#", `${months[dateOfYear.getMonth()]}`);
          }
        }
      }

      if (!ruleData.till === false) {
        if (!ruleData.till.age === false) {
          displayString += ", ";
          displayString += linkTemplate.replace("#variable_string#", `till Age ${ruleData.till.age}`);
        } else if (!ruleData.till.date === false) {
          const tillDate = parseKuberaDateString(ruleData.till.date);
          displayString += ", ";
          displayString += linkTemplate.replace(
            "#variable_string#",
            `till ${months[tillDate.getMonth()]} ${tillDate.getFullYear()}`
          );
        }
      }

      if (!ruleData.thereafter === false) {
        displayString += ", thereafter ";
        displayString += linkTemplate.replace(
          "#variable_string#",
          `${ruleData.thereafter.increasing === true ? "+" : "-"}${ruleData.thereafter.percentage}%`
        );

        if (ruleData.thereafter.frequency === repeatFrequency.MONTHLY) {
          displayString += " every month";
        } else if (ruleData.thereafter.frequency === repeatFrequency.YEARLY) {
          displayString += " every year";
        }
      }
      return displayString;
    }
    case planningVariables.TICKER_NAME:
    case planningVariables.NEW_INCOME:
    case planningVariables.NEW_EXPENSE:
    case planningVariables.META:
    case planningVariables.NEW_DEBT:
    case planningVariables.NEW_ASSET: {
      return ruleObject.data[key].value;
    }
    case planningVariables.MULTIPLE_DATES: {
      const dates = ruleObject.data[key].dates;
      if (dates.length === 0) {
        return ruleObject.variablePlaceholder[key];
      }
      const dateStrings = dates.map(
        item =>
          `${parseKuberaDateString(item).getDate()} ${
            months[parseKuberaDateString(item).getMonth()]
          } ${parseKuberaDateString(item).getFullYear()}`
      );
      return getNameList(dateStrings);
    }
    default:
      return key;
  }
};
