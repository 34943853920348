import React, { useState, useRef, useEffect } from "react";
import i18n from "i18next";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  getUuid,
  custodianDetailsSelector,
  dismissToastAction,
  getCustodianHistoryFormattedDateString,
  converUIDateFormatToCustodianHistoryApiDateFormat,
  updateOwnershipHistoryForCustodian,
  convertCustodianHistoryApiDateFormatToUIFormat,
  updateCustodianOwnership,
  refreshCustodian,
  refreshAllConnectedCustodians,
  fetchCustodianDetailsSuccessAction
} from "@kubera/common";
import CustodianOwnershipDetailsComponent from "components/custodian_details/CustodianOwnershipDetailsComponent";
import PercentageInput from "components/inputs/PercentageInput";

const Container = styled.div`
  padding: 25px 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 11px;
  font-weight: 700
  line-height: 13px;
  letter-spacing: 0.01em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
`;

const Description = styled.div`
  margin-top: 2px;
  margin-bottom: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.7);
`;

const OwnershipField = styled(PercentageInput)`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding: 13px 14px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.015em;
  font-feature-settings: "pnum" on, "lnum" on, "ss01" on;
  color: ${props => (!props.color === true ? null : props.color)};
  outline: none;
`;

const LastUpdate = styled.div`
  width: fit-content;
  margin-top: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

const CustodianOwnershipComponent = ({
  className = "",
  custodianId,
  detailsInfo,
  debtsLinked,
  ownershipValue,
  isReadOnly,
  details,
  category,
  chartStartDate
}) => {
  const dispatch = useDispatch();

  const timeoutId = useRef(null);
  const componentRef = useRef(null);

  const custodianDetails = useSelector(custodianDetailsSelector);

  const [custodianOwnershipVal, setCustodianOwnershipVal] = useState(ownershipValue);

  const [showOwnershipDetailsDialog, setShowOwnershipDetailsDialog] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
  };

  const handleCustodianOwnershipValChange = value => {
    setCustodianOwnershipVal(value);
  };

  const setOwnershipValue = async (value, custodiansToUpdate) => {
    value = parseFloat(value);
    const detailsInfo = { ...custodianDetails };
    const newDetailsInfo = detailsInfo;

    let updatedRowEntry;
    if (newDetailsInfo.info) {
      newDetailsInfo.info.ownership = value;
    }
    let isNewEntry = true;
    const today = converUIDateFormatToCustodianHistoryApiDateFormat(
      getCustodianHistoryFormattedDateString(new Date().getTime())
    );
    if (detailsInfo.ownership) {
      for (const [index, entry] of detailsInfo.ownership.entries()) {
        if (entry.date === today) {
          isNewEntry = false;
          newDetailsInfo.ownership[index].percentage = value;
          updatedRowEntry = {
            id: entry.id,
            percentage: value,
            date: today
          };
          break;
        }
      }
    } else {
      newDetailsInfo.ownership = [];
    }
    if (isNewEntry) {
      updatedRowEntry = {
        id: getUuid(),
        percentage: value,
        date: today
      };
      newDetailsInfo.ownership.push(updatedRowEntry);
    }

    dispatch(
      updateOwnershipHistoryForCustodian(
        isNewEntry,
        detailsInfo.info && detailsInfo.info.sectionId,
        custodianId,
        updatedRowEntry
      )
    );
    dispatch(dismissToastAction());
    await dispatch(updateCustodianOwnership(custodianId, value));
    if (newDetailsInfo && newDetailsInfo.info && newDetailsInfo.info.parentId) {
      dispatch(refreshAllConnectedCustodians(newDetailsInfo.info.parentId, undefined, undefined, { force: 1 }));
    } else {
      const refreshedDetails = await dispatch(
        refreshCustodian(custodianId, undefined, undefined, undefined, undefined, { force: 1 })
      );

      if (componentRef.current) {
        dispatch(fetchCustodianDetailsSuccessAction({ ...details, ...refreshedDetails }));
      }
    }
  };

  const handleOnChange = (e, value) => {
    if (!value === true) {
      value = 0;
    }
    setCustodianOwnershipVal(value);
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      setOwnershipValue(value);
    }, 300);
  };

  const handleLastUpdateClick = e => {
    setShowOwnershipDetailsDialog(true);
  };

  const handleOwnershipDetailsDialogOnDismiss = e => {
    setShowOwnershipDetailsDialog(false);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  const getNewestOwnershipHistoryDate = () => {
    if (!details === true || !details.ownership === true || !details.ownership.length === 0) {
      return null;
    }
    var result = null;
    for (const item of details.ownership) {
      if (!result === true) {
        result = item;
      } else if (item.date.localeCompare(result.date) > 0) {
        result = item;
      }
    }
    if (!result === true) {
      return null;
    }
    return convertCustodianHistoryApiDateFormatToUIFormat(result.date);
  };

  return (
    <Container className={className} ref={componentRef}>
      <form onSubmit={handleSubmit}>
        <Title>{i18n.t("custodianDetails.ownership")}</Title>
        <Description>{i18n.t("ownership.description").replace("%s", category?.toLowerCase())}</Description>
        <OwnershipField
          value={custodianOwnershipVal}
          disabled={isReadOnly}
          errorMessage={i18n.t("ownershipfieldErrorMessage")}
          onChange={handleOnChange}
          allowMoreThanHundredPercent={true}
        />
      </form>
      {!getNewestOwnershipHistoryDate() === false && (
        <LastUpdate onClick={handleLastUpdateClick}>
          {i18n.t("lastUpdate") + ": " + getNewestOwnershipHistoryDate()}
        </LastUpdate>
      )}
      {showOwnershipDetailsDialog === true && (
        <CustodianOwnershipDetailsComponent
          details={details}
          custodianId={custodianId}
          onCustodianOwnershipChange={handleCustodianOwnershipValChange}
          onDismiss={handleOwnershipDetailsDialogOnDismiss}
          isReadOnly={isReadOnly}
          chartStartDate={chartStartDate}
        />
      )}
    </Container>
  );
};

export default CustodianOwnershipComponent;
